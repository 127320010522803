import React, { useState } from 'react';
import { Button, Tooltip, Typography } from 'antd';
import { PlusCircleFilled, EditTwoTone, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Text } = Typography;

const SubscriptionDetails = ({ record, handleEditIconClick, setPopConfirmVisible }) => {
  const [showAll, setShowAll] = useState(false);
  const [expandedCards, setExpandedCards] = useState({});

  const plans = record?.planData || [];

  const handleToggleClick = () => {
    setShowAll(!showAll);
  };

  const handleViewToggle = (id) => {
    setExpandedCards(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const plansToShow = showAll ? plans : plans.slice(0, 2);

  return (
    <div style={{ minHeight: "100px" }}>
      <div>
        <div style={{ textAlign: "end" }}>
          <span
            onClick={() => {
              handleEditIconClick(record, true, "PLAN", record?.planData?.[0]?._id);
            }}
            style={{ cursor: "pointer", marginTop: "13px" }}
          >
            <Tooltip
              title="Create Manual Subscription."
              trigger={['hover', 'click']}
            >
              <PlusCircleFilled
                style={{
                  color: "#275291",
                  fontSize: "20px"
                }}
              />
            </Tooltip>
          </span>
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "column", textAlign: "left", maxHeight: "300px", overflow: "hidden", overflowY: "auto", padding: "5px" }}>
        {plansToShow.map((el, ind) => (
          (el?.planDisplayName) && (
            <div key={ind} style={{ display: "flex", flexDirection: "column", marginBottom: "10px", boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px", padding: "5px" }}>
              <span>
                <strong>{el?.type === "manual" ? "Manual Plan:" : "Plan:"}</strong>{" "}
                {el?.planDisplayName}
              </span>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>
                  <strong>Start Date:</strong>{" "}
                  <Text style={{ color: "#29be", textAlign: "start" }}>
                    {moment(el?.startDate).format("MMMM D, YYYY hh:mm A")}
                  </Text>
                </span>
                <span>
                  <strong>End Date:{" "}</strong>
                  <Text style={{ color: "#29be", textAlign: "start" }}>
                    {moment(el?.periodEndDate).format("MMMM D, YYYY hh:mm A")}
                  </Text>
                </span>
              </div>

              <span>
                <strong>Status:</strong>{" "}
                <span>{el?.subscriptionStatus}</span>
              </span>

              {expandedCards[el._id] && (
                <span>
                  <span>
                    <div>
                      <strong>Purchased By:</strong>{" "}{record?.fullName || ""}
                    </div>
                    {record?.email ? (
                      <>
                        <strong>Email:</strong>{" "}
                        <Text ellipsis style={{ color: "#29be" }}>
                          {record?.email}
                        </Text>
                      </>
                    ) : null}
                  </span>
                  <div>
                    <strong>Notes:</strong>{" "}
                    <Text>{el?.notes}</Text>
                  </div>
                </span>
              )}

              <span style={{ display: "flex", justifyContent: "space-between" }}>
                <span>
                  <Button
                    onClick={() => handleViewToggle(el._id)}
                    type='link'
                    style={{ textAlign: "left", padding: 0 }}
                  >
                    {expandedCards[el._id] ? "View Less..." : "View More..."}
                  </Button>
                </span>

                {(el.type !== 'stripe') && (
                  <span style={{ display: "flex", gap: "20px" }}>
                    <span
                      onClick={() => {
                        handleEditIconClick(record, el?.subscriptionStatus, "PLAN", el?._id, el?.startDate, el?.periodEndDate, el?.notes, el?.planDisplayName);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <Tooltip title="Edit Subscription." trigger={['hover', 'click']}>
                        <EditTwoTone />
                      </Tooltip>
                    </span>
                    <span>
                      {(el?.subscriptionStatus !== "deleted") && (
                        <Tooltip title="Delete Subscription." trigger={['hover', 'click']}>
                          <DeleteOutlined
                            onClick={() =>
                              setPopConfirmVisible({
                                visible: true,
                                planId: el?._id,
                              })
                            }
                            style={{ color: "#1890ff", textAlign: "center" }}
                          />
                        </Tooltip>
                      )}
                    </span>
                  </span>
                )}
              </span>
            </div>
          )
        ))}
      </div>

      <div>
        {(plans.length > 2) && (
          <Button type="link" onClick={handleToggleClick}>
            {showAll ? 'See less..' : 'See more..'}
          </Button>
        )}
      </div>
    </div>
  );
};

export default SubscriptionDetails;

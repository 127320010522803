export const newsConstants={
    POST_NEWS_LOADING:"POST_NEWS_LOADING",
    POST_NEWS_SUCCESS:"POST_NEWS_SUCCESS",
    POST_NEWS_ERROR:"POST_NEWS_ERROR",

    GET_NEWS_LOADING:"GET_NEWS_LOADING",
    GET_NEWS_SUCCESS:"GET_NEWS_SUCCESS",
    GET_NEWS_ERROR:"GET_NEWS_ERROR",

    DELETE_NEWS_LOADING:"DELETE_NEWS_LOADING",
    DELETE_NEWS_SUCCESS:"DELETE_NEWS_SUCCESS",
    DELETE_NEWS_ERROR:"DELETE_NEWS_ERROR",

    UPLOAD_PDF_LOADING:"UPLOAD_PDF_LOADING",
    UPLOAD_PDF_SUCCESS:"UPLOAD_PDF_SUCCESS",
    UPLOAD_PDF_ERROR:"UPLOAD_PDF_ERROR",
}

import { closingConstants } from "./action-types";

const initialState = {
  transactionEmail:[],
  transactionEmailerror:null,

  getTitleCompanyData: null,
  getTitleCompanyLoading: false,
  getTitleCompanyError:false,

  getTitleOfficeData: [],
  getTitleOfficeLoading:true,
  getTitleOfficeError:false,

  titleReps:null,
  titleClosers:null,

  addTitleOfficeData: null,
  addTitleOfficeLoading: false,
  addTitleOfficeError: null,

  getTitleCompanySelectedData: null,
  getTitleCompanyDataLoading: false,
  getTitleCompanyDataError:false,

  openTitleCompanyModal: false,
  titleOfficeLogoModal:false,
  selectOffice: null,
  companyModalStep: 0,
  titleOffice: null,
  titleCloser: "",
  titleRep: "",

  sharedDocs: [],
  sharedDocsLoading: false,
  sharedDocsError:false,
  
};

const transactionReport = (state = initialState, action) => {
  switch (action.type) {
    case closingConstants.TRANSACTION_EMAIL_SUCCESS:{
        return {
            ...state,
            transactionEmail:action.transactionEmail
        }
    }
    case closingConstants.TRANSACTION_EMAIL_FAIL:{
        return {
            ...state,
            transactionEmailerror:action.error
        }
    }
    case closingConstants.GET_TITLE_COMPANY_LOADING:
      return {
        ...state,
        getTitleCompanyData: null,
        getTitleCompanyLoading: true,
        getTitleCompanyError: null,
      };
    case closingConstants.GET_TITLE_COMPANY_SUCCESS:
      return {
        ...state,
        getTitleCompanysData: action.titleCompanydata,
        getTitleCompanyLoading: false,
        getTitleCompanyError: null,
      };
    case closingConstants.GET_TITLE_COMPANY_ERROR:
      return {
        ...state,
        getTitleCompanyData: [],
        getTitleCompanyLoading: false,
        getTitleCompanyError: null,
      };
      case closingConstants.GET_TITLE_OFFICE_LOADING:
        return {
          ...state,
          getTitleOfficeData:[],
          getTitleOfficeLoading: true,
          getTitleOfficeError: false,
      
        };
      case closingConstants.GET_TITLE_OFFICE_SUCCESS:
        
       return {
          ...state,
           getTitleOfficeLoading:false,
          getTitleOfficeData:action.titleOfficedata ,
          titleReps:action.titleOfficedata?.[0].salesReps,
          titleClosers:action.titleOfficedata?.[0].titleClosers,
          getTitleOfficeError: false,
        };
      case closingConstants.GET_TITLE_OFFICE_ERROR:
        return {
          ...state,
          getTitleOfficeData: [],
          getTitleOfficeLoading: false,
          getTitleOfficeError: true,
        };
        case closingConstants.GET_TITLE_COMPANY_DATA_LOADING:
          return {
            ...state,
            getTitleCompanySelectedData:null,
            getTitleCompanyDataLoading: true,
            getTitleCompanyDataError: null,
          }; 
          case closingConstants.GET_TITLE_COMPANY_DATA_SUCCESS:
            return {
              ...state,
              getTitleCompanySelectedData: action.titleCompanyselecteddata,
              getTitleCompanyDataLoading: false,
              getTitleCompanyDataError: null,
            };
          case closingConstants.GET_TITLE_COMPANY_DATA_ERROR:
            return {
              ...state,
              getTitleCompanySelectedData: null,
              getTitleCompanyDataLoading: false,
              getTitleCompanyDataError: true,
            }; 
        case closingConstants.ADD_TITLE_OFFICE_LOADING:
        return {
          ...state,
          addTitleOfficeData: null,
          addTitleOfficeLoading: true,
          addTitleOfficeError: null,
        };
      case closingConstants.ADD_TITLE_OFFICE_SUCCESS:
        return {
          ...state,
          addTitleOfficeData: action.titleOfficedata,
          addTitleOfficeLoading: false,
          addTitleOfficeError: null,
        };
      case closingConstants.ADD_TITLE_OFFICE_ERROR:
        return {
          ...state,
          addTitleOfficeData: [],
          addTitleOfficeLoading: false,
          addTitleOfficeError: null,
        };
      case closingConstants.SET_TITLE_COMPANY_MODAL_STATE:
        return {
          ...state,
          ...action.payload,
          // openTitleCompanyModal:action.openTitleCompanyModal,
          // selectOffice:action.selectOffice,
          // companyModalStep:action.companyModalStep,
          // titleOffice:action.titleOffice,
          // titleCloser:action.titleCloser,
          // titleRep:action.titleRep,
        };
        case closingConstants.GET_SHARED_DOCS_LOADING:
        return {
          ...state,
          sharedDocs: [],
          sharedDocsLoading: true,
          sharedDocsError:false,
        };
        case closingConstants.GET_SHARED_DOCS_FOR_ERROR:
        return {
          ...state,
          sharedDocsError:true,
          sharedDocsLoading:false,
        }
      case closingConstants.GET_SHARED_DOCS_FOR_TR:
        return {
          ...state,
          sharedDocs: action.sharedDocs,
          sharedDocsLoading:false,
          sharedDocsError:false,
        }
      
      default :
      return state;
    }
}

export { transactionReport };

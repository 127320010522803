import { Form, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { CreateTitleCompanyForm } from './CreateTitleCompanyForm';
import { useDispatch, useSelector } from 'react-redux';
import { firmDetailsHandle } from '../../FirmCreation/state/action';
import { roleAccessFunc } from '../../Common/commondata/commonData';
// import { firmDetailsHandle } from '../../FirmCreation/state/action';

const { TabPane } = Tabs;

const CreateTitlecompany = ({form}) => {
  const dispatch = useDispatch();
  const { getUserDetailsData, roleAccessed, getCompanyData, getCompanyLoading, getCompanyError } = useSelector(
    (state) => state.account
  );
 const [tag, setTag] = useState("COMPANY");
  const [showCreateModal, setShowCreateModal] = useState(false);
   
  useEffect(()=>{
    if (roleAccessFunc(roleAccessed, "TITLE_COMPANY_CREATE")) {
      setTag("OFFICE");
    }
  },[getUserDetailsData])

  useEffect(() => {
    if (tag === "COMPANY") {
    } else if (tag === "OFFICE") {
      let data = { titleType: "TITLE_COMPANY" };
      dispatch(firmDetailsHandle.getAllCompany(data));
    } else if (tag=== "SALESREP") {
      if (getUserDetailsData.length === 0) {
        const data = { titleType: "TITLE_COMPANY" };
        dispatch(firmDetailsHandle.getAllCompany(data));
      }
      let data = { titleType: "TITLE_OFFICE" };
      dispatch(firmDetailsHandle.getAllOffice(data));
    }
  }, [tag]);

  return (
    <>
      <Tabs style={{ marginTop: "-30px"}} defaultActiveKey={"1"}>
        {
          roleAccessFunc(roleAccessed, "TITLE_COMPANY_CREATE") ? 
          <TabPane
            tab={
              <span
                className="mozFontSize"
                style={{
                  fontSize: "1.2rem",
                  paddingLeft: "1rem",
                }}
                onClick={() => {
                  setTag("COMPANY");
                  form.resetFields();
                  dispatch(firmDetailsHandle.resetImageUrl());
                }}
              >
                Title Company
              </span>
            }
            key="1"
          >
            <CreateTitleCompanyForm
              tag={tag}
              form={form}
              setShowCreateModal={setShowCreateModal}
            />
          </TabPane> : ""
        }

        {
          roleAccessFunc(roleAccessed, "TITLE_OFFICE_CREATE") ? 
          <TabPane
            tab={
              <span
                className="mozFontSize"
                style={{
                  fontSize: "1.2rem",
                }}
                onClick={() => {
                  setTag("OFFICE");
                  form.resetFields();
                  dispatch(firmDetailsHandle.resetImageUrl());
                }}
              >
                Title Office
              </span>
            }
            key="2"
          >
            <CreateTitleCompanyForm
              tag={tag}
              form={form}
              setShowCreateModal={setShowCreateModal}
            />
          </TabPane> : ""
        }
        {
          // roleAccessed && roleAccessed?.[getUserDetailsData.adminRole]?.["TITLE_OFFICE_CREATE"] ? 
          <TabPane
            tab={
              <span
                className="mozFontSize"
                style={{
                  fontSize: "1.2rem",
                }}
                onClick={() => {
                  setTag("SALESREP");
                  form.resetFields();
                  dispatch(firmDetailsHandle.resetImageUrl());
                }}
              >
                Sales Rep
              </span>
            }
            key="3"
          >
            <CreateTitleCompanyForm
              tag={tag}
              form={form}
              setShowCreateModal={setShowCreateModal}
            />
          </TabPane>
        }

{
          // roleAccessed && roleAccessed?.[getUserDetailsData.adminRole]?.["TITLE_OFFICE_CREATE"] ? 
          <TabPane
            tab={
              <span
                className="mozFontSize"
                style={{
                  fontSize: "1.2rem",
                }}
                onClick={() => {
                  setTag("TITLECLOSERS");
                  form.resetFields();
                  dispatch(firmDetailsHandle.resetImageUrl());
                }}
              >
                Title Closures
              </span>
            }
            key="4"
          >
            <CreateTitleCompanyForm
              tag={tag}
              form={form}
              setShowCreateModal={setShowCreateModal}
            />
          </TabPane>
        }
        
      </Tabs>
    </>
  );
};

export { CreateTitlecompany };

import {
    Button,
    Input,
    Space,
    Table,
    Typography,
    Select,
    List,
    Pagination,
    Avatar,
  } from "antd";
  import React, { useEffect, useRef, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { useLocation } from "react-router-dom";
  import { logsDataHandle } from "../state/actions";
  import { Loading } from "../../Common";
  import { SearchOutlined } from "@ant-design/icons";
  import "../styles/logs.css";
  import moment from "moment";
  import Highlighter from "react-highlight-words";
  import _ from "lodash";

  function PropertyCreatedLogs() {
    const location = useLocation();
    const dispatch = useDispatch();
    const searchRef = useRef(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [query, setQuery] = useState({
      logType:
        location.pathname === "/propertyCreatedLogs"
          ? "MANUAL_PROPERTY_CREATED_LOGS"
          : location.pathname === "/mlsPropertyCreatedLogs"
            ? "MLS_PROPERTY_CREATED_LOGS"
            : "",
    });
    const { getLogsData, getLogsLoading, totalRecords } = useSelector(
      (state) => state.getLogsData,
    );

    useEffect(() => {
      let queryData = {};
      if (location.pathname === "/propertyCreatedLogs") {
        queryData = {
          logType: "MANUAL_PROPERTY_CREATED_LOGS",
          page: 1,
        };
      } else if (location.pathname === "/mlsPropertyCreatedLogs") {
        queryData = {
          logType: "MLS_PROPERTY_CREATED_LOGS",
          page: 1,
        };
      }
      dispatch(logsDataHandle.getAgentCreatedLogsData(queryData));
      setCurrentPage(1);
      setQuery(queryData);
    }, [location.pathname]);

    const debouncedHandleSearch = _.debounce((queryData) => {
      dispatch(logsDataHandle.getAgentCreatedLogsData(queryData));
    }, 1000);

    const handleSearch = (value) => {
      let queryData = {};
      if(value){
        queryData.search = value;
      }else{
        queryData.page = currentPage;
      }
      if (location.pathname === "/propertyCreatedLogs") {
        queryData.logType = "MANUAL_PROPERTY_CREATED_LOGS";
      } else if (location.pathname === "/mlsPropertyCreatedLogs") {
        queryData.logType = "MLS_PROPERTY_CREATED_LOGS";
      }
      debouncedHandleSearch(queryData);
    };

    return (
      <div id="logs">
            <div style={{ background: "white" }}>
            <div style={{display:"flex",justifyContent:"center",fontSize:"large"}}>
                <h1>{location.pathname === "/propertyCreatedLogs" ? "MANUAL PROPERTY LOGS":"MLS PROPERTY LOGS"}</h1>
            </div>
              {/* serach input box  */}
              <div style={{ width: "50%", margin: "auto", padding: "5px" }}>
                <Input
                  allowClear
                  suffix={<SearchOutlined />}
                  onChange={(e) =>{handleSearch(e.target.value)}}
                />
              </div>
              {/* main list box  */}
              {getLogsLoading ?
              <div><Loading/></div>:
              <div style={{ height: "100vh" }}>
                <List
                  loading={getLogsLoading}
                  bordered
                  dataSource={getLogsData}
                  renderItem={(item, index) => (
                    <List.Item key={index}>
                      {/* Rendering content here */}
                      <div style={{width:"100%",display:"flex",fontSize:"15px",fontWeight:"bold"}}>
                          <div style={{ width: "85%",}}>
                            <Typography.Text>
                              {/* <span>
                                {" "}
                                Using the {item?.platformUsed === "WEB" ? "Webapp" : "Mobileapp"} platform,{" "}
                              </span> */}
                              <span>
                               <span>Property name</span>
                               <span> {location.pathname === "/propertyCreatedLogs"
                                ? item?.manualPropertyCreatedData?.fullAddress
                                : item?.mlsPropertyCreatedData?.fullAddress}
                                </span>
                             </span>
                              <span>
                                <span> is created by</span>
                                <span> {item?.createdByData?.fullName}</span>
                                <span> ({item?.createdByData?.email}) </span>
                              </span>

                              {location.pathname ===
                                "/mlsPropertyCreatedLogs" && (
                                <>
                                  <span
                                    style={{
                                      marginLeft: "5px",
                                      marginRight: "5px",
                                    }}
                                  >
                                    <span>through </span>
                                  </span>
                                  {(item?.mlsPropertyCreatedLog?.createdThrough === "RESIDENTIAL_OFFER") ? "Residential Offer" :
                                  (item?.mlsPropertyCreatedLog?.createdThrough === "LINK_MLS") ? "Link Mls" :
                                  item?.mlsPropertyCreatedLog?.createdThrough }
                                </>
                              )}

                              {location.pathname === "/propertyCreatedLogs"
                                ? item?.manualPropertyCreatedForSellerAgentData
                                    ?.email && (
                                    <span>
                                      {" "}
                                     <span> for the seller agent </span>
                                      <span>
                                      {item?.manualPropertyCreatedForSellerAgentData?.fullName ?
                                       item?.manualPropertyCreatedForSellerAgentData?.fullName : ""}
                                      </span> (
                                      {
                                        item
                                          ?.manualPropertyCreatedForSellerAgentData
                                          ?.email
                                      }
                                      ){" "}
                                    </span>
                                  )
                                : item?.mlsPropertyCreatedForSellerAgentData
                                    ?.email && (
                                    <span>
                                      {" "}
                                      for the seller agent email address (
                                      {
                                        item?.mlsPropertyCreatedForSellerAgentData
                                          ?.email
                                      }
                                      ).{" "}
                                    </span>
                                  )}
                            </Typography.Text>
                          </div>


                      <div style={{ width: "15%",marginLeft:"20px" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <div
                            style={{
                              width: "10px",
                              height: "10px",
                              borderRadius: "100px",
                              backgroundColor: "green",
                            }}
                          ></div>
                          {"Created"}
                        </div>
                        <div>
                          {item?.createdAt
                            ? moment(item?.createdAt).format("MMMM D, YYYY")
                            : ""}
                        </div>
                      </div>
                      </div>
                    </List.Item>
                  )}
                />
                <Pagination
                  style={{ textAlign: "center", padding: "10px" }}
                  current={currentPage}
                  total={totalRecords}
                  pageSize={5}
                  showSizeChanger={false}
                  onChange={(page) => {
                    if (!searchRef.current) {
                      searchRef.current = true;
                      return;
                    }
                    dispatch(
                      logsDataHandle.getAgentCreatedLogsData({
                        ...query,
                        logType:
                          location.pathname === "/propertyCreatedLogs"
                            ? "MANUAL_PROPERTY_CREATED_LOGS"
                            : "MLS_PROPERTY_CREATED_LOGS",
                        page: page,
                      }),
                    );
                    setQuery({
                      ...query,
                      logType:
                        location.pathname === "/propertyCreatedLogs"
                          ? "MANUAL_PROPERTY_CREATED_LOGS"
                          : "MLS_PROPERTY_CREATED_LOGS",
                      page: page,
                    });
                    setCurrentPage(page);
                  }}
                />
              </div>}
            </div>
      </div>
    );
  }

  export default PropertyCreatedLogs;

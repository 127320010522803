import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  FilterFilled,
  SearchOutlined
} from "@ant-design/icons";
import { Button, Image, Input, Modal, Select, Space, Spin, Table } from "antd";
import { officeTypeHandle } from "../state/actions";
import Highlighter from "react-highlight-words";
import { useRef } from "react";

const { confirm } = Modal;

export default function UpdatedFirmsVerify() {
  const dispatch = useDispatch();
  const { firmUpdateData,totalUpdateRecords,firmUpdateLoading } = useSelector((state) => state.officeType);
 const handleFirmUpdate = (officeId, isVerified) => {
    confirm({
      title: "Are you sure you want to Mark it Verified?",
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      content: "Note: Changes will be live once Verified",
      onOk() {
        dispatch(officeTypeHandle.updatedFirmVerify(officeId, isVerified));
      },
      onCancel() {},
    });
  };

//filtering functionality
const [filterbyOffice,setFilterbyoffice]=useState("")
const [searchText, setsearchText] = useState("");
const [searchedColumn, setsearchedColumn] = useState("");
const searchRef = useRef(false);
const searchInput = useRef();
const [disabledBtn, setdisabledBtn] = useState(true);
const [currentQuery,setCurrentQuery]=useState({})
let getoffice = (dataIndex) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <div style={{marginBottom:"10px"}}>
      <Select
              style={{ width: "100%" }}
              placeholder="Select Firm Type"
              onChange={(role, event) => {
                setFilterbyoffice(role)
              }}
              options={[
                  {
                    value: "FRANCHISE",
                    label: "FRANCHISE",
                  },
                  {
                    value: "BROKERAGE",
                    label: "BROKERAGE",
                  },
                  {
                    value: "OFFICE",
                    label: "OFFICE",
                  },
              ]}
            />
            </div>
      <Space>
        <Button
          type="primary"
          onClick={() => {handleFilterChange(filterbyOffice);setdisabledBtn(false)}}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Filter
        </Button>
        <Button
          onClick={() => {handleReset(clearFilters, setSelectedKeys);setdisabledBtn(true)}}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <FilterFilled style={{ color: filtered ? "#1890FF" : undefined }} />
  ),
  render: (text) =>
    searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: "#FFC069", padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    ) : (
      text
    ),
});

const handleFilterChange=(value)=>{
  let query = {
     ...currentQuery,
     firmType:value
   };
   dispatch(officeTypeHandle.getUpdatedFirms(query));
   setCurrentQuery(query)
   setCurrentPage(1);
 }
 const handleReset = (clearFilters, setSelectedKeys) => {
  clearFilters();
  setSelectedKeys("");
  setCurrentQuery({})
  let query = {
    page: 1,
    limit:20,
  };
  dispatch(officeTypeHandle.getUpdatedFirms(query));
  setsearchText("");
  searchRef.current = false;
};
const handleResetfilters = () => {
    setCurrentQuery({})
    let query = {
      page: 1,
      limit:20,
    };
    dispatch(officeTypeHandle.getUpdatedFirms(query));
    setsearchText("");
    searchRef.current = false;
  };
let getColumnSearchProps = (dataIndex) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={(node) => {
          searchInput.current = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => {handleSearch(selectedKeys, confirm, dataIndex);setdisabledBtn(false)}}
        style={{ width: 188, marginBottom: 8, display: "block" }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => {handleSearch(selectedKeys, confirm, dataIndex);setdisabledBtn(false)}}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          onClick={() => {handleReset(clearFilters, setSelectedKeys);setdisabledBtn(true)}}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>

      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  ),

  render: (text) =>

    searchedColumn === dataIndex ? (
     <>
     {
   dataIndex == "creator"?<div><Highlighter
     highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
     searchWords={[searchText]}
     autoEscape
     textToHighlight={text ? text.fullName : ""}
     />
     <div style={{ fontSize: "11px", color: "#787474" }}>
     <Highlighter
     highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
     searchWords={[searchText]}
     autoEscape
     textToHighlight={text ? text.email: ""}
      /></div></div>
   :
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.Address.toString() : ""}
      />
     }
     </>
    ) : (<>
      {dataIndex =="creator"?
          <div>
           <div>{text.fullName ? text.fullName : "-"}</div>
           <div style={{ fontSize: "11px", color: "#787474" }}>
           {text.email ? `${text.email}` : ""}
            </div>
          </div>:
          text}
      </>
    ),
});
const handleSearch = (selectedKeys, confirm, dataIndex) => {
  searchRef.current = true;
  confirm();
 setsearchText(selectedKeys[0]);
 let query = {
    ...currentQuery,
   nameEmailSearch: selectedKeys[0],
   page: 1,
   limit:20,
  };
dispatch(officeTypeHandle.getUpdatedFirms(query));
  setsearchedColumn(dataIndex);
setCurrentQuery(query);
  setCurrentPage(1);
};
 // pagination logic
 const [currentPage,setCurrentPage]=useState(1)
 useEffect(() => {
let query = {
    ...currentQuery,
    page: currentPage,
    limit: 20,
  };
dispatch(officeTypeHandle.getUpdatedFirms(query));
}, [dispatch]);

  return (
    <>
    { firmUpdateLoading? (
      <div
         style={{
           display: "flex",
           height: "70vh",
           justifyContent: "center",
           alignItems: "center",
         }}
       >
         <Spin />
       </div>):
    (
        <>
        <div style={{position:"absolute",top:"98px",zIndex:"111",left :'300px'}}>
             <Button disabled={disabledBtn} onClick={()=>{handleResetfilters();setdisabledBtn(true)}} size="" style={{marginLeft:"60px"}}>Reset Filters</Button>
        </div>
    <Table
     scroll={{ y: "60vh" }}
      columns={[
        {
          title: "FIRM UPDATED",
          dataIndex: "name",
          key: "name",
          ...getoffice()
        },
        {
          title: "CREATED BY",
          dataIndex: "creator",
          key: "creator",
          width: "20%",
          ...getColumnSearchProps("creator"),
        },
        {
          title: "STATUS",
          dataIndex: "status",
          key: "status",
          width: "10%",
          render: (isVerified) => (

            <div>
              {isVerified ? (
                <div
                  style={{ display: "flex", gap: "5px", alignItems: "center" }}
                >
                  <CheckCircleOutlined
                    style={{ color: "#4bd33a", fontSize: "18px" }}
                  />
                  Verified{isVerified}
                </div>
              ) : (
                <div
                  style={{ display: "flex", gap: "5px", alignItems: "center" }}
                >
                  <CloseCircleOutlined
                    style={{ color: "#e73939", fontSize: "18px" }}
                  />
                  Not Verified{isVerified}
                </div>
              )}
            </div>
          ),
        },
        {
          title: "FRANCHISE",
          dataIndex: "franchise",
          key: "franchise",
          width: "17%",
          render: (text, allData) => (
            <div>
                {
                    allData.franchiseUpdate ? <div style={{fontWeight: 600}}>From:</div> : ""
                }
                <div style={{display:"flex",alignItems:"center", gap:"5px"}}>
                  {text?.name && allData?.franchise?.officeLogo ? <Image src={allData.franchise.officeLogo} alt="" width={"30px"}/> : ""}
                  {text.name ? text.name : "-"}
                </div>
                <div style={{ fontSize: "11px", color: "#787474" }}>
                    {text.address ? `Address: ${text.address}` : ""}
                    {text.email ? <><br/>Email: {text.email}</> : ""}
                    {text.phonenumber ? <><br/>Phone Number: {text.phonenumber}</> : ""}
                </div>
                {
                    allData.franchiseUpdate ? <div>
                        <div style={{fontWeight: 600, marginTop: "10px"}}>To:</div>
                        <div style={{display:"flex",alignItems:"center", gap:"5px"}}>
                          {text?.name && allData?.franchiseUpdate?.officeLogo ? <Image src={allData.franchiseUpdate.officeLogo} alt="" width={"30px"}/> : ""}
                          {allData.franchiseUpdate.name ? allData.franchiseUpdate.name : "-"}
                        </div>
                        <div style={{ fontSize: "11px", color: "#787474" }}>
                            {allData.franchiseUpdate.address ? `Address: ${allData.franchiseUpdate.address}` : ""}
                            {allData.franchiseUpdate.email ? <><br/>Email: {allData.franchiseUpdate.email}</> : ""}
                            {allData.franchiseUpdate.phoneNumber ? <><br/>Phone Number: {allData.franchiseUpdate.phoneNumber}</> : ""}
                        </div>
                    </div> : ""
                }
            </div>
          ),
        },
        {
          title: "BROKERAGE",
          dataIndex: "brokerage",
          key: "brokerage",
          width: "17%",
          render: (text, allData) => (
            <div>
              
                {
                    allData.brokerageUpdate ? <div style={{fontWeight: 600}}>From:</div> : ""
                }
                <div style={{display:"flex",alignItems:"center", gap:"5px"}}>
                  {text?.name && allData?.brokerage?.officeLogo ? <Image src={allData.brokerage.officeLogo} alt="" width={"30px"}/> : ""}
                  {text.name ? text.name : "-"}
                  </div>
                  <div>
                    {allData?.licenseNumber ? `License No: ${allData?.licenseNumber}` : ""}
                  </div>
                <div style={{ fontSize: "11px", color: "#787474" }}>
                    {text.address ? `Address: ${text.address}` : ""}
                    {text.email ? <><br/>Email: {text.email}</> : ""}
                    <br/>{text.phonenumber ? <><br/>Phone Number: {text.phonenumber}</> : ""}
                </div>
                {
                    allData.brokerageUpdate ? <div>
                        <div style={{fontWeight: 600, marginTop: "10px"}}>To:</div>
                        <div style={{display:"flex",alignItems:"center", gap:"5px"}}>
                          {text?.name && allData?.brokerageUpdate?.officeLogo ? <Image src={allData.brokerageUpdate.officeLogo} alt="" width={"30px"}/> : ""}
                          {allData.brokerageUpdate.name ? allData.brokerageUpdate.name : "-"}
                          </div>
                          <div>
                            {allData?.licenseNumber ? `License No: ${allData?.licenseNumber}` : ""}
                        </div>
                        <div style={{ fontSize: "11px", color: "#787474" }}>
                            {allData.brokerageUpdate.address ? `Address: ${allData.brokerageUpdate.address}` : ""}
                            {allData.brokerageUpdate.email ? <><br/>Email: {allData.brokerageUpdate.email}</> : ""}
                            {allData.brokerageUpdate.phoneNumber ? <><br/>Phone Number: {allData.brokerageUpdate.phoneNumber}</> : ""}
                        </div>
                    </div> : ""
                }
            </div>
          ),
        },
        {
          title: "OFFICE",
          dataIndex: "office",
          key: "office",
          width: "17%",
          render: (text, allData) => (
            <div>
                {
                    allData.officeUpdate ? <div style={{fontWeight: 600}}>From:</div> : ""
                }
                <div>{text.name ? text.name : "-"}</div>
                <div style={{ fontSize: "11px", color: "#787474" }}>
                    {text.address ? `Address: ${text.address}` : ""}
                    {text.email ? <><br/>Email: {text.email}</> : ""}
                    {text.phonenumber ? <><br/>Phone Number: {text.phonenumber}</> : ""}
                </div>
                {
                    allData.officeUpdate ? <div>
                        <div style={{fontWeight: 600, marginTop: "10px"}}>To:</div>
                        <div>{allData.officeUpdate.name ? allData.officeUpdate.name : "-"}</div>
                        <div style={{ fontSize: "11px", color: "#787474" }}>
                            {allData.officeUpdate.address ? `Address: ${allData.officeUpdate.address}` : ""}
                            {allData.officeUpdate.email ? <><br/>Email: {allData.officeUpdate.email}</> : ""}
                            {allData.officeUpdate.phoneNumber ? <><br/>Phone Number: {allData.officeUpdate.phoneNumber}</> : ""}
                        </div>
                    </div> : ""
                }
            </div>
          ),
        },
        {
          title: "UPDATE",
          dataIndex: "update",
          key: "update",
          width:"9%",
          render: (isVerified, ele) => (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {isVerified ? (
                <CheckCircleOutlined
                  style={{ color: "#4bd33a", fontSize: "18px" }}
                />
              ) : (
                <button
                  style={{ cursor: "pointer" }}
                  onClick={() => handleFirmUpdate(ele.id, true)}
                >
                  Mark Verified
                </button>
              )}
            </div>
          ),
        },
      ]}
      dataSource={firmUpdateData}
      bordered
      pagination={{
        position: ["bottomCenter"],
        showSizeChanger: false,
        pageSize: 20,
        current: currentPage,
        total:totalUpdateRecords,
        onChange: (page) => {

          if (!searchRef.current) {
          dispatch(officeTypeHandle.getUpdatedFirms({  page: page,
                limit: 20,}));
          }
              setCurrentPage(page);
            },
     }}
    /></>)
    }
    </>
  );
}

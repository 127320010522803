import { firmConstants } from "./action-types";

const initialState = {
  officeLoading: true,
  officeError: false,
  officeData: [],

  postImageUrl: "",
  postImageLoading: false,
  postImageError: false,

  filteredFranchiseData: [],
  filteredBrokrageData: [],
  filteredOfficeData: [],

  getFranchiseData: [],
  getFranchiseLoading: false,
  getFranchiseError: false,

  createFranchiseData: [],
  createFranchiseLoading: false,
  createFranchiseError: false,

  editFranchiseData: [],
  editFranchiseLoading: false,
  editFranchiseError: false,

  adminSearchLoading: false,
  adminSearchError: false,
  adminSearchData: [],

  FJGGAcessData : [],
  FJGGAcessDataLoading : false,
  FJGGAcessDataError : false,
  
  getCompanyData: [],
  getCompanyLoading: false,
  getCompanyError: false,
  
  getOfficeData: [],
  getOfficeLoading: false,
  getOfficeError: false,
};

const firmDetails = (state = initialState, action) => {
  switch (action.type) {
    // Post image
    case firmConstants.POST_IMAGE_LOADING:
      return {
        ...state,
        postImageUrl: "",
        postImageLoading: true,
        postImageError: false,
      };
    case firmConstants.POST_IMAGE_SUCCESS:
      return {
        ...state,
        postImageUrl: action.postImageUrl,
        postImageLoading: false,
        postImageError: false,
      };
    case firmConstants.POST_IMAGE_FAILURE:
      return {
        ...state,
        postImageLoading: false,
        postImageError: true,
      };

    case firmConstants.RESET_IMAGE_URL:
      return {
        ...state,
        postImageUrl: "",
        postImageLoading: false,
        postImageError: true,
      };
    // Create a new Franchise
    case firmConstants.CREATE_FRANCHISE_DETAILS_LOADING:
      return {
        ...state,
        createFranchiseLoading: true,
        createFranchiseError: false,
      };
    case firmConstants.CREATE_FRANCHISE_DETAILS_SUCCESS:
      return {
        ...state,
        createFranchiseData: action.createFranchiseData,
        createFranchiseLoading: false,
        createFranchiseError: false,
      };
    case firmConstants.CREATE_FRANCHISE_DETAILS_FAILURE:
      return {
        ...state,
        createFranchiseLoading: false,
        createFranchiseError: false,
      };
    case firmConstants.GET_BROKRAGE_DETAILS_LOADING:
      return {
        ...state,
        getBrokrageLoading: true,
        getBrokrageError: false,
      };
    case firmConstants.GET_BROKRAGE_DETAILS_SUCCESS:
      const brokerageData = action?.getBrokrageData?.data?.filter((el) => el?.type === "BROKERAGE")
      return {
        ...state,
        getBrokrageData: brokerageData,
        getBrokrageLoading: false,
        getBrokrageError: false,
      };
    case firmConstants.GET_BROKRAGE_DETAILS_FAILURE:
      return {
        ...state,
        getBrokrageLoading: false,
        getBrokrageError: true,
      };
    // get all Licenses
    case firmConstants.GET_FRANCHISE_DETAILS_LOADING:
      return {
        ...state,
        getFranchiseLoading: true,
        getFranchiseError: false,
      };
    case firmConstants.GET_FRANCHISE_DETAILS_SUCCESS:
      return {
        ...state,
        getFranchiseData: action.getFranchiseData,
        getFranchiseLoading: false,
        getFranchiseError: false,
      };
    case firmConstants.GET_FRANCHISE_DETAILS_FAILURE:
      return {
        ...state,
        getFranchiseLoading: false,
        getFranchiseError: false,
      };
case firmConstants.EDIT_FRANCHISE_DETAILS_LOADING:
  return {
    ...state,
    editFranchiseLoading: true,
    editFranchiseError: false,

  }
  case firmConstants.EDIT_FRANCHISE_DETAILS_SUCCESS:
    return {
      ...state,
      editFranchiseData:action.editfranchiseData,
      editFranchiseLoading:false,
      editFranchiseError: false,
    }
    case firmConstants.EDIT_FRANCHISE_DETAILS_FAILURE:
      return {
        ...state,
        editFranchiseError:true,
        editFranchiseLoading:false,
      }
    case firmConstants.SEARCH_ADMIN_LOADING:
      return {
        ...state,
        adminSearchLoading: action.data,
        adminSearchError: false
      }
    case firmConstants.SEARCH_ADMIN_ERROR:
      return {
        ...state,
        adminSearchLoading: false,
        adminSearchError: true
      }
    case firmConstants.SEARCH_ADMIN_DATA:
      return {
        ...state,
        adminSearchLoading: false,
        adminSearchError: false,
        adminSearchData: action.data
      }
      case firmConstants.RESET_SEARCH_ADMIN_DATA:
      return {
        ...state,
        adminSearchLoading: false,
        adminSearchError: false,
        adminSearchData: []
      }
      case firmConstants.SEND_MAIL_FOR_CLAUSES_LOADING:
        return {
          ...state,
          SenMailforClauseLoading:true,
        };
      case firmConstants.SEND_MAIL_FOR_CLAUSES_SUCCESS:
        return {
          ...state,
          SenMailforClauseLoading:false,
        };
      case firmConstants.SEND_MAIL_FOR_CLAUSES_FAILURE:
        return {
          ...state,
          SenMailforClauseLoading:false,
        };
        case firmConstants.GET_ACCESS_TO_FJGG_DOCANDCLAUSES_LAODING:
        return {
          ...state,
          FJGGAcessDataLoading:true,
        };
       case firmConstants.GET_ACCESS_TO_FJGG_DOCANDCLAUSES_SUCCESS:
        return {
          ...state,
          FJGGAcessData:action.FJGGAccessData,
           FJGGAcessDataLoading:false,
        };
       case firmConstants.GET_ACCESS_TO_FJGG_DOCANDCLAUSES_ERROR:
        return {
          ...state,
          FJGGAcessDataError:action.error,
        };
      case firmConstants.GET_COMPANY_DETAILS_LOADING:
        return {
          ...state,
          getCompanyLoading: true,
          getCompanyError: false,
        };
      case firmConstants.GET_COMPANY_DETAILS_SUCCESS:
        return {
          ...state,
          getCompanyData: action.getCompanyData,
          getCompanyLoading: false,
          getCompanyError: false,
        };
      case firmConstants.GET_COMPANY_DETAILS_FAILURE:
        return {
          ...state,
          getCompanyLoading: false,
          getCompanyError: false,
        };

        case firmConstants.GET_OFFICE_DETAILS_LOADING:
        return {
          ...state,
          getOfficeLoading: true,
          getOfficeError: false,
        };
      case firmConstants.GET_OFFICE_DETAILS_SUCCESS:
        return {
          ...state,
          getOfficeData: action.getOfficeData,
          getOfficeLoading: false,
          getOfficeError: false,
        };
      case firmConstants.GET_OFFICE_DETAILS_FAILURE:
        return {
          ...state,
          getOfficeLoading: false,
          getOfficeError: false,
        };
    default:
      return state;
  }
};
export { firmDetails };

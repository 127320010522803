import { Modal } from 'antd'
import React from 'react'

const VideoModal = ({el,showVideo,handleVideoCancel}) => {

function extractUniqueIDFromYouTubeURL(url) {
    // Split the URL by '/' and pop the last element
    const parts = url?.[0]?.split('/');
    const uniqueID = parts.pop();
    return uniqueID;
}

const videoId = extractUniqueIDFromYouTubeURL(el?.videos);

    return (
        <div>
        <Modal visible={showVideo} footer={false} onCancel={handleVideoCancel} width={'80%'} style={{height:"auto",marginTop:"-90px"}}>
          <div style={{marginTop:"20px"}}>
            <iframe
                width="100%"
                height="500px"
                src={`https://youtube.com/embed/${videoId}`}
                title={el?.title}
                frameborder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowfullscreen
            ></iframe>
          </div>
        </Modal>

    </div>
  )
}

export default VideoModal

import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { CreateOffer } from "../CreateOffer";
import { Error404 } from "../Common/components/Error404";
import { PdfUpload } from "../PdfUpload";
import { ListingPage } from "../ListingPage";
import { PrivateRoute } from "./components/PrivateRoute";
import { LogoutWindow } from "./components/LogoutWindow";
import { SignupVerification } from "./components/SignupVerification";
import { Authenticate } from "../Authenticate";
import { Dashboard } from "../Dashboard";
import { ListingEdit } from "../ListingEdit";
import { Suggestion } from "../Suggestion";
import { CMS } from "../CMS";
import { PreviewContract } from "../PdfUpload/components/PreviewContract";
import { Failed } from "../Common/components/Failed";
import { CalenderComponent } from "../CalenderComponent";
import { Verification } from "../Verification";
import { OfferTable } from "../OfferTable";
import { NDA, NDAForm } from "../NDA";
import { NDAlisting } from "../NDA";
import { Clauses } from "../Clauses";
import { BugReportAdmin } from "../BugReport";
import { PreviewContractView } from "../ListingPage/PreviewContractView";
import { SuccessComponent } from "../Common/components/SuccessComponent";
import { DeleteComponent } from "../Common/components/DeleteComponent";
import "antd/dist/antd.css";
import ClientAuth from "../ClientAuth/ClientAuth";
import TransactionReport from "../TransactionReport/TransactionReport";
import OfficeType from "../OfficeType/OfficeType";
import FirmCreation from "../FirmCreation/FirmCreation";
import { Signup } from "../Authenticate/components/Signup";
import { ForgetPassword } from "../Authenticate/components/ForgotPassword";
import { SuccessChangePassword } from "../Authenticate/components/SuccessChangePassword";
// import Accounts from "../Accounts/Accounts";
import Accessed from "../OfficeType/components/Accessed";
// import { Subscription } from "../Subscription/Subscription";
import SubscriptionVerification from "../SubscriptionVerication/SubscriptionVerication";
import { TitleCompany } from "../TitleCompany/TitleCompany";
import { TitleCompanyVerfication } from "../TitleCompany/components/TitleCompanyVerfication";
import { AdminHelp } from "../Help/AdminHelp";
import { NewsLetter } from "../Newsletter/NewsLetter";
import FjggAccess from "../FJGG/FjggAccess";
import PropertyCreatedLogs from "../Logs/components/PropertyCreatedLogs";
import BrokerCreatedLogs from "../Logs/components/BrokerCreatedLogs";
import AgentCreatedLogs from "../Logs/components/AgentCreatedLogs";
import SubscriptionLogs from "../Logs/components/SubscriptionLogs";
import SpecialPlans from "../SubscriptionMain/componants/SpecialPlans";
import ShowEmailContent from "../CreateOffer/components/ShowEmailContent";
import VerificationWithTerms from "../Verification/VerificationWithTerms";
import { ContactSheet } from "../ContactSheet";
import CustomCalendar from "../CalenderComponent/CustomCalendar";
import DeletedDocumentSuccessComponent from "../Common/components/DeletedDocumentSuccessComponent";

function App() {
  // useEffect(() => {
  //   window.addEventListener("online", () => {
  //     setNetworkConnection(false);
  //     console.log("Became online");
  //     // location.reload();
  //   });
  //   window.addEventListener("offline", () => {
  //     setNetworkConnection(true);
  //     console.log("Became offline");
  //   });
  // }, []);
  //  {networkConnection && (
  //                   <div
  //                     style={{
  //                       paddingBottom: "10px",
  //                       backgroundColor: "#f0f2f5",
  //                     }}
  //                   >
  //                     <Alert message="No internet" type="error" />
  //                   </div>
  //                 )}
  // useEffect(() => {
  //   document.getElementById("loader") &&
  //     document.getElementById("loader").remove();
  // }, []);

  return (
    <div>
      <Router>
        <Switch>
          <PrivateRoute exact path="/" component={Dashboard} />
          <PrivateRoute exact path="/upload" component={PdfUpload} />
          <PrivateRoute exact path="/preview/:id" component={PreviewContract} />
          <PrivateRoute
            exact
            path="/previewView/:id"
            component={PreviewContractView}
          />
          <PrivateRoute exact path="/listing" component={ListingPage} />
          <PrivateRoute exact path="/listEdit" component={ListingEdit} />
          <PrivateRoute exact path="/user" component={CMS} />
          <PrivateRoute exact path="/suggestion" component={Suggestion} />
          <PrivateRoute exact path="/nda" component={NDAlisting} />
          <PrivateRoute exact path="/clauses" component={Clauses} />
          <PrivateRoute exact path="/test/:id" component={PreviewContract} />
          <PrivateRoute exact path="/bugs" component={BugReportAdmin} />
          <PrivateRoute exact path="/help" component={AdminHelp} />
          <PrivateRoute exact path="/fjggaccess" component={FjggAccess} />
          <PrivateRoute exact path="/news" component={NewsLetter} />
          <PrivateRoute exact path="/firmVerification" component={OfficeType} />
          <PrivateRoute exact path="/firmaccess" component={Accessed} />
          <PrivateRoute exact path="/firmCreation" component={FirmCreation} />

          <PrivateRoute exact path="/subscription" component={SpecialPlans} />

          {/* <PrivateRoute exact path="/subscription" component={Subscription} /> */}
          <PrivateRoute exact path="/subscriptionVerification" component={SubscriptionVerification} />
          <Route exact path="/clientAuth" component={ClientAuth} />
          <Route exact path="/success" component={SuccessComponent} />
          <Route exact path="/deletedDocumentSuccess" component={DeletedDocumentSuccessComponent} />
         <PrivateRoute exact path="/titleCompanyCreation" component={TitleCompany} />
         <PrivateRoute exact path="/titleCompanyVerification" component={TitleCompanyVerfication} />
         <PrivateRoute exact path="/propertyCreatedLogs" component={PropertyCreatedLogs} />
         <PrivateRoute exact path="/mlsPropertyCreatedLogs" component={PropertyCreatedLogs} />
         <PrivateRoute exact path="/sellerAgentCreatedLogs" component={BrokerCreatedLogs} />
         <PrivateRoute exact path="/buyerAgentCreatedLogs" component={BrokerCreatedLogs} />
         <PrivateRoute exact path="/agentCreatedLogs" component={AgentCreatedLogs} />
         <PrivateRoute exact path="/subscriptionLogs" component={SubscriptionLogs} />

          <Route exact path="/delete" component={DeleteComponent} />
          <Route
            exact
            path="/calendar/:documentId"
            component={CalenderComponent}
            // component={CustomCalendar}
          />

          <Route exact path="/error" component={Error404} />
          <Route exact path="/admin" component={Authenticate} />
          {/* // not in use */}
          {/* <Route exact path="/signup" component={Signup} /> */}
          <Route exact path="/forgetpassword" component={ForgetPassword} />

          {/* <PrivateRoute exact path="/rtd" component={} /> */}
           <Route
            exact
            path="/successpasswordchange"
            component={SuccessChangePassword}
          />
          <Route exact path="/logout" component={LogoutWindow} />
          <Route exact path="/failed" component={Failed} />
          <Route path="/offer/:id" component={OfferTable} />
          <Route exact path="/createOffer/:id" component={CreateOffer} />
          <Route exact path="/contractDocument/:id" component={CreateOffer} />
          <Route exact path="/test/:id" component={PreviewContract} />
          <Route exact path="/non_disclosure_agreement" component={NDAForm} />
          <Route exact path="/ndaForm" component={NDA} />
          <Route
            exact
            path="/transactionReport/:id"
            component={TransactionReport}
          />
          <Route
            exact
            path="/signupVerification"
            component={SignupVerification}
          />
          <Route
            exact
            path="/verification"
            component={Verification}
          />
          <Route
            exact
            path="/verificationWithTerms"
            component={VerificationWithTerms}
          />
          <Route
            exact
            path="/confirm/:id/:fullName/:userEmail/:source"
            component={Verification}
          />
          <Route
            exact
            path="/needToKnowEmails"
            component={ShowEmailContent}
          />
          <Route exact path="/contactSheet/:id" component={ContactSheet} />
          <Route path="*">
            <Redirect to="/error" />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export { App };

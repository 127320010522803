import axios from "axios";
const apiURL = process.env.REACT_APP_BASE_URL;

const verifyUserOtp = ({ OTP, OTPId }) => {
  return axios.post(
    `${apiURL}/offerDocument/authentication`,
    {
      OTP,
      OTPId,
    },
    {
      accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    }
  );
};

export const clienAuthApi = {
  verifyUserOtp,
};

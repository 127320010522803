import { adminConstants } from "./action-types";
import { adminDataApi } from "../utils/api";
import { message } from "antd";

/**
 * All Actions for Admin
 */

/**
 * To update Admin state
 * @param {string} auth
 */
const authError = (error) => {
  return {
    type: adminConstants.AUTHENTICATION_FAILURE,
    authError: error,
    authLoading: false,
  };
};

const authSuccess = (response) => {
  localStorage.setItem("auth", response.data.info.accessToken);
  return {
    type: adminConstants.AUTHENTICATION_SUCCESS,
    auth: response.data.info.accessToken,
    username: response.data.info.fullName,
    authLoading: false,
  };
};

const getAuthentication = (data) => {
  return (dispatch, getState) => {
    adminDataApi
      .adminCredentialRequest(data)
      .then((response) => {
        dispatch(authSuccess(response));
      })
      .catch((error) => {
        dispatch(authError(error));
      });
  };
};

const logOut = () => {
  localStorage.removeItem("auth");
  localStorage.removeItem("adminName");
  localStorage.removeItem("mobileToken");
  localStorage.removeItem("userID");
  localStorage.removeItem("adminRole");
  localStorage.removeItem("roleAccessed");
  return {
    type: adminConstants.LOGOUT_ACTION,
    authLoading: true,
    username: null,
    auth: null,
    logOutRedirect: true,
  };
};
const resetError = () => {
  return {
    type: adminConstants.RESET_ERROR,
  };
};
const resetLogout = () => {
  return {
    type: adminConstants.RESET_LOGOUT,
  };
};

const signupLoadingRealter = () => {
  return {
    type: adminConstants.SIGNUP_USER_LOADING,
    Signup_Loading: true,
    Signup_Error: false,
  };
};

const signupErrorRealter = (res) => {
  return {
    type: adminConstants.SIGNUP_USER_ERROR,
    Signup_Loading: false,
    Signup_Error: true,
  };
};

const signupSuccessRealter = (response) => {
  return {
    type: adminConstants.SIGNUP_USER_SUCCESS,
    Signup_Success: response,
    Signup_Loading: false,
    Signup_Error: false,
  };
};

// Forgot password actions
const userForgetPasswordLoading = () => {
  return {
    type: adminConstants.USER_FORGET_PASSWORD_LOADING,
  };
};

const userForgetPasswordError = (error) => {
  return {
    type: adminConstants.USER_FORGET_PASSWORD_FAILURE,
    userForgetPasswordError: error,
    userForgetPasswordLoading: false,
  };
};

const userForgetPasswordSuccess = (response, email) => {
  return {
    type: adminConstants.USER_FORGET_PASSWORD_SUCCESS,
    userForgetPasswordData: response.data.statusCode,
    userForgetPasswordDataEmail: email,
    userForgetPasswordLoading: false,
  };
};

const userForgetPasswordClear = () => {
  return {
    type: adminConstants.USER_FORGET_PASSWORD_CLEAR,
    userForgetPasswordData: null,
    userForgetPasswordDataEmail: null,
    userForgetPasswordLoading: true,
  };
};

const userForgetPassword = (data) => {
  return (dispatch, getState) => {
    dispatch(userForgetPasswordLoading());
    adminDataApi
      .userForgetPasswordRequest(data)
      .then((response) => {
        dispatch(userForgetPasswordSuccess(response, data.email));
      })
      .catch((error) => {
        dispatch(userForgetPasswordError(error));
      });
  };
};
// Varify password
const userForgetPasswordCodeVerificationLoading = () => {
  return {
    type: adminConstants.FORGET_PASSWORD_VERIFICATION_LOADING,
  };
};
const userForgetPasswordCodeVerificationError = (error) => {
  return {
    type: adminConstants.FORGET_PASSWORD_VERIFICATION_FAILURE,
    verificationError: error,
  };
};

const userForgetPasswordCodeVerificationSuccess = (response) => {
  return {
    type: adminConstants.FORGET_PASSWORD_VERIFICATION_SUCCESS,
    verificationData: response,
  };
};

const userForgetPasswordCodeVerification = (data, email) => {
  return (dispatch, getState) => {
    dispatch(userForgetPasswordCodeVerificationLoading());
    adminDataApi
      .userForgetPasswordCodeVerificationRequest(data, email)
      .then((response) => {
        dispatch(userForgetPasswordCodeVerificationSuccess(response));
      })
      .catch((error) => {
        dispatch(userForgetPasswordCodeVerificationError(error));
      });
  };
};

// Reset new password
const resetPasswordSaveNewPasswordLoading = () => {
  return {
    type: adminConstants.RESET_PASSWORD_SAVE_NEW_PASSWORD_LOADING,
  };
};
const resetPasswordSaveNewPasswordError = (error) => {
  return {
    type: adminConstants.RESET_PASSWORD_SAVE_NEW_PASSWORD_FAILURE,
    resetPasswordNewPasswordError: error,
  };
};

const resetPasswordSaveNewPasswordSuccess = (response) => {
  return {
    type: adminConstants.RESET_PASSWORD_SAVE_NEW_PASSWORD_SUCCESS,
    resetPasswordNewPasswordData: response,
  };
};

const resetPasswordSaveNewPassword = (data, email, verificationCode) => {
  return (dispatch, getState) => {
    dispatch(resetPasswordSaveNewPasswordLoading());
    adminDataApi
      .resetPasswordSaveNewPasswordRequest(data, email, verificationCode)
      .then((response) => {
        dispatch(resetPasswordSaveNewPasswordSuccess(response));
      })
      .catch((error) => {
        dispatch(resetPasswordSaveNewPasswordError(error));
      });
  };
};
const resetPasswordCleanUp = () => ({
  type: adminConstants.RESET_PASSWORD,
});

const resetAlert = () => ({
  type: adminConstants.RESET_ALERT,
});

const openBrokerageSelectModal = (payload) => {
  return {
    type: adminConstants.MANIPULATE_BROKERAGE_SELECT_MODAL,
    setBrokerageModal: payload,
  }
}

export const adminAction = {
  getAuthentication,
  logOut,
  resetError,
  resetLogout,
  userForgetPassword,
  userForgetPasswordClear,
  userForgetPasswordCodeVerification,
  resetPasswordCleanUp,
  resetAlert,
  resetPasswordSaveNewPassword,
  openBrokerageSelectModal,
};

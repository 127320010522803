import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
  FilterFilled
} from "@ant-design/icons";
import { Button, Image, Input, Modal, Select, Space, Spin, Table ,Tag} from "antd";
import { officeTypeHandle } from "../state/actions";
import Highlighter from "react-highlight-words";

const { confirm } = Modal;

export default function Created() {
  const dispatch = useDispatch();
  const searchInput = useRef();
  const searchRef = useRef(false);
  const [searchText, setsearchText] = useState("");
  const [searchedColumn, setsearchedColumn] = useState("");
  const { officeData ,totalRecords,officeLoading} = useSelector((state) => state.officeType);
  const [currentPage,setCurrentPage]=useState(1)
  const [currentQuery,setCurrentQuery]=useState({})
  const [disabledBtn, setdisabledBtn] = useState(true)
 useEffect(() => {
    let query = {
      ...currentQuery,
      page: currentPage,
    };
  dispatch(officeTypeHandle.getOfficeTypes("verifyCreatedFirm",query));
   }, [dispatch]);
 const handleOfficeUpdate = (officeId, isVerified) => {
   confirm({
      title: "Are you sure you want to Mark it Verified?",
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      content: "Note: Status cannot be changed once Verified",
      onOk() {
        dispatch(officeTypeHandle.updateOfficeType(officeId, isVerified,currentPage));
      },
      onCancel() {},
    });
  };
//filtering functionality
const [filterbyOffice,setFilterbyoffice]=useState("")
let getoffice = (dataIndex) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <div style={{marginBottom:"10px"}}>
      <Select
              style={{ width: "100%" }}
              placeholder="Select Firm Type"
              onChange={(role, event) => {
                setFilterbyoffice(role)
              }}
              options={[
                  {
                    value: "FRANCHISE",
                    label: "FRANCHISE",
                  },
                  {
                    value: "BROKERAGE",
                    label: "BROKERAGE",
                  },
                  {
                    value: "OFFICE",
                    label: "OFFICE",
                  },
              ]}
            />
            </div>
      <Space>
        <Button
          type="primary"
          onClick={() => {handleFilterChange(filterbyOffice);setdisabledBtn(false)}}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Filter
        </Button>
        <Button
          onClick={() => {handleReset(clearFilters, setSelectedKeys);setdisabledBtn(true)}}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <FilterFilled style={{ color: filtered ? "#1890FF" : undefined }} />
  ),
  render: (text) =>
    searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: "#FFC069", padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    ) : (
      text
    ),
});
const [isVerify,setisVerify]=useState("")
let getstatus = (dataIndex) => ({

  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <div style={{marginBottom:"10px"}}>
      <Select
              style={{ width: "100%" }}
              placeholder="Select Status"
              onChange={(role, event) => {
                setisVerify(role)
              }}
              options={[
                  {
                    value: "true",
                    label: "Verified",
                  },
                  {
                    value: "false",
                    label: "Not Verified",
                  },
              ]}
            />
            </div>
      <Space>
        <Button
          type="primary"
          onClick={() => {handleFilterChangestatus(isVerify);setdisabledBtn(false)}}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Filter
        </Button>
        <Button
          onClick={() => {handleReset(clearFilters, setSelectedKeys);setdisabledBtn(true)}}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <FilterFilled style={{ color: filtered ? "#1890FF" : undefined }} />
  ),

  render: (text) =>
    searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: "#FFC069", padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    ) : (
      (
        <div>
          {text ? (
            <div
              style={{ display: "flex", gap: "5px", alignItems: "center" }}
            >
              <CheckCircleOutlined
                style={{ color: "#4bd33a", fontSize: "18px" }}
              />
              Verified
            </div>
          ) : (
            <div
              style={{ display: "flex", gap: "5px", alignItems: "center" }}
            >
              <CloseCircleOutlined
                style={{ color: "#e73939", fontSize: "18px" }}
              />
              Not Verified
            </div>
          )}
        </div>
      )
    ),
});
const handleFilterChangestatus=(value)=>{
  let query = {
    ...currentQuery,
    isVerified:value
  };
  dispatch(officeTypeHandle.getOfficeTypes("verifyCreatedFirm",query));
  setCurrentQuery(query)
  setCurrentPage(1);
}
const handleFilterChange=(value)=>{
  let query = {
     ...currentQuery,
     firmType:value
   };

   dispatch(officeTypeHandle.getOfficeTypes("verifyCreatedFirm",query));
   setCurrentQuery(query)

   setCurrentPage(1);
 }
  //searching functionality
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    searchRef.current = true;
    confirm();
    setsearchText(selectedKeys[0]);
    let query = {}
    if (dataIndex == "creator") {
      query = {
        ...currentQuery,
        nameEmailSearch: selectedKeys[0],
        page: 1,
      };
    }
    else if (dataIndex === 'franchise') {
      query.officeTypes = dataIndex.toUpperCase();
      query.franchiseSearch = selectedKeys[0];
    } else if (dataIndex === 'brokerage') {
      query.officeTypes = dataIndex.toUpperCase();
      query.brokerageSearch = selectedKeys[0]
    } else if (dataIndex === 'office') {
      query.officeTypes = dataIndex.toUpperCase();
      query.officeSearch = selectedKeys[0]
    } else {
      query = {
        // officeTypes:dataIndex.toUpperCase(),
        ...currentQuery,
        search: selectedKeys[0],
        page: 1,
      };
    }
    dispatch(officeTypeHandle.getOfficeTypes("verifyCreatedFirm", query));
    setsearchedColumn(dataIndex);
    setCurrentQuery(query);
    setCurrentPage(1);

  };
  const handleReset = (clearFilters, setSelectedKeys) => {
    clearFilters();
    setSelectedKeys("");
    setCurrentQuery({})
    let query = {
      page: 1,
    };
    dispatch(officeTypeHandle.getOfficeTypes("verifyCreatedFirm",query));
    setsearchText("");
    searchRef.current = false;
  };
  const handleResetfilters = () => {
    setCurrentQuery({})
    let query = {
      page: 1,
    };
    dispatch(officeTypeHandle.getOfficeTypes("verifyCreatedFirm",query));
    setsearchText("");
    searchRef.current = false;
  };
  let getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput.current = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => {handleSearch(selectedKeys, confirm, dataIndex);setdisabledBtn(false)}}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {handleSearch(selectedKeys, confirm, dataIndex);setdisabledBtn(false)}}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {handleReset(clearFilters, setSelectedKeys);setdisabledBtn(true)}}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>

        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),

    render: (text, allData) =>

      searchedColumn === dataIndex ? (
       <>

       {dataIndex == "franchise"?<div><Highlighter
       highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
       searchWords={[searchText]}
       autoEscape
       textToHighlight={text ? text.name : ""}
       />
       <div style={{ fontSize: "11px", color: "#787474" }}>
       <Highlighter
       highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
       searchWords={[searchText]}
       autoEscape
       textToHighlight={text ? text.address: ""}
        /></div></div>
     :dataIndex == "brokerage"?<div><Highlighter
       highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
       searchWords={[searchText]}
       autoEscape
       textToHighlight={text ? text.name : ""}
       />
       <div style={{ fontSize: "11px", color: "#787474" }}>
       <Highlighter
       highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
       searchWords={[searchText]}
       autoEscape
       textToHighlight={allData ? `License No: ${allData.licenseNumber}`: ""}
        />
        </div>
        <div style={{ fontSize: "11px", color: "#787474" }}>
       <Highlighter
       highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
       searchWords={[searchText]}
       autoEscape
       textToHighlight={text ? text.address: ""}
        />
        </div>
        </div>
     :dataIndex == "office"?<div><Highlighter
       highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
       searchWords={[searchText]}
       autoEscape
       textToHighlight={text ? text.name : ""}
       />
       <div style={{ fontSize: "11px", color: "#787474" }}>
       <Highlighter
       highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
       searchWords={[searchText]}
       autoEscape
       textToHighlight={text ? text.address: ""}
        /></div></div>
     :dataIndex == "creator"?<div><Highlighter
       highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
       searchWords={[searchText]}
       autoEscape
       textToHighlight={text ? text.fullName : ""}
       />
       <div style={{ fontSize: "11px", color: "#787474" }}>
       <Highlighter
       highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
       searchWords={[searchText]}
       autoEscape
       textToHighlight={text ? text.email: ""}
        /></div></div>
     :
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.Address.toString() : ""}
        />
       }
       </>
      ) : (<>
        {dataIndex =="franchise"?<div>
              <div style={{display:"flex",alignItems:"center", gap:"5px"}}>
                {text?.officeLogo ? <Image src={text.officeLogo} alt="" width={"30px"}/> : ""}
                {text.name ? text.name : "-"}
              </div>
              <div style={{ fontSize: "11px", color: "#787474" }}>
                {text.address ? `Address: ${text.address}` : ""}
              </div>
            </div>:dataIndex =="brokerage"?
            <div>
              <div style={{display:"flex",alignItems:"center", gap:"5px"}}>
                {text.name && text?.officeLogo ? <Image src={text.officeLogo} alt="" width={"30px"}/> : ""}
                {text.name ? text.name : "-"}
              </div>
              <div style={{ fontSize: "11px", color: "#787474" }}>
                {allData?.licenseNumber ? `License No: ${allData?.licenseNumber}` : ""}
              </div>
              <div style={{ fontSize: "11px", color: "#787474" }}>
                {text.address ? `Address: ${text.address}` : ""}
              </div>
            </div>:
            dataIndex =="office"?<div>
              <div>{text.name ? text.name : "-"}</div>
              <div style={{ fontSize: "11px", color: "#787474" }}>
                {text.address ? `Address: ${text.address}` : ""}
              </div>
            </div>:dataIndex =="creator"?
            <div>
             <div>{text.fullName ? text.fullName : "-"}</div>
             <div style={{ fontSize: "11px", color: "#787474" }}>
             {text.email ? `${text.email}` : ""}
              </div>
            </div>:
            text}
        </>
      ),
  });
  return (
    <>
   { officeLoading ? (
   <div
      style={{
        display: "flex",
        height: "70vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin />
    </div>):(
      <div style={{ height: "calc(100vh - 300px)" }}>
         <div style={{position:"absolute",top:"98px",zIndex:"111",left :'350px'}}>
          <Button disabled={disabledBtn} onClick={()=>{handleResetfilters();setdisabledBtn(true)}} size="" style={{marginLeft:"10px"}}>Reset Filters</Button>
          </div>
      <Table
    scroll={{ y: "60vh" }}
      columns={[
        {
          title: "FIRM CREATED",
          dataIndex: "name",
          key: "name",
          width:"10%",
          ...getoffice()
      //    filters: [

      //   { text: "FRANCHISE", value: "FRANCHISE" },
      //   { text: "BROKERAGE", value: "BROKERAGE" },
      //   { text: "OFFICE", value: "OFFICE" },
      // ],
      // onFilter: (value, record) =>record.name === value,
      // key: "x",
      // render: (_, record) => (
      //   <text>{record.name}</text>
      // ),
        },
        {
          title: "CREATED BY",
          dataIndex: "creator",
          key: "creator",
          width: "20%",
          ...getColumnSearchProps("creator"),
          // render: (text) => (
          //   <div>
          //     <div>{text.fullName ? text.fullName : "-"}</div>
          //     <div style={{ fontSize: "11px", color: "#787474" }}>
          //       {text.email ? `${text.email}` : ""}
          //     </div>
          //   </div>
          // ),
        },
        {
          title: "STATUS",
          dataIndex: "status",
          key: "status",
          width: "10%",
          ...getstatus("isVerified"),
          // render: (isVerified) => (
          //   <div>
          //     {isVerified ? (
          //       <div
          //         style={{ display: "flex", gap: "5px", alignItems: "center" }}
          //       >
          //         <CheckCircleOutlined
          //           style={{ color: "#4bd33a", fontSize: "18px" }}
          //         />
          //         Verified
          //       </div>
          //     ) : (
          //       <div
          //         style={{ display: "flex", gap: "5px", alignItems: "center" }}
          //       >
          //         <CloseCircleOutlined
          //           style={{ color: "#e73939", fontSize: "18px" }}
          //         />
          //         Not Verified
          //       </div>
          //     )}
          //   </div>
          // ),
        },
        {
          title: "FRANCHISE",
          dataIndex: "franchise",
          key: "franchise",
          ...getColumnSearchProps("franchise"),
          width: "17%",
          // render: (text) => (
          //   <div>
          //     <div>{text.name ? text.name : "-"}</div>
          //     <div style={{ fontSize: "11px", color: "#787474" }}>
          //       {text.address ? `Address: ${text.address}` : ""}
          //     </div>
          //   </div>
          // ),
        },
        {
          title: "BROKERAGE",
          dataIndex: "brokerage",
          key: "brokerage",
          ...getColumnSearchProps("brokerage"),
          width: "25%",
          // render: (text) => (
          //   <div>
          //     <div>{text.name ? text.name : "-"}</div>
          //     <div style={{ fontSize: "11px", color: "#787474" }}>
          //       {text.address ? `Address: ${text.address}` : ""}
          //     </div>
          //   </div>
          // ),
        },
        {
          title: "OFFICE",
          dataIndex: "office",
          key: "office",
          ...getColumnSearchProps("office"),
          width: "17%",
          // render: (text) => (
          //   <div>
          //     <div>{text.name ? text.name : "-"}</div>
          //     <div style={{ fontSize: "11px", color: "#787474" }}>
          //       {text.address ? `Address: ${text.address}` : ""}
          //     </div>
          //   </div>
          // ),
        },
        {
          title: "UPDATE",
          dataIndex: "update",
          key: "update",
          width:"9%",
          render: (isVerified, ele) => (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {isVerified ? (
                <CheckCircleOutlined
                  style={{ color: "#4bd33a", fontSize: "18px" }}
                />
              ) : (
                <button
                  style={{ cursor: "pointer" }}
                  onClick={() => handleOfficeUpdate(ele.id, true)}
                >
                  Mark Verified
                </button>
              )}
            </div>
          ),
        },
      ]}
      dataSource={officeData}
      bordered
      pagination={{
        position: ["bottomCenter"],
        showSizeChanger: false,
        pageSize: officeData?.length,
        current: currentPage,
        total:totalRecords,
        onChange: (page) => {
          if (!searchRef.current) {

            dispatch(officeTypeHandle.getOfficeTypes("verifyCreatedFirm",{
                page: page,
              }));
         }
              setCurrentPage(page);
            },
       }}
    />
</div>
)}
    </>
  );
}

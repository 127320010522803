import React, { useState } from "react";
import { Form, Input, Row, Col, Button } from "antd";
import { Loading } from "../../../Common/components/Loading";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const Step3 = (props) => {
  const [loader, setLoader] = useState(false);

  return (
    <>
      <Form
        {...layout}
        className="formaccnt"
        preserve={true}
        style={{ minHeight: "405px" }}
        onFinish={() => {
          setLoader(true);
          if (props.toggle) {
            props?.importPDFApiCall(true);
          } else {
            props?.importUrlApiCall(props?.selectedData?._id, true);
          }
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Button
            style={{
              border: "none",
              fontSize: "18px",
              fontWeight: "bold",
              color: "#8C8C8C",
              marginTop: "2%",
            }}
          >
            Property
          </Button>
        </div>

        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "15px",
          }}
        >
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Input value={`${props?.CurrentPdfImportData["FullAddress"]}`} />
          </Col>
        </Row>

        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          {loader ? (
            <Loading />
          ) : (
            <Button type="primary" htmlType="submit">
              {props.toggle ? "Offer Import" : "Clone offer"}
            </Button>
          )}
        </Row>
      </Form>
    </>
  );
};

export default Step3;

/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Dropdown,
  Input,
  Modal,
  Space,
  Table,
  Tooltip,
  message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  SearchOutlined,
  MoreOutlined,
  CopyOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { Loading } from "../Common";
import { subscriptionAction } from "./state/action";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import { officeTypeHandle } from "../OfficeType/state/actions";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { MenuItem } from "./components/MenuItem";
import "./styles/subscription.css";
import { roleAccessFunc } from "../Common/commondata/commonData";
const { confirm } = Modal;

const SubscriptionVerication = () => {
  const {
    getSubscriptionUsersLoading,
    getSubscriptionUsersData,
    totalRecords,
  } = useSelector((state) => state.subscription);
  const { adminRole, roleAccessed, getUserDetailsData } = useSelector(
    (state) => state.account,
    shallowEqual
  );

  const dispatch = useDispatch();
  const [searchText, setsearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentQuery, setCurrentQuery] = useState({});
  const [searchedColumn, setsearchedColumn] = useState("");
  const searchInput = useRef();
  const [copyid, setCopyid] = useState("Copy code");
  const [copyicon, setcopyicon] = useState(true);
  const [planDetailsModalVisible, setPlanDetailsModalVisible] = useState(false);
  let currentPlan = getUserDetailsData?.subscriptionData?.activePlan;
  let endDate = getUserDetailsData?.subscriptionData?.endDate;
  let subscriptionCode = getUserDetailsData?.subscriptionData?.subscriptionCode;
  let purchaseQuantity = getUserDetailsData?.subscriptionData?.quantity;
  let remainingQuantity = getUserDetailsData?.subscriptionData?.redemptionCount;
  useEffect(() => {
    if(roleAccessFunc(roleAccessed, "SUBSCRIPTION_USERS")){
      let query = {
        ...currentQuery,
        page: currentPage,
        limit: 30,
      };
      dispatch(subscriptionAction.getAllPersonsforSubscription(query));
    }
  }, [currentPage]);

  const handleApprovePlan = (ele) => {
    confirm({
      title: `Are you sure you want to give ${ele.fullName} to ${ele.requestedPlan} Plan of RE-Sure?`,
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      content: "Note: Changes will be live once Verified",
      onOk() {
        let query = {
          ...currentQuery,
          page: currentPage,
          limit: 30,
        };
        dispatch(
          officeTypeHandle.updatePerson(
            {
              personId: ele.personId,
              isSubscriptionVerified: true,
            },
            "SUBSCRIPTION",
            query
          )
        );
      },
      onCancel() {},
    });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setsearchText(selectedKeys[0]);
    let query = {
      search: selectedKeys[0],
      page: 1,
      limit: 30,
    };
    dispatch(subscriptionAction.getAllPersonsforSubscription(query));
    setsearchedColumn(dataIndex);
    setCurrentQuery(query);
    setCurrentPage(1);
  };
  const handleReset = (clearFilters, setSelectedKeys) => {
    clearFilters();
    setSelectedKeys("");
    let query = {
      page: 1,
      limit: 30,
    };
    dispatch(
      subscriptionAction.getAllPersonsforSubscription(query, "Subscription")
    );
    setsearchText("");
  };
  if (!roleAccessFunc(roleAccessed, "SUBSCRIPTION_USERS")) {
    return <Redirect to="/error" />;
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput.current = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, setSelectedKeys)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{ fontSize: "20px", color: filtered ? "#1890ff" : undefined }}
      />
    ),

    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const handlePlanDetailsModalCancel = () => {
    setPlanDetailsModalVisible(false);
  };

  const handleCopy = async () => {
    try {
      setCopyid("Copied");
      setcopyicon(false);
      await navigator.clipboard.writeText(subscriptionCode);
      message.success("Subscription code copied to clipboard");
      setTimeout(() => {
        setCopyid("Copy code");
        setcopyicon(true);
      }, 5000);
    } catch (error) {
      message.error("Failed to copy subscription code");
    }
  };
  return getSubscriptionUsersLoading ? (
    <Loading />
  ) : (
    <>
      <Modal
        title={
          <div style={{ textAlign: "center", fontSize: "18px" }}>
            Plan Details
          </div>
        }
        onOk={handlePlanDetailsModalCancel}
        onCancel={handlePlanDetailsModalCancel}
        visible={planDetailsModalVisible}
        width={600}
        footer={null}
      >
        <div
          style={{
            width: "100%",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            backgroundColor: "#8d958036",
            borderRadius: "20px",
            padding: "20px 60px 20px 40px",
          }}
        >
          <div className="planDetailsModalDiv">
            <p>Active Plan:</p>
            <p>{currentPlan} </p>
          </div>
          <div className="planDetailsModalDiv">
            <p>Start Date: </p>
            <p> {moment.unix(endDate).format("MMMM D, YYYY hh:mm A")}</p>{" "}
          </div>
          <div className="planDetailsModalDiv">
            <p>End Date: </p>
            <p> {moment.unix(endDate).format("MMMM D, YYYY hh:mm A")}</p>
          </div>
          <div className="planDetailsModalDiv">
            <p>Quantity Purchased:</p>
            <p> {purchaseQuantity}</p>{" "}
          </div>
          <div className="planDetailsModalDiv">
            <p>Quantity Used:</p>

            <p>{remainingQuantity}</p>
          </div>
          {purchaseQuantity>1 ? (
            <div className="planDetailsModalDiv">
              <p>Subscription Code: </p>
              <Tooltip title={copyid} placement="right">
                <p
                  onClick={handleCopy}
                  style={{
                    cursor: "pointer",
                    backgroundColor: "white",
                    padding: "5px 10px",
                    borderRadius: "10px",
                    fontWeight: "bold",
                  }}
                >
                  {subscriptionCode}{" "}
                  {copyicon ? (
                    <CopyOutlined
                      type="copy"
                      style={{
                        fontWeight: "bold",
                        marginLeft: "5px",
                      }}
                    />
                  ) : (
                    <SnippetsOutlined
                      style={{
                        color: "hsl(209deg 72% 51%)",
                        fontWeight: "bold",
                        marginLeft: "5px",
                      }}
                    />
                  )}
                </p>
              </Tooltip>
            </div>
          ) : null}
        </div>
      </Modal>
      <div
        style={{
          display: "flex",
          marginBottom: "20Px",
          marginTop: "10px",
          justifyContent: "space-between",
        }}
      >
        <div></div>
        <div
          style={{ fontWeight: "bold", fontSize: "20px", textAlign: "center" }}
        >
          Manage Subscription Users
        </div>

        <div
          style={{ fontWeight: "bold", fontSize: "20px", marginRight: "20px" }}
        >
          <Dropdown
            trigger={["click", "hover"]}
            overlay={
              <MenuItem
                setPlanDetailsModalVisible={setPlanDetailsModalVisible}
              />
            }
          >
            <MoreOutlined
              key="ellipsis"
              style={{
                fontSize: "26px",
                color: "#08c",
                marginTop: "10px",
              }}
            />
          </Dropdown>
        </div>
      </div>

      <Table
        columns={[
          {
            title: "Full Name",
            dataIndex: "fullName",
            key: "fullName",
            ...getColumnSearchProps("fullName"),
            width: "15%",
          },
          {
            title: "Email",
            dataIndex: "email",
            key: "email",
            ...getColumnSearchProps("email"),
            width: "15%",
            align: "center",
          },
          {
            title: "Plan",
            dataIndex: "requestedPlan",
            key: "requestedPlan",
            width: "10%",
          },
          {
            title: "Start Date",
            dataIndex: "startDate",
            key: "startDate",
            render: (event, i) => (
              <Text>
                {moment(event?.startDate).format("MMMM D, YYYY hh:mm A")}
              </Text>
            ),
            width: "15%",
          },
          {
            title: "End Date",
            dataIndex: "endDate",
            key: "endDate",
            render: (event, i) => (
              <Text>
                {moment(event?.endDate).format("MMMM D, YYYY hh:mm A")}
              </Text>
            ),
            width: "15%",
          },
          {
            title: "Code Applied",
            dataIndex: "subscriptionCode",
            key: "subscriptionCode",
            width: "15%",
          },
          {
            title: "UPDATE",
            dataIndex: "isVerified",
            key: "isVerified",
            render: (isVerified, ele) => (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {isVerified ? (
                  <CheckCircleOutlined
                    style={{ color: "#4bd33a", fontSize: "18px" }}
                  />
                ) : (
                  <button
                    style={{ cursor: "pointer" }}
                    onClick={() => handleApprovePlan(ele)}
                  >
                    Give Access
                  </button>
                )}
              </div>
            ),
          },
        ]}
        dataSource={getSubscriptionUsersData}
        bordered
        pagination={{
          position: ["bottomCenter"],
          showSizeChanger: false,
          pageSize: 30,
          current: currentPage,
          total: totalRecords,
          onChange: (page) => {
            setCurrentPage(page);
          },
        }}
      />
    </>
  );
};

export default SubscriptionVerication;

import { Input, List, Pagination, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { logsDataHandle } from "../state/actions";
import moment from "moment";
import { Loading } from "../../Common";
import { SearchOutlined, FilterFilled } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import "../styles/logs.css";
import _ from "lodash";

function BrokerCreatedLogs() {
  const location = useLocation();
  const dispatch = useDispatch();
  const searchRef = useRef(false);
  const [query, setQuery] = useState({
    logType:
      location.pathname === "/buyerAgentCreatedLogs"
        ? "BUYER_AGENT_CREATED_LOGS"
        : location.pathname === "/sellerAgentCreatedLogs"
          ? "SELLER_AGENT_CREATED_LOGS"
          : "",
  });
  const { getLogsData, getLogsLoading, totalRecords } = useSelector(
    (state) => state.getLogsData,
  );
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    let queryData = {};
    if (location.pathname === "/buyerAgentCreatedLogs") {
      queryData = {
        logType: "BUYER_AGENT_CREATED_LOGS",
        page: 1,
      };
    } else if (location.pathname === "/sellerAgentCreatedLogs") {
      queryData = {
        logType: "SELLER_AGENT_CREATED_LOGS",
        page: 1,
      };
    }
    dispatch(logsDataHandle.getAgentCreatedLogsData(queryData));
    setCurrentPage(1);
    setQuery(queryData);
  }, [location.pathname]);

  const debouncedHandleSearch = _.debounce((queryData) => {
    dispatch(logsDataHandle.getAgentCreatedLogsData(queryData));
  }, 1000);

  const handleSearch = (value) => {
    let queryData = {};
    if(value){
        queryData.search = value;
      }else{
        queryData.page = currentPage;
      }
    if (location.pathname === "/buyerAgentCreatedLogs") {
      queryData.logType = "BUYER_AGENT_CREATED_LOGS";
    } else if (location.pathname === "/sellerAgentCreatedLogs") {
      queryData.logType = "SELLER_AGENT_CREATED_LOGS";
    }
    debouncedHandleSearch(queryData);
  };

  return (
    <div id="logs">
        <div style={{ background: "white" }}>
           <div style={{display:"flex",justifyContent:"center",fontSize:"large"}}>
                <h1>{location.pathname === "/sellerAgentCreatedLogs" ? "SELLER AGENT CREATED LOGS":"BUYER AGENT CREATED LOGS"}</h1>
            </div>
          <div style={{ width: "50%", margin: "auto", padding: "5px" }}>
            <Input
              allowClear
              suffix={<SearchOutlined />}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
          {getLogsLoading ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Loading />
        </div>
      ) : (
      <div>
          <List
            loading={getLogsLoading}
            bordered
            dataSource={getLogsData}
            renderItem={(item, index) => (
              <List.Item key={index}>
                {/* Render item content here */}
                <div style={{width:"100%",display:"flex",fontSize:"15px",fontWeight:"bold"}}>
                    <div style={{ width: "85%" }}>
                      <Typography.Text style={{ fontFamily: "poppins" }}>
                        {/* <span>
                          {" "}
                          Using the{" "}
                          {item?.platformUsed === "WEB"
                            ? "Webapp"
                            : "Mobileapp"}{" "}
                          platform,{" "}
                        </span> */}

                        <span>
                          {location.pathname === "/sellerAgentCreatedLogs"
                            ? "Seller"
                            : "Buyer"}{" "}
                          agent {item?.sellerAgentData?.fullName}{" "}
                        </span>
                        {item?.sellerAgentData?.email ? (
                          <span> ({item?.sellerAgentData?.email}) </span>
                        ) : (
                          ""
                        )}
                        <span>
                            <span> created on property name</span>
                            <span> {location.pathname === "/sellerAgentCreatedLogs"
                                ? item?.sellerAgentCreatedOnPropertyData?.fullAddress
                                : item?.buyerAgentCreatedOnOfferIdToPropertyData?.fullAddress}
                            </span>
                        </span>
                        <span>
                          {" "}
                          by {item?.createdByData?.fullName}{" "}
                        </span>
                        {item?.createdByData?.email ? (
                          <span> ({item?.createdByData?.email}) </span>
                        ) : (
                          ""
                        )}
                        <span></span>
                      </Typography.Text>
                    </div>

                <div style={{ width: "15%",marginLeft:"20px" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "100px",
                        backgroundColor: "green",
                      }}
                    ></div>
                    {"Created"}
                  </div>
                  <div>
                    {item?.createdAt
                      ? moment(item?.createdAt).format("MMMM D, YYYY")
                      : ""}
                  </div>
                </div>
                </div>
              </List.Item>
            )}
          />
          <Pagination
            style={{ textAlign: "center", padding: "10px" }}
            current={currentPage}
            total={totalRecords}
            showSizeChanger={false}
            pageSize={5}
            onChange={(page) => {
              if (!searchRef.current) {
                searchRef.current = true;
                return;
              }
              dispatch(
                logsDataHandle.getAgentCreatedLogsData({
                  ...query,
                  logType:
                    location.pathname === "/buyerAgentCreatedLogs"
                      ? "BUYER_AGENT_CREATED_LOGS"
                      : "SELLER_AGENT_CREATED_LOGS",
                  page: page,
                }),
              );
              setQuery({
                ...query,
                logType:
                  location.pathname === "/buyerAgentCreatedLogs"
                    ? "BUYER_AGENT_CREATED_LOGS"
                    : "SELLER_AGENT_CREATED_LOGS",
                page: page,
              });
              setCurrentPage(page);
            }}
          />
      </div>)}
        </div>
    </div>
  );
}

export default BrokerCreatedLogs;

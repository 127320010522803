export const adminConstants = {
  AUTHENTICATION_FAILURE: "AUTHENTICATION_FAILURE",
  AUTHENTICATION_SUCCESS: "AUTHENTICATION_SUCCESS",

  LOGOUT_ACTION: "LOGOUT_ACTION",

  RESET_ERROR: "RESET_ERROR",

  RESET_LOGOUT: "RESET_LOGOUT",

  SIGNUP_USER_LOADING: "SIGNUP_USER_LOADING",
  SIGNUP_USER_ERROR: "SIGNUP_USER_ERROR",
  SIGNUP_USER_SUCCESS: "SIGNUP_USER_SUCCESS",

  USER_FORGET_PASSWORD_LOADING: "USER_FORGET_PASSWORD_LOADING",
  USER_FORGET_PASSWORD_FAILURE: "USER_FORGET_PASSWORD_FAILURE",
  USER_FORGET_PASSWORD_SUCCESS: "USER_FORGET_PASSWORD_SUCCESS",
  USER_FORGET_PASSWORD_CLEAR: "USER_FORGET_PASSWORD_CLEAR",

  FORGET_PASSWORD_VERIFICATION_LOADING: "FORGET_PASSWORD_VERIFICATION_LOADING",
  FORGET_PASSWORD_VERIFICATION_FAILURE: "FORGET_PASSWORD_VERIFICATION_FAILURE",
  FORGET_PASSWORD_VERIFICATION_SUCCESS: "FORGET_PASSWORD_VERIFICATION_SUCCESS",

  RESET_PASSWORD_SAVE_NEW_PASSWORD_LOADING:
    "RESET_PASSWORD_SAVE_NEW_PASSWORD_LOADING",
  RESET_PASSWORD_SAVE_NEW_PASSWORD_FAILURE:
    "RESET_PASSWORD_SAVE_NEW_PASSWORD_FAILURE",
  RESET_PASSWORD_SAVE_NEW_PASSWORD_SUCCESS:
    "RESET_PASSWORD_SAVE_NEW_PASSWORD_SUCCESS",

  RESET_ALERT: "RESET_ALERT",

  RESET_PASSWORD: "RESET_PASSWORD",

  MANIPULATE_BROKERAGE_SELECT_MODAL: 'MANIPULATE_BROKERAGE_SELECT_MODAL',
  
};

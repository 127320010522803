import axios from "axios";
import dotEnv from "dotenv";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;
// Creating a TitleCompany
const createTitleCompanyRequest = (payload) => {
  let accessTokan = localStorage.getItem("auth");
  return axios.post(`${apiURL}/titleOffice`, payload, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

// Get all titleCompany created by a person
const gettitleCompanyRequest = (source) => {
  let accessTokan = localStorage.getItem("auth");
  if (source === "createTitleCompany") {
    return axios.get(
    `${apiURL}/titleOffice?isFromAdmin=${true}&createdOnly=${true}`,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
    }else if(source === "verifyTitleCompany"){
      return axios.get(`${apiURL}/titleOffice?isFromAdmin=${true}&verify=${true}`,{
       headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          accept: "application/json",
          authorization: `bearer ${accessTokan}`,
        },
      });
    }
};
const updateClosuresSalesRepRequest = (payload) => {
  let accessTokan = localStorage.getItem("auth");
  return axios.put(`${apiURL}/updateClosuresSalesRepData`, payload, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};
export const TitleCompanyApi = {
    createTitleCompanyRequest,
    gettitleCompanyRequest,
    updateClosuresSalesRepRequest
  };
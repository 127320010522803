import { act } from "@testing-library/react";
import { adminConstants } from "./action-types";

const initialState = {
  auth: null,
  authError: null,
  authLoading: true,
  username: null,
  logOutRedirect: false,
  currentUser: null,

  Signup_Loading: false,
  Signup_Error: null,
  Signup_Success: null,

  userForgetPasswordData: null,
  userForgetPasswordDataEmail: null,
  userForgetPasswordLoading: false,
  userForgetPasswordError: null,

  verificationData: null,
  verificationLoading: false,
  verificationError: null,

  resetPasswordNewPasswordData: null,
  resetPasswordNewPasswordLoading: false,
  resetPasswordNewPasswordError: false,
};

const authenticate = (state = initialState, action) => {
  switch (action.type) {
    case adminConstants.AUTHENTICATION_SUCCESS:
      return {
        ...state,
        authLoading: action.authLoading,
        auth: action.auth,
        username: action.username,
      };
    case adminConstants.AUTHENTICATION_FAILURE:
      return {
        ...state,
        authError: action.authError,
        authLoading: action.authLoading,
      };
    case adminConstants.LOGOUT_ACTION:
      return {
        ...state,
        authLoading: action.authLoading,
        username: action.username,
        auth: action.auth,
        logOutRedirect: true,
      };
    case adminConstants.RESET_ERROR:
      return {
        ...initialState,
      };
    case adminConstants.RESET_LOGOUT:
      return {
        ...state,
        logOutRedirect: false,
      };
    case adminConstants.SIGNUP_USER_LOADING:
      return {
        ...state,
        Signup_Loading: action.Signup_Loading,
        Signup_Error: action.Signup_Error,
      };

    case adminConstants.SIGNUP_USER_ERROR:
      return {
        ...state,
        Signup_Loading: false,
        Signup_Error: action.Signup_Error,
      };

    case adminConstants.SIGNUP_USER_SUCCESS:
      return {
        ...state,
        Signup_Success: action.Signup_Success,
        Signup_Loading: action.Signup_Loading,
        Signup_Error: action.Signup_Error,
      };

    // ForgetPassword request
    case adminConstants.USER_FORGET_PASSWORD_LOADING:
      return {
        ...state,
        userForgetPasswordData: action.userForgetPasswordData,
        userForgetPasswordDataEmail: action.userForgetPasswordDataEmail,
        userForgetPasswordLoading: true,
        alert: "",
      };
    case adminConstants.USER_FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        userForgetPasswordData: action.userForgetPasswordData,
        userForgetPasswordDataEmail: action.userForgetPasswordDataEmail,
        userForgetPasswordLoading: false,
        alert: "",
      };
    case adminConstants.USER_FORGET_PASSWORD_CLEAR:
      return {
        ...state,
        userForgetPasswordData: action.userForgetPasswordData,
        userForgetPasswordDataEmail: action.userForgetPasswordDataEmail,
        userForgetPasswordLoading: false,
        alert: "",
      };
    case adminConstants.USER_FORGET_PASSWORD_FAILURE:
      return {
        ...state,
        userForgetPasswordError: action.userForgetPasswordError,
        userForgetPasswordLoading: false,
        alert: action.userForgetPasswordError.response.data.message,
      };

    // ForgetPassword Verification request
    case adminConstants.FORGET_PASSWORD_VERIFICATION_LOADING:
      return {
        ...state,
        verificationLoading: true,
        verificationError: false,
      };
    case adminConstants.FORGET_PASSWORD_VERIFICATION_SUCCESS:
      return {
        ...state,
        verificationData: action.verificationData,
        verificationLoading: false,
      };
    case adminConstants.FORGET_PASSWORD_VERIFICATION_FAILURE:
      return {
        ...state,
        verificationError: action.verificationError,
        verificationLoading: false,
        alert: action.verificationError.response.data.message,
      };

    // Reset password save new password request
    case adminConstants.RESET_PASSWORD_SAVE_NEW_PASSWORD_LOADING:
      return {
        ...state,
        resetPasswordNewPasswordLoading: true,
        resetPasswordNewPasswordError: false,
      };
    case adminConstants.RESET_PASSWORD_SAVE_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordNewPasswordData: action.resetPasswordNewPasswordData,
        resetPasswordNewPasswordLoading: false,
        resetPasswordNewPasswordError: false,
      };
    case adminConstants.RESET_PASSWORD_SAVE_NEW_PASSWORD_FAILURE:
      return {
        ...state,
        resetPasswordNewPasswordError: action.resetPasswordNewPasswordError,
        resetPasswordNewPasswordLoading: false,
      };
    case adminConstants.RESET_PASSWORD:
      return {
        ...state,
        resetPasswordNewPasswordData: null,
        resetPasswordNewPasswordLoading: false,
        resetPasswordNewPasswordError: null,
        verificationData: null,
        verificationLoading: false,
        verificationError: null,
        userForgetPasswordData: null,
        userForgetPasswordDataEmail: null,
        userForgetPasswordLoading: false,
        userForgetPasswordError: null,
      };
    case adminConstants.RESET_ALERT:
      return {
        ...state,
        alert: "",
      };
    case adminConstants.MANIPULATE_BROKERAGE_SELECT_MODAL:
      return {
        ...state,
        setBrokerageModal: action.setBrokerageModal
      }
    default:
      return state;
  }
};
export { authenticate };

import { message } from "antd";
import { accountApi } from "../utils/api";
import { accountCreationConstants } from "./action-types";
import { officeTypeApi } from "../../OfficeType/utils/api";
import { cmsDataHandle } from "../../CMS/state/actions";

const realterSignup = (data, source) => {
  return (dispatch) => {
    accountApi
      .signupRealtorRequest(data)
      .then((response) => {
        if (source === "SUPER_ADMIN_USER") {
          dispatch(cmsDataHandle.getPersonsData());
        }
        // else {
        //   dispatch(getAllPersonsByProfileCreated());
        // }
        message.success(
          "Account created successfully!"
        );
      })
      .catch((error) => {
        message.error(error.response.data.message);
      });
  };
};

const getLoading = () => {
  return {
    type: accountCreationConstants.CREATED_PERSON_LOADING,
    createdPersonsLoading: true,
    createdPersonsError: false,
  };
};

const getError = () => {
  return {
    type: accountCreationConstants.CREATED_PERSON_ERROR,
    createdPersonsLoading: false,
    createdPersonsError: true,
  };
};

const getData = (response) => {
return {
    type: accountCreationConstants.CREATED_PERSON_DATA,
    createdPersonsData: response.data.info.data,
    totalRecords: response.data.info.totalRecords
  };
};

const getAllPersonsByProfileCreated = (query) => {
  return (dispatch, getState) => {
    dispatch(getLoading());
    accountApi
      .getAllPersonsApi(query)
      .then((response) => {
        dispatch(getData(response));
      })
      .catch((error) => {
     dispatch(getError());
      });
  };
};

const updatePersonCreated = (payload) => {
  return (dispatch, getState) => {
    officeTypeApi
      .updatePerson(payload)
      .then((response) => {
        message.success("Updated Successfully");
        dispatch(getAllPersonsByProfileCreated());
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || "There is an error while updating person data!");
      });
  };
};
// Get User details
const getUserDetailsLoading = () => {
  return {
    type: accountCreationConstants.GET_USER_DETAILS_LOADING,
  };
};
const getUserDetailsError = (error) => {
  return {
    type: accountCreationConstants.GET_USER_DETAILS_FAILURE,
    getUserDetailsError: error,
  };
};

const getUserDetailsSuccess = (userData) => {
  return {
    type: accountCreationConstants.GET_USER_DETAILS_SUCCESS,
    getUserDetailsData: userData,
    userID: userData._id,
    adminName: userData.fullName,
    adminRole: userData.adminRole,
    roleAccessed: userData.roleAccessed,
  };
};

const getUserDetails = ({token}) => {
  return (dispatch) => {
    dispatch(getUserDetailsLoading());
    accountApi
      .getUserDetailsRequest({token})
      .then((response) => {
        dispatch(getUserDetailsSuccess(response.data.info));
      })
      .catch((error) => {
        dispatch(getUserDetailsError(error));
      });
  };
};

// Get User details
const getDelegateListLoading = () => {
    return {
      type: accountCreationConstants.GET_DELEGATE_LIST_LOADING,
    };
  };
  const getDelegateListError = (error) => {
    return {
      type: accountCreationConstants.GET_DELEGATE_LIST_ERROR,
      getDelegateListError: error,
    };
  };

  const getDelegateListSuccess = (Data) => {
    return {
      type: accountCreationConstants.GET_DELEGATE_LIST_SUCCESS,
      getDelegateListData: Data,
    };
  };

  const getDelegateList = (Id) => {
    return (dispatch) => {
      dispatch(getDelegateListLoading());
      accountApi
        .getDelegateListRequest(Id)
        .then((response) => {

          dispatch(getDelegateListSuccess(response.data.info));
        })
        .catch((error) => {
          dispatch(getDelegateListError(error));
        });
    };
  };

export const accountAction = {
  realterSignup,
  getAllPersonsByProfileCreated,
  updatePersonCreated,
  getUserDetails,
  getDelegateList,
};

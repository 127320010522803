import React from "react";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";
import styles from "../style/Error.module.css";
function Error404({ flag }) {
  const title = () => {
    return;
  };
  return (
    <Result
      status="404"
      title={
        <p className={styles.errorMessage}>
          Sorry, the page you visited does not exist.
        </p>
      }
      subTitle={<h3>404</h3>}
      extra={
        flag && (
          <Button type="primary">
            <Link to="/dashboard">Back Home</Link>
          </Button>
        )
      }
    />
  );
}

export { Error404 };


export const formatAddress = (address) => {
  let formattedAddress =
    address &&
    `${address.streetNumber ? address.streetNumber + " " : ""}
    ${
      address.unit && address.streetName
        ? address.streetName + ", " + address.unit + ", "
        : ""
    }
    ${!address.unit && address.streetName ? address.streetName + ", " : ""}
    ${address.city ? address.city + ", " : ""} ${
      address.state ? address.state + " " : ""
    }
    ${address.zipCode ? address.zipCode : ""}`;
  if (formattedAddress) {
    return formattedAddress;
  } else {
    return "";
  }
};

export const titleOrderFormatAddress = (address) => {
  let formattedAddress =
    address &&
    `${address.streetNumber ? address.streetNumber + " " : ""}` +
    `${
      address.unit && address.streetName
        ? address.streetName + ", " + address.unit + ", "
        : ""
    }` +
    `${!address.unit && address.streetName ? address.streetName + ", " : ""}` +
    `${address.city ? address.city + ", " : ""}` +
    `${address.state ? address.state + " " : ""}` +
    `${address.zipCode ? address.zipCode : ""}`;

  if (formattedAddress) {
    return formattedAddress;
  } else {
    return "";
  }
};


export const encryptStr = (str) => {
  let n = str?.length;
  let newStr = "";
  for (let i = 0; i < n; i++) {
    if (i !== 0 && i % 4 === 0) {
      newStr += str[i] + "-";
    } else {
      newStr += str[i];
    }
  }
  return newStr;
};

import { Modal, Spin,} from 'antd'
import React, { useEffect, useState, useRef,  } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { helpApis } from '../state/actions'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import { PictureOutlined} from "@ant-design/icons";
import UploadImageModal from './UploadImageModal'
import styles from "../style/form.module.css"
import "../style/Form.css"

const PlatForms =["WEB_APP", "ADMIN", "MAKETING", "MOBILE"];

const HelpCreateModal = ({isModalOpen,handleCancel,data,form,setData,page}) => {
    const dispatch = useDispatch();
    const {uploadImageData,uploadImageLoading,rolesData,sectionsData} = useSelector(state=>state.helpTopics);
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [videoLinks, setVideoLinks] = useState([]);
    const [editorHtml, setEditorHtml] = useState('');
    const [selectedPlatformOptions, setSelectedPlatformOptions] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [tags, setTags] = useState([]);
    const [inputValue, setInputValue] = useState('');

    // new code for platform select box
    const dropdownRef = useRef(null);
    const [isOptionsOpen, setOptionsOpen] = useState(false);

    const togglePlatformOption = (option) => {
      if (selectedPlatformOptions.includes(option)) {
        setSelectedPlatformOptions(selectedPlatformOptions.filter((item) => item !== option));
      } else {
        setSelectedPlatformOptions([...selectedPlatformOptions, option]);
      }
    };

    const toggleOptionsDropdown = () => {
      setOptionsOpen(true);
    };

    useEffect(() => {
      // Add a click event listener to close the dropdown when clicking outside of it
      const handleOutsideClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setOptionsOpen(false);
        }
      };
      // Attach the event listener when the component mounts
      document.addEventListener('mousedown', handleOutsideClick);
      // Remove the event listener when the component unmounts
      return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
      };
    }, []);
//    --------------------------------------code ends here--------------------------------

//----------------------------new code for handle section select box-------------------------
const [selectedSectionOption, setSelecteSectiondOption] = useState([]);
const [isSectionBoxOpen, setSectionBoxOpen] = useState(false);

const dropdownSectionRef = useRef(null);

  const toggleSectionDropdown = () => {
    setSectionBoxOpen(!isOpen);
  };

  const handleSectionSelect = (option) => {
    if (selectedSectionOption.includes(option)) {
      // If the option is already selected, remove it from the array
      setSelecteSectiondOption(selectedSectionOption.filter((item) => item !== option));
    } else {
      // If the option is not selected, add it to the array
      setSelecteSectiondOption([...selectedSectionOption, option]);
    }
    setSectionBoxOpen(false);
  };

  useEffect(() => {
    // Add a click event listener to close the dropdown when clicking outside of it
    const handleOutsideSectionClick = (event) => {
      if (dropdownSectionRef.current && !dropdownSectionRef.current.contains(event.target)) {
        setSectionBoxOpen(false);
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener('mousedown', handleOutsideSectionClick);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleOutsideSectionClick);
    };
  }, []);
//------------------------------code ends here--------------------------------
//-------------------------------new code for section roles------------------------------
const [selectedRolesOption, setSelecteSRolesOption] = useState([...rolesData]);
const [isRoleBoxOpen, setRoleBoxOpen] = useState(false);

const dropdownRoleRef = useRef(null);

  const toggleRoleDropdown = () => {
    setRoleBoxOpen(!isOpen);
  };
  useEffect(() => {
    setSelecteSRolesOption([...rolesData]);
  }, [rolesData]);
  const handleRoleSelect = (option) => {
    if (selectedRolesOption.includes(option)) {
      // If the option is already selected, remove it from the array
      setSelecteSRolesOption(selectedRolesOption.filter((item) => item !== option));
    } else {
      // If the option is not selected, add it to the array
      setSelecteSRolesOption([...selectedRolesOption, option]);
    }
    setSectionBoxOpen(false);
  };

  useEffect(() => {
    // Add a click event listener to close the dropdown when clicking outside of it
    const handleOutsideRoleClick = (event) => {
      if (dropdownRoleRef.current && !dropdownRoleRef.current.contains(event.target)) {
        setRoleBoxOpen(false);
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener('mousedown', handleOutsideRoleClick);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleOutsideRoleClick);
    };
  }, []);
//-----------------------------------ends here-----------------------------

      const addTag = () => {
        if (inputValue.trim() !== '') {
          setTags([...tags, inputValue]);
          setInputValue('');
        }
      };

      const removeTag = (tagIndex) => {
        const updatedTags = [...tags];
        updatedTags.splice(tagIndex, 1);
        setTags(updatedTags);
      };

    const handleChange = (html) => {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = html;

        const imgTags = tempDiv.getElementsByTagName("img");

        for (let i = 0; i < imgTags.length; i++) {
          const img = imgTags[i];

          img.setAttribute("width", 330);
          img.setAttribute("height", 200);
        }

        const modifiedHtml = tempDiv.innerHTML;

        setEditorHtml(modifiedHtml);
      };



  const handleVideoLinkChange = (e) => {
    const linksArray = e.target.value.split('\n').filter((link) => link.trim() !== '');
    setVideoLinks(linksArray);
  };

  const showImageUploadModal = () => {
    setIsImageModalOpen(true);
  }

  const CancelImageUploadModal=(setimageData,imageFormdataRef)=>{
    if(setimageData && imageFormdataRef){
       setimageData([]);
       imageFormdataRef.current = []
    }
    setIsImageModalOpen(false)
  }

  const clearData=()=>{
    setTitle('');
    setEditorHtml('')
    setVideoLinks([]);
    setTags([]);
    setSelectedPlatformOptions([]);
    setSelecteSectiondOption([]);
    setSelecteSRolesOption([...rolesData]);
  }

  const disableBtn = () => {
    if (title === '' || editorHtml === ''|| editorHtml=== '<p><br></p>' || selectedSectionOption === '' || selectedPlatformOptions?.length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const toggleSelectAllOptions = (checkbox) => {
    if(checkbox === 'platforms'){
    if (selectedPlatformOptions?.length === PlatForms?.length) {
        setSelectedPlatformOptions([]);
    } else {
      setSelectedPlatformOptions([...PlatForms]);
    }
   }else if(checkbox==='sections'){
    if (selectedSectionOption?.length === sectionsData?.length) {
        setSelecteSectiondOption([]);
      } else {
        setSelecteSectiondOption([...sectionsData]);
      }
   }else if(checkbox==='roles'){
    if (selectedRolesOption?.length === rolesData?.length) {
        setSelecteSRolesOption([]);
      } else {
        setSelecteSRolesOption([...rolesData]);
      }
   }
  };

      const onFinish =  (event) => {
        event.preventDefault();
        let values={};
        values.title = title;
        values.content = editorHtml;
        values.videos = videoLinks;
        values.tags = tags;
        values.accessibleTO = selectedRolesOption?.filter((el) => el !== "ADMIN_OFFICE");
        values.section = selectedSectionOption;
        values.platForm = selectedPlatformOptions;
        if(isModalOpen.action === 'create' ){
        dispatch(helpApis.postHelpData(values,page));
       }else{
         dispatch(helpApis.editHelpData(values,data._id,page));
       }
       dispatch(helpApis.handleResetImages());
       clearData();
       handleCancel();
       setData([]);
      };

useEffect(() => {
        // Check if uploadImageData is defined before mapping
        if (uploadImageData && uploadImageData?.length > 0) {
          // Convert the list of image URLs into HTML markup
          const imagesHtml = uploadImageData
            .map((imageUrl) => `<img src="${imageUrl}" width='300px' height='200px' alt="Image" className="previewImage" />`)
            .join('');

          // Set the editor content
          setEditorHtml((prevEditorHtml) => prevEditorHtml + imagesHtml);
        }
      }, [uploadImageData]);

      useEffect(() => {
        if (isModalOpen.action === "edit") {
          setEditorHtml(data.content || ''); // Set the editor content with editData.content
        }
      }, [isModalOpen.action, data]);

      useEffect(() => {
        if (data) {
          if (data.title) {
            setTitle(data.title);
          }
          if (data.tags) {
            setTags(data.tags);
          }
          if (data.videos) {
            setVideoLinks(data.videos);
          }
          if (data.editorHtml) {
            setEditorHtml(data.editorHtml);
          }
          if (data.section) {
            setSelecteSectiondOption(data.section);
          }
          if (data.accessibleTO) {
            setSelecteSRolesOption(data.accessibleTO);
        }
          if (data.platForm) {
              setSelectedPlatformOptions(data.platForm);
            }
        }
      }, [data]);


      useEffect(() => {
        dispatch(helpApis.rolesApiCall('ROLES'));
        dispatch(helpApis.sectionsApiCall('SECTIONS'));
      }, [dispatch])


  return (
    <div>
        {/* Image Upload Modal */}
    <UploadImageModal isImageModalOpen={isImageModalOpen} CancelImageUploadModal={CancelImageUploadModal}
    setEditorHtml={setEditorHtml} setData={setData}/>

    <Modal
      style={{marginTop:"-90px",minWidth:"800px",height:"95%",}}
      title={<div style={{display:"flex",justifyContent:"center",color:"grey"}}>
        {isModalOpen.action === 'edit' ? "EDIT HELP TOPIC" : "CREATE HELP TOPIC"}</div>}
        footer={false} visible={isModalOpen.isOpen}  onCancel={()=>handleCancel(clearData)}>
    <div
        className='createHelpForm'
        style={{
        width:"100%",
        maxHeight:"100%",
        overflowY: "auto",
        overflowX: "hidden",
        }}>
    <form action="create question" style={{width:"100%"}} >
        {/* checkboxes */}
     <div style={{display:"flex",justifyContent:"space-between",boxSizing:"border-box"}}>
          {/* platform select box */}
     <div className='flexdiv'>
      <div className='label-bold'>
        <span className='inner-span'>*</span>Platform
      </div>
      <div className='custom-select-container' ref={dropdownRef}>
        <div className={`custom-select ${isOptionsOpen ? 'open' : ''}`} onClick={toggleOptionsDropdown}>
          <div className='selected-option'>
            {/* {selectedPlatformOptions.length === 0 ? 'Select Platforms' : selectedPlatformOptions.join(', ')} */}
            {(Array.isArray(selectedPlatformOptions)) ?  selectedPlatformOptions.join(', ') :selectedPlatformOptions}
            {selectedPlatformOptions?.length === 0 && 'Select Platforms' }
          </div>
          <ul className='options'>
          {isOptionsOpen && (
          <li
            className={`option ${selectedPlatformOptions?.length === PlatForms?.length ? 'selected' : ''}`}
            onClick={() => toggleSelectAllOptions('platforms')}
          >
            Select All
          </li>
           )}
            {isOptionsOpen &&
              PlatForms.map((option, index) => (
                <li
                  key={index}
                  className={`option ${selectedPlatformOptions.includes(option) ? 'selected' : ''}`}
                  onClick={() => togglePlatformOption(option)}
                >
                  {option}
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>

    <div className='flexdiv'>
      <div className='label-bold'>
        <span className='inner-span'>*</span>Section
      </div>
      <div className='custom-select-container' ref={dropdownSectionRef}>
        <div className={`custom-select ${isOpen ? 'open' : ''}`} onClick={toggleSectionDropdown}>
          <div className='selected-option'>
            {(Array.isArray(selectedSectionOption)) ?  selectedSectionOption.join(', ') :selectedSectionOption}
            {selectedSectionOption?.length === 0 && 'Select Sections' }
          </div>
          <ul className='options'>
          {isSectionBoxOpen && (
          <li
            className={`option ${selectedSectionOption?.length === sectionsData?.length ? 'selected' : ''}`}
            onClick={() => toggleSelectAllOptions('sections')}
          >
            Select All
          </li>
           )}
            {isSectionBoxOpen &&
              sectionsData.map((option, index) => (
                <li
                  key={index}
                  className={`option ${selectedSectionOption.includes(option) ? 'selected' : ''}`}
                  onClick={() =>{ handleSectionSelect(option)}}
                >
                  {option}
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>

          {/* Roles select box */}
        <div className='flexdiv' >
        <div className='label-bold' htmlFor="accessibleTO"> <span className='inner-span'>*</span>Roles</div>
        <div className='custom-select-container' ref={dropdownRoleRef}>
        <div className={`custom-select ${isOpen ? 'open' : ''}`} onClick={toggleRoleDropdown}>
           <div className='selected-option'>
            {(Array.isArray(selectedRolesOption)) ?  selectedRolesOption.join(', ') :selectedRolesOption}
            {selectedRolesOption?.length === 0 && 'Select Roles' }
          </div>
          <ul className='options'>
          {isRoleBoxOpen && (
          <li
            className={`option ${selectedRolesOption?.length === rolesData?.length ? 'selected' : ''}`}
            onClick={() => toggleSelectAllOptions('roles')}
          >
            Select All
          </li>
           )}
            {isRoleBoxOpen &&
              rolesData.map((option, index) => (
                <li
                  key={index}
                  className={`option ${selectedRolesOption.includes(option) ? 'selected' : ''}`}
                  onClick={() =>{ handleRoleSelect(option)}}
                >
                  {option}
                </li>
              ))}
          </ul>
        </div>
      </div>
        </div>

      </div>

      {/* Title div  */}
      <div className='flexdiv'>
      <div className='label-bold' htmlFor="title"><span className='inner-span'>*</span>Title</div>
     <input
        name='title'
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        className='custom-input'
        type="text"
        placeholder='Enter title'
        required
      />
      </div>


      {/* content div  */}
      <div className='flexdiv'>
        <div className='imageIcon'>
            <PictureOutlined classNameName='imgIcon' onClick={showImageUploadModal} style={{fontSize:"18px"}} />
        </div>
        <div className='label-bold'><span className='inner-span'>*</span> Content</div>
        <div style={{textAlign:"center",position:"relative",top:"150px"}}>
            {uploadImageLoading ? <Spin/> : null}
        </div>
      <ReactQuill
             name='content'
            style={{ height: "200px",marginBottom:"90px" }}
            value={editorHtml ? editorHtml : data.content}
            onChange={handleChange}
            modules={{
              toolbar: [
                ["bold", "italic", "underline", "strike"], // toggled buttons
                ["blockquote", "code-block"],
                [{ header: 1 }, { header: 2 }], // custom button values
                [{ list: "ordered" }, { list: "bullet" }],

                [{ script: "sub" }, { script: "super" }], // superscript/subscript
                [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                [{ direction: "rtl" }], // text direction
                [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                [{ font: [] }],
                [{ align: [] }],
                ["clean"],// remove formatting button
              ],
            }}
          />
      </div>

      {/* Tags div  */}
     <div className="flexdiv">
      <div className="label-bold" htmlFor="tags">
        Tags
      </div>
      <div>
        {tags?.map((tag, index) => (
          <div
            key={index}
            className="tag"
            style={{ backgroundColor: '#40a9ff',fontWeight:"bold" }}
          >
            {tag}
            <button
            type='button'
              className="delete-button"
              onClick={() => removeTag(index)}
            >
              X
            </button>
          </div>
        ))}
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <input
        className='custom-input'
          style={{ width: "80%" }}
          type="text"
          placeholder="Enter tags"
          value={inputValue}
          onChange={(e) =>  setInputValue(e.target.value)}
        />
        <span className="custom-button"  onClick={addTag}>Add Tags</span>
      </div>
    </div>

      {/* video link div */}
      <div>
        <div  className='label-bold' htmlFor="videos">Video Links</div>
        <textarea
           value={videoLinks}
            name="videos"
            className='custom-input'
            style={{width:"100%"}}
            onChange={handleVideoLinkChange}
            placeholder="Enter video links, one per line"
             />
      </div>
      <button disabled={disableBtn()} className="custom-button" style={{width:"100%"}} onClick={onFinish}>Save</button>
     </form>
     </div>
      </Modal>
    </div>
  )
}

export  {HelpCreateModal}

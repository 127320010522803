import React from "react";
import { Form, Input, Button, Row, Checkbox } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import styles from "../styles/Admin.module.css";
import Text from "antd/lib/typography/Text";
import { Link } from "react-router-dom";

/* eslint-enable no-template-curly-in-string */
function validateMessages(label) {
  return {
    required: `${label} is required!`,
    types: {
      email: `${label} is not a valid email!`,
      number: `${label} is not a valid number!`,
    },
  };
}
/* eslint-enable no-template-curly-in-string */
function Login({ onFinish }) {
  return (
    <div style={{ padding: "2rem 2rem" }}>
      <div>
        <img style={{ width: "52px", height: "auto" }} src={"https://resureprojectbucket.s3.us-east-2.amazonaws.com/dev/649be3538168791298e1a3f0/RESURE_RESERVED/18resure-logo-short-blue-text.png"} alt="Logo" />
      </div>
      <div style={{position:"relative",top:"-50px"}}>
        <h1 className={styles.custFormH1}>Sign In</h1>
        <h2 className={styles.custFormH2}>Welcome to RE-Sure</h2>
      </div>
      <Form
      layout="vertical"
        name="normal_login"
        // className={styles.loginForm}
        style={{marginTop:"-50px"}}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        validateMessages={validateMessages}
      >
        <Form.Item
          label={<p style={{fontSize:"18px",color:"grey",fontWeight:"bold",paddingTop:"10px"}}>Email</p>}
          className="lbl1"
          name="email"
          hasFeedback
          rules={[
            {
              type: "email",
              message: "Not a valid Email!",
            },
            {
              required: true,
              message: "Please Enter Email!",
            },
          ]}
        >
          <Input
            allowClear
            prefix={
              <UserOutlined
                className="site-form-item-icon"
                style={{ marginRight: "15px" }}
              />
            }
            placeholder="Enter Email"
           
          />
        </Form.Item>
        <Form.Item
           name="password"
           label={<p style={{fontSize:"18px",color:"grey",fontWeight:"bold",paddingTop:"10px"}}>Password</p>}
           className="lbl1"
           hasFeedback
          rules={[
            {
              required: true,
              message: "",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (value?.length > 5 && value?.includes(" ") === false) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  new Error("Required minimum 6 characters password!")
                );
              },
            }),
          ]}
        >
          <Input.Password
            allowClear
            prefix={
              <LockOutlined
                className="site-form-item-icon"
                style={{ marginRight: "15px" }}
              />
            }
            type="password"
            placeholder="Enter Password"
          />
        </Form.Item>

        <Form.Item>
          <Row justify="space-between">
            <Form.Item>
              <Checkbox style={{ color: "#7f8082" }}>
                Keep me logged in
              </Checkbox>
            </Form.Item>
            <Link
              to={{
                pathname: "/forgetpassword",
                // state: { email: emailVal && emailVal },
              }}
            >
              {" "}
              Forgot Password?
            </Link>
          </Row>
        </Form.Item>
        {/* <Form.Item justify="space-between">
          <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

          <a className={styles.loginFormForgot} href="*">
            Forgot password
          </a>
        </Form.Item> */}

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className={styles.btnPrimary}
            style={{width:"200px",alignContent:"center",margin:"auto",fontWeight:"bold",fontSize:"15px",textAlign:"center"}}
          >
            Log in
          </Button>
          <Row justify="center" style={{ marginTop: "15px" }}>
            <Text type="secondary">
              Not yet a member ?
              <Link to="/signup" disabled>
                {" "}
                Sign up now
              </Link>
            </Text>
          </Row>
        </Form.Item>
      </Form>
    </div>
  );
}
export { Login };

import React, { useState, useEffect } from "react";
import { Table, Input, InputNumber, Form } from "antd";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const EditableTableForSuggession = ({ list }) => {
  // const dispatch = useDispatch();
  const [data, setData] = useState(list);
  useEffect(() => {
    if (list) {
      let originData = [];
      for (let i = 0; i < list.length; i++) {
        originData.push({
          key: i,
          title: list[i],
        });
      }
      setData(originData);
    }
  }, [list]);
  const [form] = Form.useForm();
  // const [editingKey, setEditingKey] = useState("");
  let editingKey = "";

  const isEditing = (record) => record.key === editingKey;

  // const edit = (record) => {
  //   form.setFieldsValue({
  //     name: "",
  //     age: "",
  //     address: "",
  //     ...record,
  //   });
  //   setEditingKey(record.key);
  // };

  // const cancel = () => {
  //   setEditingKey("");
  // };

  // const save = async (key) => {
  //   try {
  //     const row = await form.validateFields();
  //     // dispatch(listingEditAction.editCategory(row, key));

  //     const newData = [...data];
  //     const index = newData.findIndex((item) => key === item.key);
  //     if (index > -1) {
  //       const item = newData[index];
  //       newData.splice(index, 1, { ...item, ...row });
  //       setData(newData);
  //       setEditingKey("");
  //     } else {
  //       newData.push(row);
  //       setData(newData);
  //       setEditingKey("");
  //     }
  //   } catch (errInfo) {
  //   }
  // };

  // let handleDelete = (event) => {
  //   // dispatch(listingEditAction.deleteCategory(event.key));
  // };

  const columns = [
    {
      title: "Name",
      dataIndex: "title",
      width: "25%",
      key: "title",
      editable: true,
    },
    // {
    //   title: "Operation",
    //   key: "Operation",
    //   dataIndex: "operation",
    //   render: (_, record) => {
    //     const editable = isEditing(record);
    //     return editable ? (
    //       <span>
    //         <Button
    //           disabled
    //           onClick={() => save(record.key)}
    //           style={{
    //             marginRight: 8,
    //           }}
    //           danger
    //         >
    //           Save
    //         </Button>
    //         <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
    //           <Button danger>Cancel</Button>
    //         </Popconfirm>
    //       </span>
    //     ) : (
    //       <Typography.Link
    //         // disabled={editingKey !== ""}
    //         disabled={true}
    //         onClick={() => edit(record)}
    //       >
    //         Edit
    //       </Typography.Link>
    //     );
    //   },
    // },
    // {
    //   title: "Action",
    //   dataIndex: "",
    //   key: "x",
    //   render: (_, record) => (
    //     <Button disabled onClick={() => handleDelete(record)} danger>
    //       Delete
    //     </Button>
    //   ),
    // },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        scroll={{ y: 440 }}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
      />
    </Form>
  );
};

export { EditableTableForSuggession };

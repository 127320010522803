import { message } from "antd";
import { subscriptionMainApi } from "../utils/api";
import { subcriptionMainConstants } from "./action-types";

// Get special offer data methods
const getSpecialplanLoading =()=>{
    return{
        type : subcriptionMainConstants.GET_SPECIALPLANS_LOADING,
        plansLoading:true,
        PlansError:false,
    }
};

const getSpecialplanError =()=>{
    return{
        type : subcriptionMainConstants.GET_SUCCESSPLANS_ERROR,
        plansLoading:false,
        PlansError:true,
    }
};

const getSpecialplanSuccess =({data})=>{
    return{
        type : subcriptionMainConstants.GET_SPECIALPLANS_SUCCESS,
        plansData: data?.info,
        plansLoading:false,
        PlansError:false,
    }
};

const getSpecialPlans = () => {
    return (dispatch) => {
      dispatch(getSpecialplanLoading());
      subscriptionMainApi
        .getSpecialPlansRequest()
        .then((response) => {
      dispatch(getSpecialplanSuccess(response));
        })
        .catch((error) => {
          if (error.response) {
            dispatch(getSpecialplanError(error.response.data));
          }
        });
    };
  };

//Edit Special plans
const editSpecialplanLoading =()=>{
    return{
        type : subcriptionMainConstants.EDIT_SPECIALPLANS_LOADING,
        plansLoading:true,
        PlansError:false,
    }
};

const editSpecialplanError =()=>{
    return{
        type : subcriptionMainConstants.EDIT_SPECIALPLANS_ERROR,
        plansLoading:false,
        PlansError:true,
    }
};

const editSpecialplanSuccess =({data})=>{
    message.success(data?.info?.message);
    return{
        type : subcriptionMainConstants.EDIT_SPECIALPLANS_SUCCESS,
        plansLoading:false,
        PlansError:false,
    }
};

const editSpecialPlans = (id, data, addCode,codeId) => {
    return (dispatch) => {
      dispatch(editSpecialplanLoading());
      subscriptionMainApi
        .editSpecialPlansRequest(id, data, addCode,codeId)
        .then((response) => {
        dispatch(editSpecialplanSuccess(response));
        dispatch(getSpecialPlans());
        })
        .catch((error) => {
          if (error.response) {
            dispatch(editSpecialplanError(error.response.data));
          }
        });
    };
  };

 

export const subscriptionMainHandlers={
    getSpecialPlans,
    editSpecialPlans,
}

import React, { useRef, useState } from "react";
import { Modal } from "antd";

const DraggableModal = ({ children, ...props }) => {
  const [dragging, setDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y:30 });
  const modalRef = useRef(null);
  const offsetRef = useRef({ x: 0, y: 0 });

  //onMouseDown event handler triggers the start of dragging,
  const handleMouseDown = (e) => {
    setDragging(true);
    if (modalRef?.current) {
      const { clientX, clientY } = e;
      const { left, top } = modalRef.current.getBoundingClientRect();
      offsetRef.current = { x: clientX - left, y: clientY - top };
    }
  };
  //onMouseMove event handles the movement of the Modal as the user drags it
  const handleMouseMove = (e) => {
    if (!dragging) return;
    const { clientX, clientY } = e;
    setPosition({ x: clientX - offsetRef.current.x, y: clientY - offsetRef.current.y });
  };
  //onMouseUp stops the dragging when the mouse button is released.
  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleModalRef = (node) => {
    modalRef.current = node;
  };

  return (
    <div
      ref={handleModalRef}
      style={{
        position: "absolute",
        top: position.y,
        left: position.x,
        cursor: dragging ? "grabbing" : "context-menu",
      }}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
        }}
        onMouseDown={handleMouseDown}
      >
        <Modal
           ref={handleModalRef}
          {...props}
          width={500}
          height={window.screen.height>600?600:330}
        style={{
            top: position.y,
            left: position.x,
            cursor: dragging ? "grabbing" : "context-menu",
          }}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
        >
          {children}
        </Modal>
      </div>
    </div>
  );
};

export default DraggableModal;

export const offerTableConstants = {
  OFFER_TABLE_SUCCESS: "OFFER_TABLE_SUCCESS",
  OFFER_TABLE_FAILURE: "OFFER_TABLE_FAILURE",

  POST_STARS_SUCCESS: "POST_STARS_SUCCESS",
  POST_STARS_FAILURE: "POST_STARS_FAILURE",

  CHECKED_OFFER_SUCCESS: "CHECKED_OFFER_SUCCESS",
  CHECKED_OFFER_FAILURE: "CHECKED_OFFER_FAILURE",

  OFFERTABLE_DADA_SUCCESS: "OFFERTABLE_DADA_SUCCESS",
  OFFERTABLE_DADA_FAILURE: "OFFERTABLE_DADA_FAILURE",

  GET_EMAIL_TEMPLATE_LOADING: "GET_EMAIL_TEMPLATE_LOADING",
  GET_EMAIL_TEMPLATE_SUCCESS: "GET_EMAIL_TEMPLATE_SUCCESS",
  GET_EMAIL_TEMPLATE_FAILURE: "GET_EMAIL_TEMPLATE_FAILURE",

  GET_CONSTANTS_LOADING: "GET_CONSTANTS_LOADING",
  GET_CONSTANTS_SUCCESS: "GET_CONSTANTS_SUCCESS",
  GET_CONSTANTS_FAILURE: "GET_CONSTANTS_FAILURE",

  SET_OFFER_TABLE_LOADING:"SET_OFFER_TABLE_LOADING",

  INPUT_FILL_SUCCESS: "INPUT_FILL_SUCCESS",
  INPUT_FILL_FAILURE: "INPUT_FILL_FAILURE",

  URL_SHORT_SELECT_OFFER_SUCCESS: "URL_SHORT_SELECT_OFFER_SUCCESS",
  URL_SHORT_SELECT_OFFER_FAILURE: "URL_SHORT_SELECT_OFFER_FAILURE",

  SELECT_OFFER_SUCCESS: "SELECT_OFFER_SUCCESS",
  SELECT_OFFER_FAILURE: "SELECT_OFFER_FAILURE",

  SET_ERROR_IN_OFFER: "SET_ERROR_IN_OFFER",

  // Email action Type
  SEND_EMAIL_LOADING: "SEND_EMAIL_LOADING",
  SEND_EMAIL_SUCCESS: "SEND_EMAIL_SUCCESS",
  SEND_EMAIL_FAILURE: "SEND_EMAIL_FAILURE",
  //password action Type
  SEND_PASSWORD_LOADING: "SEND_PASSWORD_LOADING",
  SEND_PASSWORD_SUCCESS: "SEND_PASSWORD_SUCCESS",
  SEND_PASSWORD_FAILURE: "SEND_PASSWORD_FAILURE",

  SHARE_OFFER_TABLE_LOADING: "SHARE_OFFER_TABLE_LOADING",
  SHARE_OFFER_TABLE_SUCCESS: "SHARE_OFFER_TABLE_SUCCESS",
  SHARE_OFFER_TABLE_FAILURE: "SHARE_OFFER_TABLE_FAILURE",

  GET_RESTRICTED_CLIENT_OFFER_TABLE_LOADING: "GET_RESTRICTED_CLIENT_OFFER_TABLE_LOADING",
  GET_RESTRICTED_CLIENT_OFFER_TABLE_SUCCESS: "GET_RESTRICTED_CLIENT_OFFER_TABLE_SUCCESS",
  GET_RESTRICTED_CLIENT_OFFER_TABLE_FAILURE: "GET_RESTRICTED_CLIENT_OFFER_TABLE_FAILURE",

  RESTRICT_OFFER_TABLE_LOADING: "RESTRICT_OFFER_TABLE_LOADING",
  RESTRICT_OFFER_TABLE_SUCCESS: "RESTRICT_OFFER_TABLE_SUCCESS",
  RESTRICT_OFFER_TABLE_FAILURE: "RESTRICT_OFFER_TABLE_FAILURE",

  ACCEPT_OFFER_VIA_RESURE_SIGN_LOADING: "ACCEPT_OFFER_VIA_RESURE_SIGN_LOADING",
  ACCEPT_OFFER_VIA_RESURE_SIGN_SUCCESS: "ACCEPT_OFFER_VIA_RESURE_SIGN_SUCCESS",
  ACCEPT_OFFER_VIA_RESURE_SIGN_FAILURE: "ACCEPT_OFFER_VIA_RESURE_SIGN_FAILURE",
};

import React, { useState, useEffect } from "react";
import { listingEditAction } from "../state/actions";
// import { uuid } from "uuidv4";
import {
  Table,
  Input,
  InputNumber,
  Button,
  Popconfirm,
  Form,
  Upload,
  Avatar,
  Image,
  message,
  Typography,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const EditableTable = ({ list }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState(list);
  useEffect(() => {
    if (list) {
      let originData = [];
      for (let i = 0; i < list.length; i++) {
        originData.push({
          key: list[i]._id,
          name: list[i].name,
          categoryType: list[i].categoryType,
          imageUrl: list[i].imageUrl,
        });
      }
      setData(originData);
    }
  }, [list]);
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      name: "",
      age: "",
      address: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      dispatch(listingEditAction.editCategory(row, key));

      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
    }
  };

  let handleDelete = (event) => {
    dispatch(listingEditAction.deleteCategory(event.key));
  };

  let handleImageUpload = (event, id, name) => {
    dispatch(listingEditAction.uploadImage(event.file, id, name));
  };

  const props = {
    beforeUpload: (file) => {
      if (file.type !== "image/png") {
        message.error(`${file.name} is not a png file`);
      }
      return file.type === "image/png" ? true : Upload.LIST_IGNORE;
    },
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "25%",
      key: "name",
      editable: true,
    },
    {
      title: "Category Type",
      dataIndex: "categoryType",
      width: "25%",
      key: "categoryType",
      editable: true,
    },
    {
      title: "Operation",
      key: "Operation",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
              danger
            >
              Save
            </Button>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <Button danger>Cancel</Button>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (_, record) => (
        <Button onClick={() => handleDelete(record)} danger>
          Delete
        </Button>
      ),
    },
    {
      title: "Upload Icon",
      dataIndex: "",
      key: "x",
      render: (_, record) => {
        if (record.imageUrl) {
          return (
            <Avatar
              src={<Image size={32} shape="square" src={record.imageUrl} />}
            />
          );
        } else {
          return (
            <Upload
              {...props}
              showUploadList={false}
              customRequest={(e) =>
                handleImageUpload(e, record.key, record.name)
              }
            >
              <Button icon={<UploadOutlined />}>Icon Upload</Button>
            </Upload>
          );
        }
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
      />
    </Form>
  );
};

export { EditableTable };

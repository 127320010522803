export const verificationConstants = {
  VERIFICATION_SUCCESS: "VERIFICATION_SUCCESS",
  VERIFICATION_FAILURE: "VERIFICATION_FAILURE",

  RESET_VERIFICATION: "RESET_VERIFICATION",

  GET_USER_DATA_SUCCESS: 'GET_USER_DATA_SUCCESS',
  GET_USER_DATA_LOADING: 'GET_USER_DATA_LOADING',
  GET_USER_DATA_ERROR: 'GET_USER_DATA_ERROR',
  
};

import React, { useRef, useState, useEffect } from "react";
import Highlighter from "react-highlight-words";
import { SearchOutlined ,UserOutlined,DeleteOutlined,FilterFilled} from "@ant-design/icons";
import {
  Table,
  Input,
  Image,
  Typography,
  Button,
  Form,
  Space,
  Popconfirm,
  Modal,
  List,
  Avatar,
  Select,
} from "antd";
import { bugReportHandle } from "../state/actions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Loading } from "../../Common/components/Loading";
import { EmptyData } from "../../Common/components/EmptyData"
import { generateFullNameFromObj, generateInitialsFromObj } from "../../Common/utils/universalFuntions";
import { roleAccessFunc } from "../../Common/commondata/commonData";

const text = "Are you sure to delete this task?";
const { Text } = Typography;

const BUGTable = ({ list }) => {

  const { totalRecords, bugLlistingData ,bugLlistLoading} = useSelector(
    (state) => state.bugReport
  );
  const {roleAccessed } = useSelector(
    (state) => state.account,
    shallowEqual,
  );
  const dispatch = useDispatch();

  const [searchText, setsearchText] = useState("");
  const [searchedColumn, setsearchedColumn] = useState("");
  const [filterbystatus,setFilterbystatus]=useState("");
  const [disableBtn, setdisableBtn] = useState(true);
  const [form] = Form.useForm();
  const searchInput = useRef();
  const searchRef = useRef();

  useEffect(() => {
    let query = {
      ...currentQuery,
      page: currentPage,
      limit: 20,
    };
  dispatch(bugReportHandle.getBugListingDataWith(query));
 } ,[dispatch]);

  let handleSearch = (selectedKeys, confirm, dataIndex) => {
   confirm();
   searchRef.current = true;
   setsearchText(selectedKeys[0]);
    let query = {
      ...currentQuery,
      [dataIndex]:selectedKeys[0],
      page: 1,
      limit: 20,
    };
    dispatch(bugReportHandle.getBugListingDataWith(query))
    setsearchedColumn(dataIndex);
    setCurrentQuery(query);
    setCurrentPage(1);
   searchRef.current = false;
  };
  let getstatus = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <div style={{marginBottom:"10px"}}>
        <Select
                style={{ width: "100%" }}
                placeholder="Select Status"
                onChange={(role, event) => {
             setFilterbystatus(role)
                }}
                options={[
                    {
                      value: "ACTIVE",
                      label: "ACTIVE",
                    },
                    {
                      value: "CLOSED",
                      label: "CLOSED",
                    },
                ]}
              />
              </div>
        <Space>
          <Button
            type="primary"
            onClick={() => {handleFilterChange(filterbystatus);setdisableBtn(false);}}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Filter
          </Button>
          <Button
             onClick={() => {handleReset(clearFilters, setSelectedKeys); setdisableBtn(true);}}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterFilled style={{ color: filtered ? "#1890FF" : undefined }} />
    ),
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#FFC069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
const handleFilterChange=(value)=>{
   searchRef.current = true;
  let query = {
    ...currentQuery,
    filterByStatus:value
  };

  dispatch(bugReportHandle.getBugListingDataWith(query))
  setCurrentQuery(query)

  setCurrentPage(1);
  searchRef.current = false;
}
  const handleReset = (clearFilters, setSelectedKeys) => {
   searchRef.current = true;
    clearFilters();
    setSelectedKeys("");
    let query = {
      page: currentPage,
      limit: 20,
    };
    dispatch(bugReportHandle.getBugListingDataWith(query))
    setsearchText("");
    setCurrentQuery({})
   searchRef.current = false;
  };

  const handleResetfilters = () => {
   searchRef.current = true;
    let query = {
      page: currentPage,
      limit: 20,
    };
    dispatch(bugReportHandle.getBugListingDataWith(query))
    setsearchText("");
    setCurrentQuery({})
   searchRef.current = false;
  };

  const [currentPage,setCurrentPage]=useState(1)
  const [showComment,setshowComment]=useState(false)
  const [currentQuery,setCurrentQuery]=useState({})
  const { bugReportCommentData,bugReportCommentLoading} = useSelector(
    (state) => state.bugReport
  );
  const[bugId,setBugId]=useState("")
  const[comment,setComment]=useState("")
  const handleCancelComment=()=>{

    setshowComment(false)
  }
 const  handleOpenComment=(id)=>{
  setBugId(id)
  setshowComment(true)
 dispatch(bugReportHandle.getBugReportComment(id))

 }
 const handlecomment=(e)=>{
  setComment(e.target.value)
 }
 const handleAddComment=(id,comment)=>{
  dispatch(bugReportHandle.addCommentOnBugs(id,comment))
  setComment(" ")
}

function copyText(tetx){
    return(
      <Typography.Text copyable>{text}</Typography.Text>
    )
}

  let getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput.current = node;
          }}
          placeholder={dataIndex==="idTitleSearch"? `Search in id & title`:dataIndex==="nameEmailSearch"?`Search in name & email`:"Search in comments"}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
         onPressEnter={() => { searchRef.current = true;handleSearch(selectedKeys, confirm, dataIndex);setdisableBtn(false)}}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
                searchRef.current = true;
                handleSearch(selectedKeys, confirm, dataIndex);
                setdisableBtn(false)}
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
             onClick={() => {handleReset(clearFilters, setSelectedKeys);setdisableBtn(true);}}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),

    render: (text) => {

      const fullName = text.fullName ? text.fullName.toString() : "";
      const email = text.email ? text.email.toString() : "";
      const id= text.id?text.id:"";
      const title=text.title?text.title:""
      const stripHtmlTags = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
      };

      const textToHighlight = text?.content?.map(el => el?.description).join('\n');
      const strippedText = stripHtmlTags(textToHighlight);
      const content2=text
      return searchedColumn === dataIndex ? (
        <>
          {dataIndex==="nameEmailSearch"?<><div>
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={fullName}
            />
          </div>
          <div style={{ fontSize: "11px", color: "#787474" ,}}>
            <Typography.Text copyable={{text:email}}>
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={email}
            />
            </Typography.Text>
          </div></>:dataIndex ==="idTitleSearch"?<><div>
          <Typography.Text copyable={{text:id}}>
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={id}
            />
            </Typography.Text>
          </div>
          <div style={{ fontSize: "11px", color: "#787474" }}>
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={title}
            />
          </div></>
         :dataIndex ==="contentSearch"?
         <><div>
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
            textToHighlight={strippedText}
            />
          </div>
         </>
        :<><div>
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
        textToHighlight={content2}
        />
      </div>
     </>
        }</>
      ) : (
        <>
        {dataIndex ==="nameEmailSearch"?
        <>
          <div style={{fontWeight:"bold"}}>{text.fullName ? text.fullName : "-"}</div>
          <Typography.Text copyable>{text?.email}</Typography.Text>
        </>:dataIndex ==="idTitleSearch"?<>
            <Typography.Text copyable>{text?.id}</Typography.Text>
          <div >
            {text.title ? `${text?.title} ` : ""}
          </div></>
          :dataIndex ==="contentSearch"?<><div style={{display:"flex",justifyContent:"space-between",padding:"10px",width:"100%",flexDirection:"column"}}>
          <div style={{width:"100%"}} >{text?.content?.map((el,ind)=>(<div style={{dipslay:"flex",flexDirection:"coloumn"}}>
          {ind !== 0 &&
                  <div style={{color:"#2976be"}}>
                     {moment(el?.addedDate).format('MMMM D, YYYY hh:mm A')}
                  </div>
          }
              {/* <div>{el?.description}</div> */}
              <div dangerouslySetInnerHTML={{ __html: el?.description }} />
          </div>))}</div><div style={{width:"40%",fontSize:"10px"}}>
          <div style={{width:"100%"}} ><Button style={{width:"100px",marginTop:"10px"}} onClick={()=>handleOpenComment(text.id)}>Comments</Button></div></div></div></>:text
        }</>
      );
    },
  });

  let handleStatusChange = (data) => {
   searchRef.current = true;
    dispatch(
      bugReportHandle.statusChange(
        data.idTitleSearch.id,
        data.status === "ACTIVE" ? "CLOSED" : "ACTIVE"
      )
    );
   searchRef.current = false;
  };

  const handleDelete = (record) => {
    dispatch(bugReportHandle.bugPostDelete(record.idTitleSearch.id));
  };

  const columns = [
    {
      title: "Bug Id & title",
      width: "30%",
      key: "idTitleSearch",
      dataIndex: "idTitleSearch",
      ...getColumnSearchProps("idTitleSearch"),
    },
    {
      title: "Created By",
      width: "30%",
      key: "nameEmailSearch",
      dataIndex: "nameEmailSearch",
      ...getColumnSearchProps("nameEmailSearch"),

    },
    {
      title: "Bug Content",
      width: "50%",
      key: "contentSearch",
      dataIndex: "contentSearch",
      ...getColumnSearchProps("contentSearch"),
    },
    {
      title: "Date",
      width: "20%",
      key: "date",
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      render: (_, record) => {
        return (
          <Text>
            {" "}
            {moment(record.createdAt).format("MMMM D, YYYY hh:mm A")}
          </Text>
        );
      },
    },
    {
      title: "Images",
      width: "15%",
      key: "email",
      render: (_, record) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              cursor: "pointer",
            }}
            // onClick={() => pdfDownload(record.pdfUrl)}
          >
            {
              record && record?.imageUrls?.length>0?  (record && record?.imageUrls && record && record?.imageUrls?.map((ele)=>{
                return <Image
                  width={70}
                  alt="logo"
                  src={ele}
                />
              })):<text>N/A</text>
            }

            <Text style={{ color: "#5a5ad2" }}>{record.pdfFileName}</Text>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "",
      width: "17%",
      ...getstatus('isActive'),
      // filters: [
      //   { text: "ACTIVE", value: "ACTIVE" },
      //   { text: "CLOSED", value: "CLOSED" },
      // ],
      // ...handleFilterChange(),
      // onFilter:handleFilterChange, // Call the function when filter changes

      key: "x",
      render: (_, record) => (
        <Popconfirm
            placement="top"
            title={"Are you sure,you want to change the status?"}
            onConfirm={() => handleStatusChange(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button size="small" style={{"color":record.status === "ACTIVE" ? "green" : "red"}}>{record.status}</Button>
          </Popconfirm>

      ),
    },
  ];

  const deleteBugsColumn = {
    title: "Delete",
    width: "10%",
    key: "jira",
    render: (_, record) => {

      return (
        <Popconfirm
          placement="top"
          title={"Are you sure to delete this bug?"}
          onConfirm={() => handleDelete(record)}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined style={{ color: "red", textAlign: "center" }} />
        </Popconfirm>
      );
    },
  };

  if (roleAccessFunc(roleAccessed, "DELETE_BUGS_TICKET")) {
    columns.splice(6, 0, deleteBugsColumn);
  }

  return (
    <>
     <Modal
          title="Bug Comment"
          visible={showComment}
          onCancel={handleCancelComment}
          footer={false}
        >
         <div style={{display:"flex"}}> <Input value={comment} name={comment} placeholder="Enter your comment here..."  onChange={(e)=>handlecomment(e)}/>
         <Button onClick={()=>handleAddComment(bugId,comment)}>Add Comments</Button></div>
        {bugReportCommentLoading?(<Loading/>): (<>{bugReportCommentData?.length?(bugReportCommentLoading?<Loading/>:<List
            bordered={true}
            style={{ maxHeight: "calc(6* 80px)", overflow: "auto" }}
            itemLayout="horizontal"
            dataSource={bugReportCommentData}
            renderItem={(item) => {
              const intials = generateInitialsFromObj(item);
              return (
                <List.Item
                  key={item._id}
                >
                  <List.Item.Meta
                    avatar={
                      item?.createdBy?.profileImg && item?.createdBy?.profileImg?.original ? (
                        <Avatar
                          style={{
                            border: "3px solid #085191",
                            background: "#347FC0",
                          }}
                          size={35}
                          src={item?.createdBy?.profileImg.original}
                        />
                      ) : intials ? (
                        <Avatar size={35} style={{ background: "#085191" }}>
                          {intials}
                        </Avatar>
                      ) : (
                        <Avatar
                          size={35}
                          icon={<UserOutlined />}
                          style={{ background: "#085191" }}
                        />
                      )
                    }
                    title={
                      <span style={{ fontSize: "20px" }}>
                        {generateFullNameFromObj(item) }
                      </span>
                    }
                    description={

                        <text >
                      {item?.comment}
                        </text>


                    }

                  />

                </List.Item>
              );
            }}

          />):<EmptyData/>}</>)}
        </Modal>
      {/* {jiraModal ? (
        <Modal
          title="Enter Bug Details To export on JIRA portal"
          visible={jiraModal}
          onCancel={canCleJIRAmodal}
          footer={false}
        >
          <JIRAModal
            handleCancel={canCleJIRAmodal}
            selectedJIRA={selectedJIRA}
          />
        </Modal>
      ) : null} */}
      <Form form={form} component={false}>
      { bugLlistLoading?<Loading/>:
       <>
       <div style={{position:"absolute",top:"65px",zIndex:"111",left :'206px'}}>
           <Button disabled={disableBtn} onClick={()=>{handleResetfilters();setdisableBtn(true);}} size="small" style={{marginLeft:"10px"}}>Reset Filters</Button>
           </div>
       <Table
        rowKey={(record) => record._id}
        bordered
        dataSource={bugLlistingData}
        scroll={{ y: "70vh" }}
        maxHeight={'100vh'}
        columns={columns}
        rowClassName="editable-row"
        pagination={{
        position: ["bottomCenter"],
        showSizeChanger: false,
        pageSize: 20,
        current: currentPage,
        total: totalRecords,
        onChange: (page) => {
            if(!searchRef.current){
                let query = {
                    ...currentQuery,
                page,
                limit: 20,
            };
        dispatch(bugReportHandle.getBugListingDataWith(query));
        setCurrentPage(page);
        }},
        }}
        />
        </>}
      </Form>
    </>
  );
};

export { BUGTable };

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { verificationHandle } from "./state/actions";
import { Layout, Card, Typography, Button } from "antd";
import { Loading } from "../Common/components/Loading";
import { SomeThingWentWrong } from "../Common/components/SomeThingWentWrong";
import { SuccessForm } from "../Common/components/SuccessForm";
import { Error404 } from "../Common/components/Error404";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { decryptQuery } from "../Common/utils/universalFuntions";

const { Content } = Layout;
const { Text } = Typography;
const apiURL = process.env.REACT_APP_WEB_BASE_URL;

function Verification(props) {
  useEffect(() => {
    if (props.networkConnection === false) {
      window.location.reload();
    }
  }, [props]);
  const dispatch = useDispatch();
  const location = useLocation();
  const [toggle, settoggle] = useState(false);
  const { id,  source, fullName, userEmail } = useParams();
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const emailKey = decryptQuery(query.get("emailKey")||"");
  const app = decryptQuery(query.get("app")||"");
  const useId = decryptQuery(query.get("sessionId")||"");

  useEffect(() => {
    if(location.pathname.startsWith("/confirm")){
        settoggle(true);
        dispatch(verificationHandle.approveEmailforClause(id,source,fullName,userEmail));
      }else{
        dispatch(verificationHandle.verifyEmailFun(useId,  emailKey, app));
        }
    return () => {settoggle(false);}
  }, [location.pathname])


  // useEffect(() => {
  //   return () => {};
  // }, [dispatch, id, mail, app]);

  const { statusCode, loading, verificationData, verificationError } =
    useSelector((state) => state.verification);

  const handleRedirect = async () => {
    window.location.href = `${apiURL}/login`;
  }

  return (
    <Layout style={{ height: "100vh", padding: "0px" }}>
      <Content
        style={{
          overflow: "auto",
          // background: "rgb(202 202 202)",
          background: "aliceblue",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px;",
        }}
      >
        {!toggle ? <>
        {loading ? (
          <Loading />
        ) : (
          <Card style={{ width: 600 }}>
            {statusCode === 400 ? (
              <SomeThingWentWrong message={verificationError} Back={false} />
            ) : statusCode === 200 ? (
                <>
                  <SuccessForm
                    title={
                      "Your email has been verified successfully. You can now start using RE-Sure."
                    }
                    flag={false}
                  />
                  <div style={{ textAlign: 'center', marginBottom: '30px' }}>
                    <Button type="primary" size="large" onClick={handleRedirect}>Login</Button>
                  </div>
                </>
            ) : (
              <Error404 flag={false} />
            )}
          </Card>
        )}
        </>:
        <>
         {loading ? (
          <Loading />
        ) : (
          <Card style={{ width: 400 }}>
            {statusCode === 400 ? (
              <SomeThingWentWrong message={verificationError} Back={false} />
            ) : statusCode === 200 ? (
              <SuccessForm
                // title={
                //   "You have approved this email to access the FJGG clauses!!"
                // }
                subTitle={
                  <div style={{display:"flex",flexDirection:"column",fontSize:"20px",fontWeight:"bold"}}>
                  <Text style={{color:"grey"}} >You have approved</Text>
                  <Text style={{color:"grey"}}  ellipsis>{fullName.replace("_"," ")}</Text>
                  <Text style={{color:"grey",fontSize:"15px"}}  ellipsis>({userEmail})</Text>
                  <Text style={{color:"grey"}} >to access FJGG {source}</Text>
                </div>
                }
                flag={false}
              />
            ) : (
              <Error404 flag={false} />
            )}
          </Card>
        )}
        </>
        }

      </Content>
    </Layout>
  );
}

export { Verification };

import { Tooltip } from "antd";
import { validateEmail } from "../../CreateOffer/features/helperFunctions";

export const checkAllRequiredFields = (CurrentPdfImportData) => {
  if (!Object.keys(CurrentPdfImportData)?.length) return false;
  const requiredFields = ["Buyers", "FullAddress", "BuyerBrokerName", "BuyerBrokerEmailAddress", "PurchasePrice", "EarnestMoney", "CashAtClosing", "PossessionDate", "ClosingDate", "AcceptanceDeadlineDate"];

  // checking if all required fields are present and have non-empty values
  for (const field of requiredFields) {
    if (!CurrentPdfImportData.hasOwnProperty(field) || (Array.isArray(CurrentPdfImportData[field]) && CurrentPdfImportData[field].length === 0) || !CurrentPdfImportData[field]) {
      return false;
    }
  }
  isAtleastOneClientPresent(CurrentPdfImportData.Buyers);

  if(!validateEmail(CurrentPdfImportData["BuyerBrokerEmailAddress"])){
    return false;
  }

  return true;
};

export const isAtleastOneClientPresent = (clients) => {
  if (Array.isArray(clients) && clients.length > 0) {
    for (const client of clients) {
      if (client.isCorp && client.signers.length > 0) {
        return true;
      } else if (!client.isCorp && client.fullName) {
        return true;
      }
    }
  }
  return false; 
};

export const getPropertyImages = (ele) => {
  if(!typeof ele === "object") return "";
  if(ele?.propertyType?.includes("PRIVATE")){
    return (<Tooltip placement="top" title="RE-Sure"><img style={{width: "29px", borderRadius: "2px", cursor: "pointer"}} src="https://resureprojectbucket.s3.us-east-2.amazonaws.com/dev/65b8f71bdff9fe6ba44c8501/MEMORIES/16RE-Sure%20Blue%20Bug.png" alt=""/></Tooltip>)
  }
  else if(ele?.propertyType?.some(ele => ele.includes("RECOLORADO"))){
    return (<Tooltip placement="top" title="Re-colorado"><img style={{width: "38px", cursor: "pointer"}} src="https://resureprojectbucket.s3.us-east-2.amazonaws.com/dev/64d5ce64dff9fe6ba4cfaf06/MEMORIES/18pastedImage82704" alt=""/></Tooltip>)
  }
  else if(ele?.propertyType?.some(ele => ele.includes("IRES"))){
    return (<Tooltip placement="top" title="IRES"><img style={{width: "54px", height: "24px", cursor: "pointer"}} src="https://resureprojectbucket.s3.us-east-2.amazonaws.com/dev/64d5ce64dff9fe6ba4cfaf06/MEMORIES/18pastedImage84057" alt=""/></Tooltip>)
  }
  else if(ele?.propertyType?.some(ele => ele.includes("PPAR"))){
    return (<Tooltip placement="top" title="PPAR"><img style={{width: "38px", cursor: "pointer"}} src="https://resureprojectbucket.s3.us-east-2.amazonaws.com/dev/657ff2157e660a0701cfaa24/MEMORIES/19PPMLS-logo-MAIN-WebRGB.jpeg" alt=""/></Tooltip>);
  }
  else if(ele?.propertyType?.some(ele => ele.includes("PAR"))){
    return (<Tooltip placement="top" title="PAR"><img style={{width: "38px", cursor: "pointer"}} src="https://resureprojectbucket.s3.us-east-2.amazonaws.com/qa/6444b9c521382a6780572f63/MEMORIES/2pastedImage68271" alt=""/></Tooltip>);
  }
  else if(ele?.propertyType?.some(ele => ele.includes("GJAR"))){
    return (<Tooltip placement="top" title="GJAR"><img style={{width: "78px", cursor: "pointer"}} src="https://resureprojectbucket.s3.us-east-2.amazonaws.com/prod/64cb4128b00a232d9c2cc0d4/MEMORIES/29gjara-logo.png" alt=""/></Tooltip>);
  }
  else return "";
}

import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
// import "react-codes-input/lib/react-codes-input.min.css";
import { SuccessChangePassword } from "./SuccessChangePassword";
import { Form, Input, Button, Typography, Row, Col, Card } from "antd";
import { UserOutlined } from "@ant-design/icons";
import styles from "../styles/Admin.module.css";
import { Link, useHistory, useLocation } from "react-router-dom";
import OtpInput from "react-otp-input";
import { adminAction } from "../state/actions";

const { Text } = Typography;

/* eslint-enable no-template-curly-in-string */
function validateMessages(label) {
  return {
    required: `${label} is required!`,
    types: {
      email: `${label} is not a valid email!`,
      number: `${label} is not a valid number!`,
    },
  };
}
function ForgetPassword() {
  const [activation, setActivation] = useState();
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const {
    userForgetPasswordData,
    userForgetPasswordDataEmail,
    verificationData,
    resetPasswordNewPasswordData,
    alert,
    userForgetPasswordLoading,
    verificationLoading,
    resetPasswordNewPasswordLoading,
  } = useSelector((state) => state.authenticate, shallowEqual);

  useEffect(() => {
    return () => {
      dispatch(adminAction.resetPasswordCleanUp());
    };
  }, []);

  useEffect(() => {
    setError(alert);
  }, [alert]);

  let onFinish = (event) => {
    dispatch(adminAction.userForgetPassword(event));
  };

  let onForgetPasswordBack = () => {
    setActivation("");
    setError("");
    dispatch(adminAction.userForgetPasswordClear());
  };

  let onActivationFinish = () => {
    if (activation === undefined) {
      setError("Please enter the code!");
    } else if (activation.length !== 6) {
      setError("Please enter the code!");
    } else {
      dispatch(
        adminAction.userForgetPasswordCodeVerification(
          activation,
          userForgetPasswordDataEmail
        )
      );
    }
  };

  let saveNewPassword = (event) => {
    if (
      (event && event.password === undefined) ||
      event.password !== event.cpassword
    ) {
      setError(event.password);
    } else {
      dispatch(
        adminAction.resetPasswordSaveNewPassword(
          event.password,
          userForgetPasswordDataEmail,
          verificationData?.data?.info?.code,
        )
      );
    }
  };

  if (
    resetPasswordNewPasswordData &&
    resetPasswordNewPasswordData.data.statusCode === 200
  ) {
    history.push("/successpasswordchange", {
      message: "Password changed successfully",
    });
    return <SuccessChangePassword title="Password changed successfully" />;
  }

  return verificationData && verificationData.data.statusCode === 200 ? (
    <div className={styles.alignAdmin}>
      <Card className={styles.custFormSignup}>
        <div style={{ paddingTop: "3rem" }}>
          <Form layout="vertical" onFinish={saveNewPassword}>
            <Form.Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  marginTop: "7px",
                  marginBottom: "0px",
                }}
              >
                <img
                  className={styles.custFormLogo}
                  style={{ width: "65px", height: "auto" }}
                  src={"https://resureprojectbucket.s3.us-east-2.amazonaws.com/dev/649be3538168791298e1a3f0/RESURE_RESERVED/18resure-logo-short-blue-text.png"}
                  alt="Logo"
                />
              </div>
            </Form.Item>
            <Form.Item>
              <Row justify="center" gutter={[12, 24]}>
                <Col span={24}>
                  <Typography.Title level={2} style={{ textAlign: "center" }}>
                    Reset Password
                  </Typography.Title>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            value?.length > 5 &&
                            value?.includes(" ") === false
                          ) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(
                              new Error(
                                "Required minimum 6 characters password!"
                              )
                            );
                          }
                        },
                      }),
                    ]}
                    hasFeedback
                  >
                    <Input.Password
                      placeholder="Enter new password"
                      maxLength={20}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Confirm Password"
                    name="cpassword"
                    hasFeedback
                    rules={[
                      {
                        message: "",
                        required: true,
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            new Error("Confirm Password does not match")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      placeholder="Confirm your password"
                      maxLength={20}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item style={{ textAlign: "center" }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={resetPasswordNewPasswordLoading}
              >
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  ) : (
    // <Redirect to={{ pathname: "/passwordVerified", state: { role: "user" } }} />
    <div className={styles.alignAdmin}>
      <Card className={styles.custFormSignup}>
        <div style={{ paddingTop: "7rem" }}>
          {userForgetPasswordData && userForgetPasswordData === 200 ? (
            <Form layout="vertical" onFinish={onActivationFinish}>
              <Form.Item>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    marginTop: "-45px",
                    marginBottom: "10px",
                  }}
                >
                  <img
                    className={styles.custFormLogo}
                    style={{ width: "65px", height: "auto" }}
                    src={"https://resureprojectbucket.s3.us-east-2.amazonaws.com/dev/649be3538168791298e1a3f0/RESURE_RESERVED/18resure-logo-short-blue-text.png"}
                    alt="Logo"
                  />
                </div>
              </Form.Item>
              <Form.Item>
                <Row justify="center" gutter={[12, 24]}>
                  <Col span={24}>
                    <Typography.Title level={2} style={{ textAlign: "center" }}>
                      Enter Verification Code
                    </Typography.Title>
                  </Col>
                  <Col span={24}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <OtpInput
                        numInputs={6}
                        value={activation}
                        isInputNum={true}
                        onChange={(res) => {
                          if (error) {
                            setError("");
                            dispatch(adminAction.resetAlert());
                          }
                          setActivation(res);
                        }}
                        separator={<span>-</span>}
                        inputStyle={{
                          width: "70px",
                          border: "1px solid #afaeae",
                          height: "50px",
                        }}
                      />
                      {error && (
                        <Text type="danger" style={{ marginTop: "10px" }}>
                          {error}
                        </Text>
                      )}
                    </div>
                  </Col>
                </Row>
              </Form.Item>

              <Form.Item style={{ textAlign: "center" }}>
                <Button
                  onClick={onForgetPasswordBack}
                  style={{ marginRight: "20px" }}
                >
                  Back
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  // className={styles.btnPrimary}
                  loading={verificationLoading}
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
          ) : (
            <>
              <div style={{ paddingBottom: "1rem 1rem 0rem 1rem" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    marginTop: "-45px",
                    marginBottom: "30px",
                  }}
                >
                  <img
                    className={styles.custFormLogo}
                    style={{ width: "65px", height: "auto" }}
                    src={"https://resureprojectbucket.s3.us-east-2.amazonaws.com/dev/649be3538168791298e1a3f0/RESURE_RESERVED/18resure-logo-short-blue-text.png"}
                    alt="Logo"
                  />
                </div>
                <h1 className={styles.custFormH1}>Forgot Password</h1>
                <h2 className={styles.custFormH2}>
                  You can reset your password here.
                </h2>
              </div>
              <Form
                layout="vertical"
                name="normal_login"
                className={styles.loginForm}
                initialValues={{
                  remember: true,
                  email: location && location.state && location.state.email,
                }}
                onFinish={onFinish}
                validateMessages={validateMessages}
              >
                <Form.Item
                  label="Email"
                  name="email"
                  // name="email"
                  rules={[
                    {
                      type: "email",
                      message: "Not a valid Email!",
                    },
                    {
                      required: true,
                      message: "Please Enter Email!",
                    },
                  ]}
                >
                  <Input
                    onChange={() => {
                      if (error) {
                        setError("");
                        dispatch(adminAction.resetAlert());
                      }
                    }}
                    prefix={
                      <UserOutlined
                        className="site-form-item-icon"
                        style={{ marginRight: "15px" }}
                      />
                    }
                    placeholder="Enter your Email"
                    allowClear
                  />
                </Form.Item>
                <div style={{ paddingBottom: "20px" }}>
                  {error && <Text type="danger">{error}</Text>}
                </div>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    // size="large"
                    className={styles.btnPrimary}
                    loading={userForgetPasswordLoading}
                  >
                    Reset my password
                  </Button>
                  <Row
                    justify="center"
                    style={{ marginTop: "10px", marginBottom: 0 }}
                  >
                    <Text type="secondary">
                      <Link to="/"> Back to login</Link>
                    </Text>
                  </Row>
                </Form.Item>
              </Form>
            </>
          )}
        </div>
      </Card>
    </div>
  );
}
export { ForgetPassword };

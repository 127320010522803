import React from 'react';
import { Result, Button } from "antd";
import dotEnv from "dotenv";
import { useLocation } from "react-router-dom";

dotEnv.config();
const apiURL = process.env.REACT_APP_WEB_BASE_URL;

const DeletedDocumentSuccessComponent = () => {
    const location = useLocation();
    const { deletedById } = location.state || {};
    const query = new URLSearchParams(window.location?.search);
    const isAgent = query?.get("isAgent") === "true";

    const homeHandle = async () => {
        if (isAgent) {
            window.location = `${apiURL}/login`;
        }
    };

  return (
    <div
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            backgroundColor: "#f0f5ff",
        }}
    >
        <div
            style={{
                textAlign: "center",
                backgroundColor: "#fff",
                padding: "30px 0",
                maxWidth: "650px",
                height: "50vh",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
            }}
        >
            <Result
                status="warning"
                title={
                    deletedById && (
                        <span style={{ fontSize: "18px" }}>
                            This document may have been deleted by <b>{deletedById?.fullName}</b> (<b>{deletedById?.email}</b>).
                        </span>
                    )
                }
                extra={
                    isAgent && deletedById && (
                        <Button type="primary" onClick={homeHandle} key="console">
                            Home
                        </Button>
                    )
                }
            />
        </div>
    </div>
);
};

export default DeletedDocumentSuccessComponent;

import { ndaConstants } from "./action-types";
import { ndaApi } from "../utils/api";
/**
 * To post NDA actions
 * @param {string} NDA
 */

const postNdaError = (error) => {
  return {
    type: ndaConstants.POST_NDA_FAILURE,
    ndaErrorData: error.message,
    ndaloading: false,
  };
};

const postNdaSuccess = (res) => {
  return {
    type: ndaConstants.POST_NDA_SUCCESS,
    ndaData: res.data.info,
    ndaloading: false,
  };
};

const postNda = (data, pdfurl, thumbnailUrl, id) => {
  return (dispatch, getState) => {
    ndaApi
      .postNdaRequest(data, pdfurl, thumbnailUrl, id)
      .then((response) => {
        dispatch(postNdaSuccess(response));
      })
      .catch((error) => {
        if (error.response) {
          dispatch(postNdaError(error.response.data));
        }
      });
  };
};

//Get NDA
const getNDAListError = (error) => {
  return {
    type: ndaConstants.GET_NDA_FAILURE,
    getNDAError: error.message,
    getNDALoading: true,
  };
};

const getNDAListSuccess = (response) => {
  return {
    type: ndaConstants.GET_NDA_SUCCESS,
    getNDAData: response.data.info.data,
    totalRecords:response.data.info.totalRecords,
    getNDALoading: false,
  };
};

const getNDAList = (query) => {
  return (dispatch, getState) => {
    ndaApi
      .getNDARequest(query)
      .then((response) => {
        dispatch(getNDAListSuccess(response));
      })
      .catch((error) => {
        if (error.response) {
          dispatch(getNDAListError(error.response.data));
        }
      });
  };
};

// Edit NDA Admin
const editNDAError = (error) => {
  return {
    type: ndaConstants.EDIT_NDA_FAILURE,
    editNDAError: error,
    editNDALoading: false,
  };
};

const editNDASuccess = (response) => {
  return {
    type: ndaConstants.EDIT_NDA_SUCCESS,
    editNDAData: response.data.info,
    editNDALoading: false,
  };
};

const editNDA = (data, id) => {
  return (dispatch, getState) => {
    ndaApi
      .editNDARequest(data, id)
      .then((response) => {
        dispatch(editNDASuccess(response));
        dispatch(getNDAList());
      })
      .catch((error) => {
        dispatch(editNDAError(error));
      });
  };
};
// Delete NDA Admin
const deleteNDAError = (error) => {
  return {
    type: ndaConstants.DELETE_NDA_FAILURE,
    deleteNDAError: error,
    deleteNDALoading: false,
  };
};

const deleteNDASuccess = (response) => {
  return {
    type: ndaConstants.DELETE_NDA_SUCCESS,
    deleteNDAData: response.data.info,
    deleteNDALoading: false,
  };
};

const deleteNDA = (data, flag) => {
  return (dispatch, getState) => {
    ndaApi
      .deleteNDARequest(data, flag)
      .then((response) => {
        dispatch(deleteNDASuccess(response));
        dispatch(getNDAList());
      })
      .catch((error) => {
        dispatch(deleteNDAError(error));
      });
  };
};
// Get NDA JSX
const getNDA_JSXError = (error) => {
  return {
    type: ndaConstants.NDA_JSX_FAILURE,
    getNDA_JSX_Error: error,
    getNDA_JSXLoading: false,
  };
};

const getNDA_JSXSuccess = (response) => {
  return {
    type: ndaConstants.NDA_JSX_SUCCESS,
    getNDA_jsx: response.data.info.contractJSX,
    getNDA_jsx_validation: response.data.info.frontendValidationObject,
    getNDA_JSXLoading: false,
  };
};

const getNDA_JSX = (id) => {
  return (dispatch, getState) => {
    ndaApi
      .getNDA_JSXRequest(id)
      .then((response) => {
        dispatch(getNDA_JSXSuccess(response));
      })
      .catch((error) => {
        dispatch(getNDA_JSXError(error));
      });
  };
};
// submitEmail
const submitEmailError = (error) => {
  return {
    type: ndaConstants.SUBMIT_EMAIL_FAILURE,
    submitEmailError: error,
    submitEmailLoading: false,
  };
};

const submitEmailSuccess = (response) => {
  return {
    type: ndaConstants.SUBMIT_EMAIL_SUCCESS,
    submitEmailData: response.data.info,
    submitEmailLoading: false,
  };
};

const submitEmail = (id, firstName, lastName) => {
  return (dispatch, getState) => {
    ndaApi
      .submitEmailRequest(id, firstName, lastName || "")
      .then((response) => {
        dispatch(submitEmailSuccess(response));
      })
      .catch((error) => {
        dispatch(submitEmailError(error));
      });
  };
};

// imageUpload
const uploadError = (error) => {
  return {
    type: ndaConstants.IMAGE_UPLOAD_PDF_FAILURE,
    uploadError: error,
    imageloading: true,
  };
};

const uploadSuccess = (response) => {
  return {
    type: ndaConstants.IMAGE_UPLOAD_PDF_SUCCESS,
    uploadData: response.data.info.fileData.fileUrl,
    imageloading: false,
  };
};

const uploadImageFile = (file) => {
  return (dispatch, getState) => {
    ndaApi
      .imageUploadRequest(file)
      .then((response) => {
        dispatch(uploadSuccess(response));
      })
      .catch((error) => {
        dispatch(uploadError(error));
      });
  };
};

// file Upload
const uploadpdfFileError = (error) => {
  return {
    type: ndaConstants.PDF_UPLOAD_PDF_FAILURE,
    pdfUploadError: error,
    pdfLoading: true,
  };
};

const uploadpdfFileSuccess = (response) => {
  return {
    type: ndaConstants.PDF_UPLOAD_PDF_SUCCESS,
    pdfUploadData: response.data.info.docUrl,
    pdfUploadthumbnail: response.data.info.thumbnailUrl,
    pdfLoading: false,
  };
};

const uploadpdfFile = (file, data, id) => {
  return (dispatch, getState) => {
    ndaApi
      .uploadpdfFileRequest(file)
      .then((response) => {
        dispatch(uploadpdfFileSuccess(response));
        dispatch(
          postNda(
            data,
            response.data.info.docUrl,
            response.data.info.thumbnailUrl,
            id
          )
        );
      })
      .catch((error) => {
        dispatch(uploadpdfFileError(error));
      });
  };
};

export const ndaHandle = {
  postNda,
  uploadImageFile,
  getNDAList,
  editNDA,
  deleteNDA,
  getNDA_JSX,
  submitEmail,
  uploadpdfFile,
};

import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { cmsDataHandle } from "./state/actions";
import { Layout} from "antd";
import { TablbeView } from "./components/TablbeView";
import { Redirect } from "react-router-dom";
import { roleAccessFunc } from "../Common/commondata/commonData";
const { Content } = Layout;

function CMS(props) {
  // useEffect(() => {
  //   if (props.networkConnection === false) {
  //     window.location.reload();
  //   }
  // }, [props]);
  const dispatch = useDispatch();
  const { personData } = useSelector((state) => state.cms);
  const { roleAccessed } = useSelector(
    (state) => state.account,
    shallowEqual
  );
  useEffect(() => {
    if(roleAccessFunc(roleAccessed, "USER")){
      dispatch(cmsDataHandle.getPersonsData());
    }
  }, []);

  if (!roleAccessFunc(roleAccessed, "USER")) {
    return <Redirect to="/error" />;
  }



  return (
    <Layout style={{ height: "100%", padding: "0px" }}>
      <Content
        style={{
          overflow: "auto",
        //   background: "rgb(202 202 202)",
        //   padding: "1rem",
        overflowY:"hidden",

        }}
      >
        <div
          style={{
            background: "rgb(245, 239, 239)",
            padding: "0",
            // minHeight: 380,
            overflow: "auto",
          }}
        >
          <TablbeView personData={personData} />
        </div>
      </Content>
    </Layout>
  );
}

export { CMS };

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { subscriptionMainHandlers } from "../state/action";
import {
  Button,
  Dropdown,
  Form,
  Menu,
  Modal,
  Table,
  DatePicker,
  Input,
  Tooltip,
  Typography,
} from "antd";
import { Loading } from "../../Common/components/Loading";
import { MoreOutlined, PlusCircleFilled } from "@ant-design/icons";
import moment from "moment";
import { useForm } from "antd/lib/form/Form";
import { v4 as uuidv4 } from "uuid";

const SpecialPlans = () => {
  const dispatch = useDispatch();
  const { plansDataLoading, plansData, emailLoading } = useSelector(
    (state) => state.subscriptionMainReducer,
  );
  const [isOpen, setisOpen] = useState({
    visible: false,
    data: {},
    tempId: "",
    addCode: false,
    codeId: "",
  });
  const [issendModal, setSendModal] = useState({ visible: false, data: {} });
  const [startDate, setStartDate] = useState("");
  const [form] = useForm();
  const [emailForm] = useForm();
  const disptach = useDispatch();

  const handleFinish = (values) => {
    if (isOpen?.addCode === false) {
      // to edit
      disptach(
        subscriptionMainHandlers.editSpecialPlans(
          isOpen?.tempId,
          values,
          isOpen?.addCode,
          isOpen?.codeId,
        ),
      );
    } else {
      // to create
      disptach(
        subscriptionMainHandlers.editSpecialPlans(
          isOpen?.tempId,
          values,
          isOpen?.addCode,
        ),
      );
    }
    form.resetFields();
    setisOpen({ visible: false, data: {} });
  };


  const handleClose = () => {
    setisOpen({ visible: false, data: {} });
    form.resetFields();
  };

  const handlecloseSendModal = () => {
    setSendModal({ visible: false, data: {} });
    emailForm.resetFields();
  };

  const MenuItem = ({ item, tempId }) => {
    return (
      <Menu size="small">
        <>
          <Menu.Item
            style={{
              fontWeight: "500",
              fontSize: "15px",
              display: "flex",
              alignItems: "center",
            }}
            key="editCreate"
            onClick={() =>
              setisOpen({
                visible: true,
                data: item,
                tempId,
                addCode: false,
                codeId: item?._id,
              })
            }
          >
            Edit
          </Menu.Item>
        </>
      </Menu>
    );
  };

  const subscriptionColumns = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      align: "center",
      minHeight:"300px",
    },
    {
      title: "YEARLY PRICE ($)",
      dataIndex: "yearlyPrice",
      key: "yearlyPrice",
      ellipsis: true,
      align: "center",
      minHeight:"300px",
      render: (text) => {
        const formattedPrice = `$ ${parseFloat(text).toFixed(2)}`;
        return <div style={{ minHeight: '300px', textAlign: 'center',display:"flex",flexDirection:"column",justifyContent:"center" }}>{formattedPrice}</div>;
      }
    },
    {
        title: "PRICE ID",
        dataIndex: "default_priceId",
        key: "default_priceId",
        align: "center",
        minHeight:"300px",
    },
    {
        title: "PRODUCT ID",
        dataIndex: "productId",
        key: "productId",
        align: "center",
        minHeight:"300px",

    },
    {
      title: "SPECIAL PLAN",
      dataIndex: "specialPlan",
      key: "specialPlan",
      width: "30%",
      ellipsis: true,
      align: "center",
      minHeight:"300px",
      render: (text, record) => (
        <>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Tooltip title="Create">
              <PlusCircleFilled
                onClick={() =>
                  setisOpen({
                    visible: true,
                    addCode: true,
                    tempId: record?._id,
                  })
                }
                style={{
                  fontSize: "20px",
                  fill: "#085191",
                  color: "#085191",
                  marginRight: "10px",
                }}
              />
            </Tooltip>
          </div>
          {text?.codeDetails?.map((code) => (
            <div
              style={{
                padding: "10px",
                border: "1px solid lightgrey",
                // lineHeight: "10px",
                marginTop: "5px",
                textAlign: "left",
              }}
            >
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Dropdown
                  trigger={["click", "hover"]}
                  overlay={<MenuItem item={code} tempId={record?._id} />}
                >
                  <MoreOutlined
                    style={{
                      fontSize: "18px",
                      cursor: "pointer",
                      color: "#085191",
                    }}
                  />
                </Dropdown>
              </div>
              <div>
                <strong>Code:</strong>
                <Typography.Text copyable style={detailDivStyle}>
                  {code?.code}
                </Typography.Text>
              </div>
              <div>
                <strong>Start Date:</strong>
                <span style={detailDivStyle}>
                  {moment(code?.startDate * 1000).format(
                    "MMMM D, YYYY [at] h:mm A",
                  )}
                </span>
              </div>
              <div>
                <strong>End Date:</strong>
                <span style={detailDivStyle}>
                  {moment(code?.endDate * 1000).format(
                    "MMMM D, YYYY [at] h:mm A",
                  )}
                </span>
              </div>
            </div>
          ))}
        </>
      ),
    },
  ];

  const detailDivStyle={
    marginLeft: "5px",
    color: "#085191",
    width:"10% !important",
    whiteSpace:"initial",
  }

  useEffect(() => {
    dispatch(subscriptionMainHandlers.getSpecialPlans());
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      form.setFieldsValue({
        startDate: isOpen?.data?.startDate
          ? moment(isOpen?.data?.startDate * 1000)
          : "",
        endDate: isOpen?.data?.endDate
          ? moment(isOpen?.data?.endDate * 1000)
          : "",
        code: isOpen?.data?.code ? isOpen?.data?.code : "",
      });
    }, 100);

    return () => clearTimeout(timer);
  }, [isOpen?.data, form]);

  const handleAutoGenerate = () => {
    form.setFieldsValue({
      code: uuidv4()
    });
  }

  return (
    <>
      {plansDataLoading ? (
        <div
          style={{
            height: "80vh",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Loading />
        </div>
      ) : (
        <>
          {/* Edit / Create Modal  */}
          <Modal
            footer={false}
            title={
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold",
                  color: "grey",
                }}
              >
                {isOpen?.addCode === true ? "CREATE" : "EDIT"} CODE
              </span>
            }
            visible={isOpen?.visible}
            onCancel={handleClose}
          >
            <Form
              form={form}
              layout="vertical"
              onFinish={handleFinish}
              style={{ width: "100%" }}
              colon={false}
              autoComplete="off"
            >
              <Form.Item  name="code" label={isOpen?.addCode ? <div style={{display: "flex"}}><div>Add Code</div><div onClick={handleAutoGenerate} style={{textDecoration: "underline", textDecorationColor: "blue", color: "blue", marginLeft: "270px", cursor: "pointer"}}>Auto generate code</div></div> : "Edit Code"}
                rules={[
                  { required: true, message: "Please add a code!" },
                ]}
              >
                <Input />
              </Form.Item>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Form.Item
                  name="startDate"
                  label="Select Start Date"
                  rules={[
                    { required: true, message: "Please select start date!" },
                  ]}
                >
                  <DatePicker
                    name="startDate"
                    format="MM-DD-YYYY"
                    onChange={(date) => setStartDate(date)}
                    disabledDate={(current) => {
                      return current && current < moment().startOf("day");
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="endDate"
                  label="Select End Date"
                  rules={[
                    { required: true, message: "Please select end date!" },
                  ]}
                >
                  <DatePicker
                    name="endDate"
                    // value={moment(isOpen?.data?.endDate, "YYYY-MM-DD")}
                    format="MM-DD-YYYY"
                    disabledDate={(current) => {
                      return (
                        startDate &&
                        current &&
                        current <= moment(startDate).startOf("day")
                      );
                    }}
                  />
                </Form.Item>
              </div>
              <Form.Item style={{ textAlign: "center" }}>
                <Button htmlType="submit" type="primary">
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Modal>
          <Table
            scroll={{ y: "75vh" }}
            bordered
            dataSource={plansData}
            columns={subscriptionColumns}
            rowClassName="editable-row"
            pagination={false}
          />
        </>
      )}
    </>
  );
};

export default SpecialPlans;

import { Collapse, Input, Popconfirm, Spin,Pagination} from 'antd';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { helpApis } from './state/actions';
import { EmptyData } from '../Common/components/EmptyData';
import { HelpCreateModal } from './components/HelpCreateModal';
import {SearchOutlined,PlusCircleFilled,DeleteOutlined,FormOutlined,PlayCircleTwoTone} from "@ant-design/icons";
import { useForm } from 'antd/lib/form/Form';
import VideoModal from './components/VideoModal';
import HtmlContentWithClick from './components/HtmlContentWithClick';
const AdminHelp = () => {
    const { Panel } = Collapse;
    const dispatch = useDispatch();
    const {helpTopicData,helpTopicDataLoading,totalRecords} = useSelector(state=>state.helpTopics);
    const [isModalOpen, setIsModalOpen] = useState({isOpen: false,action: "create"});
    const [showVideo, setShowVideo] = useState(false)
    const [data, setData] = useState([]);
    const [form] = useForm();
    const [page,setPage]=useState(1)
    const [limit,setLimit]=useState(10)
    const [search,setSearch]=useState('');
    const debounce= useDebounce(search,1000)
    const showCreateModal = () => {
      setIsModalOpen({isOpen: true,action: "create"});
      setData([]);
    };

    const handleCancel = (clearData) => {
      setIsModalOpen({isModalOpen:false,action: "cancel"});
      form.resetFields();
      setData([]);
      if(clearData){
        clearData();
      }
      dispatch(helpApis.handleResetImages());
    };

    const handleVideoCancel =()=>{
        setShowVideo(false);
    }

    function useDebounce(value, delay) {
      const [debouncedValue, setDebouncedValue] = useState(value);
    
      useEffect(() => {
        const timeoutId = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
        return () => clearTimeout(timeoutId);
      }, [value, delay]);
    
      return debouncedValue;
    }
  // Debounce function definition
  // function debounce(func, delay) {
  //   let timeoutId;

  //   return function (...args) {
  //     clearTimeout(timeoutId);

  //     timeoutId = setTimeout(() => {
  //       func.apply(this, args);
  //     }, delay);
  //   };
  // }

  // Original search function
  // const performSearch = (query,page,limit) => {
   
  //   dispatch(helpApis.helpSearchApi(query, page, limit));
  // };

  // Debounced search function
  // const debouncedPerformSearch = debounce(performSearch, 1000);

  // Event handler for input changes
  // const handleSearch = (event) => {
  //   const query = event.target.value;
    // debouncedPerformSearch(query);
  // };

  //Edit Questions
   const handleEdit=(el)=>{
       setData(el);
       setIsModalOpen({isOpen: true,action: "edit"});
   };

  //Delete questions
  const handeDelete =(id)=>{
    dispatch(helpApis.deleteHelpData(id))
  }

  const handlePagination=(page)=>{
    setPage(page);
  }
  const handleSearch = (e)=>{
    setSearch(e.target.value);
    setPage(1);
  }
  
  useEffect(() => {
    dispatch(helpApis.helpApiCall(null, page, limit,debounce));
  }, [debounce, page, limit]);
  

 
  return (
    <div style={{background:"white",height:"100%",overflow:"auto"}}>
        {/* ---------------Header part----------------- */}
        <div style={{display:"flex",paddingTop:"10px"}}>
        <div style={{display:"flex",width:"500px",justifyContent:"space-between",margin:"auto"}}>
            <Input allowClear width={'400px'} prefix={<SearchOutlined  />} onChange={handleSearch} placeholder='Type in keyword to search'  />
        </div>
        <div>
          <PlusCircleFilled
                  style={{
                    fontSize: "30px",
                    fill: "#085191",
                    color: "#085191",
                    marginRight:"150px",
                  }}
                  onClick={showCreateModal}
                />
        </div>
        </div>
        {/* ------------------Accordian part------------- */}
        <HelpCreateModal isModalOpen={isModalOpen} handleCancel={handleCancel} data={data} form={form} setData={setData} page={page}/>
        <div style={{width:"95%",height: "410px",margin:"20px auto",overflow:"auto"}}>
        {helpTopicDataLoading ? <div style={{textAlign:"center",paddingTop:"20%"}}><Spin size='large' /></div> :
         <div style={{border:"1px solid #d3d3d3"}}> 
          <Collapse accordion bordered={true} >
            {helpTopicData && helpTopicData.length > 0 ? helpTopicData?.map((el, ind) => (
                <Panel style={{textAlign:"left",fontWeight:"bold",}}
                header={<div style={{display:"flex",flexDirection:"row"}}>
                        <div style={{width:"90%",marginLeft:"20px"}}>{el?.title}</div>
                        <div style={{display:"flex"}}>
                        <div>
                        <FormOutlined onClick={(e)=>{e.stopPropagation(); handleEdit(el)}} style={{color:"green",marginLeft:"15px",fontSize:"18px"}}/>
                        </div>
                        <div
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                            >
                              <Popconfirm
                                 title="Are you sure you want to delete this question?"
                                 onConfirm={() => {
                                     handeDelete(el._id);
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                    >
                              <DeleteOutlined  style={{ fontSize: "18px",color:"red",marginLeft:"10px" }} />
                             </Popconfirm>
                        </div>
                        </div>
                        </div>}
                        key={ind.toString()}>
                     {el?.videos && el.videos.length > 0 ?
                    <VideoModal el={el} showVideo={showVideo} handleVideoCancel={handleVideoCancel}/>
                    : null}
                    <span>
                    <div style={{position:"relative",left:"90%"}}>
                        {el?.videos && el?.videos?.length > 0 ?
                        <PlayCircleTwoTone style={{fontSize:'30px',marginLeft:"10px"}} onClick={(e) => {e.stopPropagation(); setShowVideo(true)}}/>
                        : <></>}
                        </div>
                        <HtmlContentWithClick htmlContent={el?.content} />
                    </span>
              </Panel>
            )) : <div style={{marginTop:"20%"}}><EmptyData/></div>}
          </Collapse>
          </div>}
        </div>
        <div style={{marginTop:"60px"}}>
        {helpTopicData && helpTopicData.length > 0 && (
        <Pagination
          style={{ textAlign: "center", width: "auto"}}
          size="medium"
          defaultCurrent={page}
          onChange={handlePagination}
          total={totalRecords}
          pageSize={limit}
        />
      )}
        </div>
        
    </div>
  )
}

export  {AdminHelp};

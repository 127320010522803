import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
  FilterFilled,
} from "@ant-design/icons";
import { Button, Input, Modal, Select, Space, Table } from "antd";
import { officeTypeHandle } from "../state/actions";
import { Loading } from "../../Common";
import Highlighter from "react-highlight-words";
import { firmDetailsHandle } from "../../FirmCreation/state/action";
const { confirm } = Modal;

export default function Accessed() {
  const dispatch = useDispatch();
  const [searchText, setsearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentQuery, setCurrentQuery] = useState({});
  const [roles, setRoles] = useState([]);
  const [searchedColumn, setsearchedColumn] = useState("");
  const [selectedFilterValue, setSelectedFilterValue] = useState(null);
  const [disableBtn, setdisableBtn] = useState(true)
  const searchInput = useRef();
  const { personsData, totalfirmAccessRecords, personsLoading } = useSelector(
    (state) => state.officeType
  );

  useEffect(() => {
    let query = {
      ...currentQuery,
      page: currentPage,
      limit: 20,
    };
    dispatch(officeTypeHandle.getAllPersons(query));
  }, [currentPage]);

  const handlePersonUpdate = (ele) => {
    confirm({
      title: "Are you sure you want to Revoke Access?",
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      content: "Note: Status cannot be changed once access is revoked.",
      onOk() {
        dispatch(
          firmDetailsHandle.editBrokeragedetails({
            revokeBrokeragePerson: ele?.id
          })
        );
      },
      onCancel() {},
    });
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setsearchText(selectedKeys?.[0]);
    let query = {
        page: 1,
        limit: 20,
      };
    if(dataIndex==='franchise'){
        query.franchiseSearch =selectedKeys?.[0];
    }else if(dataIndex==='brokerage'){
        query.brokerageSearch = selectedKeys?.[0]
    }else if(dataIndex==='office'){
        query.officeSearch = selectedKeys?.[0]
    }
    else{
     query.search = selectedKeys?.[0];
}
    dispatch(officeTypeHandle.getAllPersons(query));
    setsearchedColumn(dataIndex);
    setCurrentQuery(query);
    setCurrentPage(1);
  };
  const handleReset = (clearFilters, setSelectedKeys) => {
    clearFilters();
    setSelectedKeys("");
    let query = {
      page: 1,
      limit: 20,
    };
    dispatch(officeTypeHandle.getAllPersons(query));
    setsearchText("");
  };
  const handleResetfilters = () => {
    let query = {
      page: 1,
      limit: 20,
    };
    dispatch(officeTypeHandle.getAllPersons(query));
    setsearchText("");
  };

  let getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput.current = node;
          }}
          placeholder={
            dataIndex === 'franchise'
                ? 'Search by Franchise Name'
                : dataIndex === 'brokerage'
                ? 'Search by Brokerage Name'
                : dataIndex === 'office'
                ? 'Search by Office Name'
                : 'Search in fullName and email'
        }
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => {handleSearch(selectedKeys, confirm, dataIndex);setdisableBtn(false)}}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {handleSearch(selectedKeys, confirm, dataIndex);setdisableBtn(false)}}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {handleReset(clearFilters, setSelectedKeys);setdisableBtn(true)}}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    render: (text) => {
     const fullName = text?.fullName ? text?.fullName.toString() : "";
      const email = text?.email ? text?.email.toString() : "";
      return searchedColumn === dataIndex ? (
        <>
          <div>
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={fullName}
            />
          </div>
          <div style={{ fontSize: "11px", color: "#787474" }}>
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={email}
            />
          </div>
        </>
      ) : (
        <>
          <div>{text?.fullName ? text?.fullName : "-"}</div>
          <div style={{ fontSize: "11px", color: "#787474" }}>
            {text?.email ? `${text?.email}` : ""}
          </div>
        </>
      );
    },
  });

  //filter out status
  let getFilterStatus = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <div style={{marginBottom:"10px"}}>
        <Select
                style={{ width: "100%" }}
                placeholder="Select Status"
                onChange={(role, event) => {
                    setSelectedFilterValue(role)
                }}
                options={[
                    {
                      value: "VERIFIED",
                      label: "VERIFIED",
                    },
                    {
                      value: "NOT-VERIFIED",
                      label: "NOT-VERIFIED",
                    },
                ]}
              />
              </div>
        <Space>
          <Button
            type="primary"
            onClick={()=>{dispatch(officeTypeHandle.getFilterStatus(selectedFilterValue,true));setdisableBtn(false)}}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {handleReset(clearFilters, setSelectedKeys);setdisableBtn(false)}}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterFilled style={{ color: filtered ? "#1890ff" : undefined }} />
    ),

    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  return (
    <>
      {personsLoading ? (
        <Loading />
      ) : (
        <>
        <div style={{position:"absolute",top:"65px",zIndex:"111",left :'207px'}}>
         <Button disabled={disableBtn} onClick={()=>{handleResetfilters();setdisableBtn(true)}} size="small" style={{marginLeft:"10px"}}>Reset Filters</Button>
         </div>
        <Table
          className="custom-table"
          scroll={{ y: "70vh" }}
          columns={[
            {
              title: "ACCESSED BY",
              dataIndex: "accessedBy",
              key: "accessedBy",
              width: "35%",
              ...getColumnSearchProps("accessedBy"),
            },
            // {
            //   title: "STATUS",
            //   dataIndex: "status",
            //   key: "status",
            //   width: "15%",
            //   ...getFilterStatus('Status'),
            //   render: (isVerified) => (
            //     <div>
            //       {isVerified ? (
            //         <div
            //           style={{
            //             display: "flex",
            //             gap: "5px",
            //             alignItems: "center",
            //           }}
            //         >
            //           <CheckCircleOutlined
            //             style={{ color: "#4bd33a", fontSize: "18px" }}
            //           />
            //           Has Access
            //         </div>
            //       ) : (
            //         <div
            //           style={{
            //             display: "flex",
            //             gap: "5px",
            //             alignItems: "center",
            //           }}
            //         >
            //           <CloseCircleOutlined
            //             style={{ color: "#e73939", fontSize: "18px" }}
            //           />
            //           No Access
            //         </div>
            //       )}
            //     </div>
            //   ),
            // },
            {
              title: "BROKERAGE",
              dataIndex: "brokerage",
              key: "brokerage",
              width: "40%",
              // ...getColumnSearchProps('brokerage'),
              render: (text,ele) => (
                <div>
                  <div>{text?.joinedBrokerageData?.name ? text?.joinedBrokerageData?.name : "-"}</div>
                  {/* <div style={{ fontSize: "11px", color: "#787474" }}>
                    {text?.brokerageAddress ? `Address: ${text?.brokerageAddress}` : ""}
                  </div> */}
                </div>
              ),
            },
            // {
            //   title: "FRANCHISE",
            //   dataIndex: "franchise",
            //   key: "franchise",
            //   width: "22%",
            //   ...getColumnSearchProps("franchise"),
            //   render: (text) => (
            //     <div>
            //       <div>{text?.name ? text?.name : "-"}</div>
            //       <div style={{ fontSize: "11px", color: "#787474" }}>
            //         {text?.address ? `Address: ${text?.address}` : ""}
            //       </div>
            //     </div>
            //   ),
            // },
            // {
            //   title: "OFFICE",
            //   dataIndex: "office",
            //   key: "office",
            //   width: "22%",
            //   ...getColumnSearchProps('office'),
            //   render: (text) => (
            //     <div>
            //       <div>{text?.name ? text?.name : "-"}</div>
            //       <div style={{ fontSize: "11px", color: "#787474" }}>
            //         {text?.address ? `Address: ${text?.address}` : ""}
            //       </div>
            //     </div>
            //   ),
            // },
            {
              title: "UPDATE",
              dataIndex: "update",
              key: "update",
              render: (isVerified, ele) => (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {/* {isVerified ? (
                    <CheckCircleOutlined
                      style={{ color: "#4bd33a", fontSize: "18px" }}
                    />
                  ) : ( */}
                    <button
                      style={{ cursor: "pointer" }}
                      onClick={() => handlePersonUpdate(ele)}
                    >
                      Revoke Access
                    </button>
                  {/* )} */}
                </div>
              ),
            },
          ]}
          dataSource={personsData}
          bordered
          pagination={{
            position: ["bottomCenter"],
            showSizeChanger: false,
            pageSize: 20,
            current: currentPage,
            total: totalfirmAccessRecords,
            onChange: (page) => {
              setCurrentPage(page);
            },
          }}
        />
        </>
      )}
    </>
  );
}

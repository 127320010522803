import React from "react";
import { useState, useEffect } from "react";
import { Select } from "antd";
import { offerTableHandle } from "../state/actions";
import { useDispatch, useSelector } from "react-redux";
import DraggableModal from "./DraggableModal";
import { Loading } from "../../Common/components/Loading";

const AddCriteria = ({ addCriteriaModalOpen, setAddCriteriaModalOpen, sort, order }) => {
  const { offerTableAuthKey, getConstantsLoading, offerTablePropertyId, OTAddCriteriaKeysArray, alreadyPresentCriteria } = useSelector((state) => state.offerTable);
  const [selectedItems, setSelectedItems] = useState([]);


  let filteredOptions = [];
  if (OTAddCriteriaKeysArray) {
    filteredOptions = OTAddCriteriaKeysArray?.filter((el) => !selectedItems?.includes(el));
  }

  const dispatch = useDispatch();


  useEffect(() => {
    if (alreadyPresentCriteria) {
      setSelectedItems(alreadyPresentCriteria);
    }
  }, [alreadyPresentCriteria]);

  const handleCriteriaCancel = () => {
    setAddCriteriaModalOpen(false);
  };

  const handleChange = (value) => {
    setSelectedItems(value);
  };

  const handleCritiraOk = () => {
    setAddCriteriaModalOpen(false);
    let ordernew = order ? order : "ASC";
    dispatch(offerTableHandle.getOfferTable(offerTableAuthKey, offerTablePropertyId, sort, ordernew, selectedItems));
  };

  
  return (
    <DraggableModal title={"Add Criteria"} visible={addCriteriaModalOpen} style={{height:"200px", overflow:"auto"}} onOk={handleCritiraOk} onCancel={handleCriteriaCancel}>
      {getConstantsLoading ? (
        <Loading />
      ) : (
        <Select
          style={{ minWidth: "100%" }}
          placeholder="Add Criteria"
          showArrow
          mode="multiple"
          options={filteredOptions?.map((item) => {
            return {
              value: item,
              label: item,
            };
          })}
          onChange={handleChange}
          value={selectedItems}
        />
      )}
    </DraggableModal>
  );
};

export default AddCriteria;

/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input } from "antd";
import React, { useMemo, useRef, useState } from "react";
import { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { getStreetAddress } from "../utils/universalFuntions";
import { useMediaQuery } from "react-responsive";

const SubjectLine = ({ form, data, source, clientDocs, selectedData, rootDocData, propertyAddress }) => {
  const { URLofferTableJSON } = useSelector((state) => state.offerTable);
  const { sharedDocs } = useSelector((state) => state.transactionReport);
  const { propertyDocs, currentUrlId } = useSelector((state) => state.createOffer, shallowEqual);
  const { contactSheetData } = useSelector((state) => state.contactSheetReducer);

  const [documentName, setDocumentName] = useState("");
  const inputChanged = useRef(false);

  const localData = JSON.parse(localStorage.getItem(currentUrlId) || "{}") || {};
  const clientDocuments = ["DWR", "BDS", "BDB", "ERTB", "ERTL", "ETCT", "LCAE", "LCAES_ETCT", "LCAES_ERTL"];
  const isClientDoc = clientDocuments.includes(rootDocData?.contractType);

  const address = source === "TRANSACTION_CALENDAR" ? propertyAddress : source === "CONTACT_SHEET" ? contactSheetData?.propertyAddress : URLofferTableJSON?.fullAddress || localData?.FullAddress;

  const streetAddress = useMemo(() => getStreetAddress(address), [address]);
  const breakPoint = useMediaQuery({ maxWidth: "573px" });
  const getDocumentNames = (docNames, selectedDocArray, dataArray, tab) => {
    for (let i = 0; i < selectedDocArray?.length; i++) {
      for (let j = 0; j < dataArray?.length; j++) {
        if (tab?.length) {
          if (tab === "SHARED" && selectedDocArray[i] === dataArray[j]?._id) {
            docNames.push(dataArray[j]?.fileName);
          } else if (tab === "CLIENTS" && selectedDocArray[i].documentId === dataArray[j]?._id) {
            docNames.push(dataArray[j]?.contractName);
          } else if (dataArray[j]?.contractId && selectedDocArray[i] === dataArray[j]?.documentId) {
            docNames.push(dataArray[j]?.documentName);
          } else if (selectedDocArray[i] === dataArray[j]?._id) {
            docNames.push(dataArray[j]?.fileName);
          }
        } else if (dataArray[j]?.documentId && selectedDocArray[i] === dataArray[j]?.documentId) {
          docNames.push(dataArray[j]?.documentName);
        } else if (selectedDocArray[i] === dataArray[j]?._id) {
          docNames.push(dataArray[j]?.fileName);
        }
      }
    }
  };

  useEffect(() => {
    if (!inputChanged.current) {
      const docNamesArray = [];
      if (data?.propertyDocsSelected) getDocumentNames(docNamesArray, data.propertyDocsSelected, propertyDocs);
      if (data?.sharedSelected) getDocumentNames(docNamesArray, data.sharedSelected, sharedDocs, "SHARED");
      if (data?.documentWithoutBuyerSelected) getDocumentNames(docNamesArray, data.documentWithoutBuyerSelected, propertyDocs, "WithoutBuyers");
      if (isClientDoc && data?.clientDocumentSelected) getDocumentNames(docNamesArray, data.clientDocumentSelected, clientDocs, "CLIENTS");
      setDocumentName(docNamesArray.length ? (isClientDoc ? (docNamesArray.length === 1 ? docNamesArray[0]?.trim() : "Document Links") : docNamesArray.length === 1 ? `: ${docNamesArray[0]?.trim()}` : ": Document Links") : "");
    }
  }, [data, propertyDocs, sharedDocs, clientDocs, isClientDoc]);

  useEffect(() => {
    let subject = "";
    if (source === "SEND_EMAIL_BUYER_AGENT") {
      subject = (streetAddress ? streetAddress + ": " : "") + "Offer Update";
    } else if (source === "SHARE_INDIVIDUAL_OFFER") {
      subject = (streetAddress ? streetAddress + ": " : "") + "Offer";
    } else if (source === "SHARE_OMD") {
      subject = (streetAddress ? streetAddress + ": " : "") + "Offers";
    } else if (isClientDoc) {
      subject = documentName || "";
    } else {
      subject = (streetAddress || "") + (documentName ? `${documentName}` : "");
    }
    form.setFieldsValue({
      subject: subject,
    });
  }, [documentName, streetAddress, isClientDoc, source]);

  return (
    <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
      <Form.Item label={<span style={{ fontWeight: "bold", color: "grey", fontSize: breakPoint?"14px":"17px" }}>Subject</span>} name="subject">
        <Input onChange={() => (inputChanged.current = true)} />
      </Form.Item>
    </Form>
  );
};

export default SubjectLine;

import { Button, Modal, Select, Form, message } from 'antd';
import React from 'react';
import { useEffect } from 'react';
import styles from "../styles/FilterContacts.module.css"

const FilterModalContacts = ({clientType, setClientType, form, getRelatorCustomerList, setClientCount, isFilterArchived }) => {
  const roleHashTags = ["TITLE REP", "BUYER", "SELLER", "STAGER", "MORTGAGE", "CUSTOMER", "PHOTOGRAPHER", "PROPERTY INSPECTOR"];

  const handleApplyButtonClick = () => {
    setClientType({...clientType, filtersApplied:true,openModal:false})
    getRelatorCustomerList(1,{},false);
    setClientCount(16);
  };

  const handleClearAllButtonClick = () => {
    form?.resetFields();
    getRelatorCustomerList(1,{},false);
    setClientType({...clientType, filtersApplied:false, isClearAllDisabled: true, openModal:false})
  };

  const handleModalCancel = () => {
    if(clientType?.filterNotApplied){
        setClientType({...clientType, isClearAllDisabled: true})
        form?.resetFields();
      }
    setClientType({...clientType, openModal:false})
  };

  useEffect(() => {
    const values = form?.getFieldsValue() || {};
    const { selectedRoles, selectedSortBy, selectedSortOrder, filterStatus } = values;

    if (!selectedRoles?.length && !selectedSortBy && !selectedSortOrder && filterStatus === "ACTIVE" && isFilterArchived === "ACTIVE") {
      setClientType({...clientType, filtersApplied:false, isClearAllDisabled: true})
    }
  },[isFilterArchived])

  return (
    <Modal
      footer={null}
      title={
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            color: 'rgb(61, 124, 183)',
            textDecoration: 'underline'
          }}
        >
          FILTER CONTACTS
        </span>
      }
      visible={clientType?.openModal}
      onCancel={handleModalCancel}
    >
      <Form
        form={form}
        initialValues={{
          filterStatus: "ACTIVE",
        }}
        onValuesChange={() => setClientType({...clientType,isClearAllDisabled:false})}
      >
        <div className={styles.filterModal}>
          <div>
            <h4>Status</h4>
            <Form.Item name="filterStatus">
              <Select
                style={{ width: "100%" }}
                placeholder="Select Status"
                options={[
                  { value: 'ACTIVE', label: 'Active' },
                  { value: 'ARCHIVE', label: 'Archived' },
                ]}
              />
            </Form.Item>
          </div>

          <div>
            <h4>Hashtag</h4>
            <Form.Item name="selectedRoles">
              <Select
                style={{ width: "100%" }}
                mode="multiple"
                placeholder="Select Hashtag"
              >
                {roleHashTags?.map((role) => (
                  <Select.Option key={role} value={role}>
                    {role}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div>
            <h4>Sort By</h4>
            <Form.Item name="selectedSortBy">
              <Select
                style={{ width: "100%" }}
                placeholder="Select sorting criteria"
              >
                <Select.Option value="createdAt">Created At</Select.Option>
                <Select.Option value="updatedAt">Updated At</Select.Option>
                <Select.Option value="fullName">Full Name</Select.Option>
              </Select>
            </Form.Item>
          </div>

          <div>
            <h4>Order</h4>
            <Form.Item name="selectedSortOrder">
              <Select
                style={{ width: "100%" }}
                placeholder="Select sorting order"
              >
                <Select.Option value="ASC">Ascending</Select.Option>
                <Select.Option value="DESC">Descending</Select.Option>
              </Select>
            </Form.Item>
          </div>
        </div>

        <div style={{ marginTop: '16px', textAlign: 'right' }}>
          <hr />
          <Button
            style={{ marginLeft: "10px" }}
            type="primary"
            onClick={handleClearAllButtonClick}
            disabled={clientType?.isClearAllDisabled}
          >
            Clear All
          </Button>
          <Button
            style={{ marginLeft: "10px" }}
            type="primary"
            onClick={handleApplyButtonClick}
            disabled={clientType?.isClearAllDisabled}
          >
            Apply
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default FilterModalContacts;

import axios from "axios";
const apiURL = process.env.REACT_APP_BASE_URL;

//Post newsletter api
const postNewsDataApi = (data,fileType,heading) => {
    const formData = new FormData();
        formData.append('file', data[0]);
        formData.append('fileType', fileType);
        formData.append('heading', heading);
    let accessTokan = localStorage.getItem("auth");
    return axios.post(`${apiURL}/newsLetter`,formData,{
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    })
  }

  //Get newsLetter api
const getNewsDataApi = () => {
    let accessTokan = localStorage.getItem("auth");
    return axios.get(`${apiURL}/newsLetter`,{
        params:{page:1,limit:10,fromAdmin:true},
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    })
  }

   //Delete newsLetter api
  const deleteNewsDataApi = (id) => {
    let accessTokan = localStorage.getItem("auth");
    return axios.delete(`${apiURL}/newsLetter/${id}`,{
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    })
  }

  // Upload Pdf
// const uploadPdfRequestApi = (data, origin) => {

//   let accessTokan = localStorage.getItem("auth");
//     return axios.post(
//       `${apiURL}/common/uploadDocsBase64`,
//       new URLSearchParams({
//         fileBase64: data,
//         fileType: "IMAGE",
//         folderOf: "PDF_DOC",
//       }),
//       {
//         headers: {
//           accept: "application/json",
//           authorization: `bearer ${accessTokan}`,
//         },
//       }
//     );
// };

export const newsDataApis={
    postNewsDataApi,
    getNewsDataApi,
    deleteNewsDataApi,
    // uploadPdfRequestApi,
}

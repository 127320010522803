import { Menu } from "antd";
import { IoSendSharp } from "react-icons/io5";
import { EyeInvisibleFilled, EyeFilled } from "@ant-design/icons";

export const MenuItem = ({ showhidden, isMenuOpen, handleOmdMenu }) => {
  return (
    <Menu onClick={handleOmdMenu} size="large">
      {isMenuOpen && (
        <>
          <Menu.Item
            style={{
              color: "#000000",
              fontWeight: "500",
              fontSize: "16px",
            }}
            icon={
              <IoSendSharp
                style={{
                  color: "#0036F4",
                  fontSize: "20px",
                }}
              />
            }
            key="SHARE_OMD"
          >
            Send Offer Management Dashboard
          </Menu.Item>
          <Menu.Item
            style={{
              color: "#000000",
              fontWeight: "500",
              fontSize: "16px",
            }}
            icon={
              <IoSendSharp
                style={{
                  color: "#0036F4",
                  fontSize: "20px",
                }}
              />
            }
            key="SEND_EMAIL_BUYER_AGENT"
          >
            Send Email To All Buyer Agents
          </Menu.Item>
          <Menu.Item
            style={{
              color: "#000000",
              fontWeight: "500",
              fontSize: "16px",
              alignItems: "center",
              display: "flex",
              marginBottom: "20px",
            }}
            key="HIDDEN_OFFERS"
            icon={
              showhidden ? (
                <EyeInvisibleFilled
                  style={{
                    color: "#0036F4",
                    fontSize: "20px",
                  }}
                />
              ) : (
                <EyeFilled
                  style={{
                    color: "#0036F4",
                    fontSize: "20px",
                  }}
                />
              )
            }
          >
            {/* <Checkbox onChange={handleClick2} style={{marginRight:"5px"}} checked={showhidden}/> */}
            {!showhidden ? <text>Show Hidden Offers</text> : <text>Hide Offers</text>}
          </Menu.Item>
        </>
      )}
    </Menu>
  );
};

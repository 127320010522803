import { subscriptionConstants } from "./actionTypes";


const initialState = {
  getSubscriptionUsersLoading:false,
  getSubscriptionUsersData: [],
  totalRecords: null,
  viewCustomerPortalError:false
};

const subscription = (state = initialState, action) => {
  switch (action.type) {
    // Get Profile Details
    case subscriptionConstants.GET_USERS_LOADING:
      return {
        ...state,
        getSubscriptionUsersLoading: true,
        getSubscriptionUsersData: [],
        totalRecords: null,
      };
    case subscriptionConstants.GET_USERS_SUCCESS:
      return {
        ...state,
        getSubscriptionUsersLoading: false,
        getSubscriptionUsersData: action.payload,
        totalRecords: action.totalRecords,
      };
    case subscriptionConstants.GET_USERS_FAILURE:
      return {
        ...state,
        getSubscriptionUsersData: [],
        totalRecords: null,
      };
      case subscriptionConstants.VIEW_CUSTOMER_PORTAL_LOADING:
        return {
          ...state,
          getSubscriptionUsersLoading: true,
        };
      case subscriptionConstants.VIEW_CUSTOMER_PORTAL_SUCCESS:
        return {
          ...state,
          viewCustomerPortalError: null,
        };
      case subscriptionConstants.VIEW_CUSTOMER_PORTAL_FAILURE:
        return {
          ...state,
          getSubscriptionUsersLoading: true,
          viewCustomerPortalError:false,
        };
    default:
      return state;
  }
};
export { subscription };

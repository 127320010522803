import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cmsDataHandle } from "../state/actions";
import { EmailDetails } from "../../Common/components/EmailDetails";
import { Badge, Card, Col, Dropdown, Form, Menu, Modal, Popconfirm, Row, Tag } from "antd";
import { EditTwoTone } from "@ant-design/icons";
import { MoreOutlined } from "@ant-design/icons";
const EmailDetailsCol = ({ record, currentPage }) => {
  const [emailModalVisible, setEmailModalVisible] = useState();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { adminRole } = useSelector((state) => state.account);

  // Email Verification
  let handleConfirm = ({ record, key }) => {
    if (key?.key === "sendVerificationEmail") {
      let payload = { sendEmailVerificationLink: true };
      dispatch(cmsDataHandle.sendOrVerifyEmail({ id: record?._id, payload, currentPage }));
    } else {
      let payload = { isEmailVerified: true };
      dispatch(cmsDataHandle.sendOrVerifyEmail({ id: record?._id, payload, currentPage }));
    }
  };
  const handleOpenEmailModal = () => {
    setEmailModalVisible(true);
    form?.setFieldsValue({ email: record?.email || "" });
  };
  const handleAddEmail = (values) => {
    let payload = { email: values?.email?.toLowerCase() };
    dispatch(cmsDataHandle.sendOrVerifyEmail({ id: record?._id, payload, currentPage }));
    handleCancelModal();
  };
  const handleCancelModal = () => {
    setEmailModalVisible(false);
    form?.resetFields();
  };
  return (
    <>
      {emailModalVisible && (
        <Modal visible={emailModalVisible} footer={false} title={"Email Details"} onCancel={handleCancelModal}>
          <EmailDetails onFinish={handleAddEmail} data={record} form={form} />
        </Modal>
      )}
      <Badge.Ribbon
        key={record?._id}
        text={
          record?.isEmailVerified ? (
            "Verified"
          ) : (
            <Row gutter={[12, 12]}>
              <Col span={18}>Not Verified</Col>
              <Col span={6} key={record?._id}>
                <Dropdown
                  trigger={["click", "hover"]}
                  overlay={
                    <Menu size="small">
                      <Menu.Item
                        style={{
                          fontWeight: "500",
                          fontSize: "15px",
                          display: "flex",
                          alignItems: "center",
                        }}
                        key="verifyEmail"
                      >
                        <Popconfirm
                          key={record.email}
                          title={
                            <>
                              Are you sure to verify{"  "}
                              <Tag color="processing">{record?.fullName}</Tag>?
                            </>
                          }
                          onConfirm={() => handleConfirm({ record })}
                        >
                          Verify Email
                        </Popconfirm>
                      </Menu.Item>
                      <Menu.Item
                        style={{
                          fontWeight: "500",
                          fontSize: "15px",
                          display: "flex",
                          alignItems: "center",
                        }}
                        key="sendVerificationEmail"
                        onClick={(key) => {
                          handleConfirm({ record, key });
                        }}
                      >
                        Send Email to verify
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <MoreOutlined
                    style={{
                      fontSize: "16px",
                      fill: "#085191",
                      color: "white",
                      fontWeight: 800,
                    }}
                  />
                </Dropdown>
              </Col>
            </Row>
          )
        }
        color={record?.isEmailVerified ? "#52c41a" : "#f50"}
        placement={record?.isEmailVerified ? "start" : "end"}
      >
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: "1rem" }}>
          <Card>{record?.email}</Card>
          {Array.isArray(adminRole) && adminRole.includes("SUPER_ADMIN") && (
            <p onClick={handleOpenEmailModal} style={{ cursor: "pointer", marginTop: "13px" }}>
              <EditTwoTone />
            </p>
          )}
        </div>
      </Badge.Ribbon>
    </>
  );
};

export default EmailDetailsCol;

import React, { useEffect, useState } from "react";
import { List, Avatar, Input, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import styles from "../styles/FranchiseList.module.css";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "../../Common/components/Loading";
import { firmDetailsHandle } from "../../FirmCreation/state/action";
import { formatAddress } from "../../Common/utils/ExtraFunctions";
const { Title } = Typography;
const FranchiseList = ({
  selectClient,
  dataSource,
  currentTag,
  setDataSource,
  searchFranchiseText,
  searchFirmText,
  setSearchFirmText
}) => {
  const { getBrokrageError,
    getBrokrageLoading,
    getBrokrageData,
    getFranchiseData,
    getOfficeLoading,
    getOfficeError,
    getFranchiseError,
    getFranchiseLoading,
  } = useSelector((state) => state.firmDetails);
  const [selectedData, setSelectedData] = useState("")
  const [showSearchInput, setShowSearchInput] = useState(true);
  const [listCount, setListCount] = useState("");
  const dispatch = useDispatch()

  let timeoutId;
  function debounce(fn, delay) {
    return function () {
      const args = arguments;
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        fn.apply(this, args);
      }, delay);
    };
  }

  // handling on the text Change on searchInput box
  const searchTextChange = () => {
    let queryValue = searchFirmText?.toLowerCase();

    let query = {}
    if (currentTag === "BROKERAGE") {
      if (queryValue) {
        query = {
          // officeTypes: "BROKERAGE",
          // ...(selectedData?._id ? {parentId: selectedData._id}:{}),
          search: queryValue,
        }
      } 
      // else {
      //   query = {
      //     // officeTypes: "BROKERAGE",
      //     // ...(selectedData?._id ? {parentId: selectedData._id}:{}),
      //   }
      // }
      dispatch(firmDetailsHandle.getBrokrage(query));
      setDataSource(getBrokrageData);
    } 
    // else if (currentTag === "OFFICE") {
    //   if (queryValue) {
    //     query = {
    //       // officeTypes: "OFFICE",
    //       search: queryValue,
    //       ...(selectedData?._id ? {parentId: selectedData._id}:{}),
    //     }
    //   }
    //   else {
    //     query = {
    //       // officeTypes: "OFFICE",
    //       ...(selectedData?._id ? {parentId: selectedData._id}:{}),
    //     }
    //   }
    //   dispatch(firmDetailsHandle.getAllFranchise(query));
    //   setDataSource(getFranchiseData);
    // }
  };

  const debouncedSearchBrokerage = debounce(searchTextChange, 800);

  let handleLoadMore = () => {
    setListCount(listCount + 32);
  };
  useEffect(() => {
    if (searchFranchiseText) {
      searchTextChange(searchFranchiseText);
    }
  }, [searchFranchiseText]);

  useEffect(() => {
    if (searchFirmText?.length > 0) {
      debouncedSearchBrokerage();
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [searchFirmText]);

  return (
    <div style={{ height: '500px' }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <div>
          <SearchOutlined
            style={{ fontSize: "30px", color: "#085191" }}
            onClick={() => setShowSearchInput(!showSearchInput)}
          />
        </div>
        <div style={{ textAlign: "center" }}>
          <Title level={3}>
            {currentTag === "BROKERAGE"
              ? "Select Brokerage"
              : currentTag === "OFFICE"
                ? "Select Office"
                : ""}
          </Title>
        </div>
        <div></div>
      </div>
      <div>
        {showSearchInput && (
          <Input
          onChange={(e) => {
            const value = e.target.value;
            setSearchFirmText(value);
            if (!value) {
              dispatch(firmDetailsHandle.getBrokrage(value));
              setDataSource(getBrokrageData);
            }
          }}
            value={searchFirmText}
            placeholder="Search by name"
            autoFocus
            allowClear
            style={{
              height: "40px",
              border: "1px solid #085191",
              fontSize: "20px",
            }}
          />
        )}
      </div>

      <div
        className={
          showSearchInput
            ? "mozScrollbar clientListBox"
            : "mozScrollbar clientListBoxForSmallScreen"
        }
        id="parent-scroll-div"
        style={{
          overflowY: "auto",
          overflowX: "hidden",
        }}
        onScroll={(e) => {
          if (
            Math.round(e.target.scrollHeight - Math.round(e.target.scrollTop)) <
            1000
          ) {
            handleLoadMore();
          }
        }}
      >
        {getBrokrageLoading || getOfficeLoading ? <div className={styles.brokerageLoading}>
          <Loading />
        </div> :
          <List
            className={styles.demoloadlisting}
            bordered={true}
            itemLayout="horizontal"
            dataSource={getBrokrageData || []}
            style={{overflow: 'auto', height: "400px"}}
            renderItem={(item) => {
              const nameArr = item?.name?.split(" ");
              const fullName = nameArr?.[0]?.[0]?.toUpperCase() + nameArr?.[nameArr.length - 1]?.[0]?.toUpperCase();
              return (
                <List.Item
                  className={[styles.clientListItem, styles.listuser]}
                  key={item._id}
                  onClick={() => {
                    selectClient(item)
                    setSelectedData(item);
                  }}
                >
                  <List.Item.Meta
                    avatar={
                      item.logo ? (
                        <Avatar
                          style={{
                            border: "3px solid #085191",
                            background: "white",
                            color: "#085191",
                            objectFit: "contain"
                          }}
                          size={55}
                          className={styles.useravtar}
                          src={item?.logo}
                        >
                          {fullName}
                        </Avatar>
                      ) : item?.name ? (
                        <Avatar size={55}
                          style={{
                            border: "3px solid #085191",
                            background: "white",
                            color: "#085191",
                            objectFit: "contain"
                          }}>
                          {fullName}
                        </Avatar>
                      ) : (
                        <Avatar size={55}
                          style={{
                            border: "3px solid #085191",
                            background: "white",
                            color: "#085191",
                            objectFit: "contain"
                          }} />
                      )
                    }
                    title={item?.name}
                    description={
                      <span style={{ color: "#4CAAFF", fontSize: "16px" }}>
                        {formatAddress(item?.address)}
                      </span>
                    }
                  />
                </List.Item>
              );
            }}
          />
          }
      </div>
    </div>
  );
};

export default FranchiseList;

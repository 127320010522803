import axios from "axios";
import dotEnv from "dotenv";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;

const getEmailSignRequest = (accessTokan) => {
    return axios.get(`${apiURL}/getEmailSignature`,
      {
        headers: {
          accept: "application/json",
          authorization: `bearer ${accessTokan}`,
        },
      }
    );
  }

export const commonApi = {
  getEmailSignRequest,
};

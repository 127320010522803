import React from "react";
// import { Link } from "react-router-dom";
import dotEnv from "dotenv";
import { Result, Button } from "antd";
dotEnv.config();
const marketingURL = process.env.REACT_APP_MARKETING_URL;

function NDASuccessForm({ title, subTitle, homeUrl }) {
  let logoHandle = () => {
    window.location.assign(`${marketingURL}`);
  };
  return (
    <Result
      status="success"
      title={title} //"Successfully Purchased Contract!"
      subTitle={subTitle} //"Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
      extra={
        <Button type="primary" onClick={logoHandle} key="console">
          Home
        </Button>
      }
    />
  );
}

export { NDASuccessForm };

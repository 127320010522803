export const closingConstants = {
  LOCATION_API_SUCCESS: "LOCATION_API_SUCCESS",
  LOCATION_API_FAILURE: "LOCATION_API_FAILURE",
 TRANSACTION_EMAIL_SUCCESS:"TRANSACTION_EMAIL_SUCCESS",
 TRANSACTION_EMAIL_FAIL:"TRANSACTION_EMAIL_FAIL",

 
    
 GET_TITLE_COMPANY_LOADING: "GET_TITLE_COMPANY_LOADING",
 GET_TITLE_COMPANY_SUCCESS: "GET_TITLE_COMPANY_SUCCESS",
 GET_TITLE_COMPANY_ERROR: "GET_TITLE_COMPANY_ERROR",

 

 GET_TITLE_OFFICE_LOADING: "GET_TITLE_OFFICE_LOADING",
 GET_TITLE_OFFICE_SUCCESS: "GET_TITLE_OFFICE_SUCCESS",
 GET_TITLE_OFFICE_ERROR: "GET_TITLE_OFFICE_ERROR",

 SEARCH_TITLE_OFFICE_LOADING: "SEARCH_TITLE_OFFICE_LOADING",

 GET_TITLE_COMPANY_DATA_LOADING:"GET_TITLE_COMPANY_DATA_LOADING",
 GET_TITLE_COMPANY_DATA_SUCCESS:"GET_TITLE_COMPANY_DATA_SUCCESS",
 GET_TITLE_COMPANY_DATA_ERROR:"GET_TITLE_COMPANY_DATA_ERROR",

 ADD_TITLE_OFFICE_LOADING: "ADD_TITLE_OFFICE_LOADING",
 ADD_TITLE_OFFICE_SUCCESS: "ADD_TITLE_OFFICE_SUCCESS",
 ADD_TITLE_OFFICE_ERROR: "ADD_TITLE_OFFICE_ERROR",

  SET_TITLE_COMPANY_MODAL_STATE: "SET_TITLE_COMPANY_MODAL_STATE",

  GET_SHARED_DOCS_FOR_TR: 'GET_SHARED_DOCS_FOR_TR',
  GET_SHARED_DOCS_LOADING:"GET_SHARED_DOCS_LOADING",
  GET_SHARED_DOCS_ERROR:"GET_SHARED_DOCS_ERROR"


};

import React, { useEffect } from 'react'
import styles from '../styles/ShowEmailContent.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from '../../Common';
import { message } from 'antd';
import ReactHtmlParser from "react-html-parser";
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { contractDataHandle } from '../state/actions';
import CommonNavbar from './CommonNavbar';
import { decryptQuery } from '../../Common/utils/universalFuntions';

function ShowEmailContent() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tabId = queryParams.get('tabId');
  const encryptedCurrentObject = queryParams.get('data');
  const decryptedCurrentObject = decryptQuery(decodeURIComponent(encryptedCurrentObject));
  let { s3Bucket, s3Key, forwardedTo, originalCc, logId, contractAuthKey } = decryptedCurrentObject;
  const dispatch = useDispatch();
  const { setEmailError, setEmailLoading, setEmailData } = useSelector((state) => state.createOffer);

  useEffect(() => {
    let currentObject = { s3Bucket, s3Key, logId }
    let accessToken = contractAuthKey;
    dispatch(contractDataHandle.getEmailContents(currentObject, accessToken));
  }, [contractAuthKey, dispatch, logId, s3Bucket, s3Key]);

  return (
    <>
      <div className={styles.EmailPageOuterContainer}>
       <CommonNavbar source={"ShowEmailContent"} tabId={`${tabId}_page2`} />
        <div className={styles.emailScreenContainer}>
          {
            setEmailLoading ? <Loading /> :
              setEmailError ? message.error("Couldn't retrieve the email.") :
                setEmailData ?
                <div className={styles.outerBox}>
                  <div className={styles.subject}><span style={{ color: 'black' }}>Subject:&emsp;</span>{setEmailData?.subject}</div>
                  {/* <div className={styles.date}>{setEmailData?.date ? moment(setEmailData.date).format('MM-DD-YYYY') : ''}</div> */}

                  {/* <div className={styles.date}>
                    {setEmailData?.date ? moment(setEmailData.date).format('MM-DD-YYYY hh:mm A') : ''}
                  </div> */}

                  <div className={styles.date}>
                    {setEmailData?.date ? moment(setEmailData.date).format('ddd, MMM D, h:mm A') + ' (' + moment(setEmailData.date).fromNow() + ')' : ''}
                  </div>

                  <div className={styles.emailDetails}>
                    <table>
                      <tbody>
                        <tr>
                          <td className={styles.label}>From:</td>
                          <td className={styles.value}>{ReactHtmlParser(setEmailData?.from?.html)}</td>
                        </tr>
                        <tr>
                          <td className={styles.label}>To:</td>
                          <td className={styles.value}>
                            {setEmailData?.to?.value?.map((to, i) => (
                              <div key={i}><span>{to?.name}</span>&lt;<a href={`mailto:${to?.address}`}>{to?.address}</a>&gt;</div>
                            ))}
                          </td>
                        </tr>
                        {originalCc?.length ? <tr>
                          <td className={styles.label}>CC:</td>
                          <td className={styles.value}>
                            {originalCc?.map((cc, index) => (
                              <div key={index}>&lt;<a href={`mailto:${cc}`}>{cc}</a>&gt;</div>
                            ))}
                          </td>
                        </tr> : null}
                        {forwardedTo?.length ? <tr>
                          <td className={styles.label}>Forwarded To:</td>
                          <td className={styles.value}>
                            {forwardedTo?.map((fwdTo, index) => (
                              <div key={index}>&lt;<a href={`mailto:${fwdTo}`}>{fwdTo}</a>&gt;</div>
                            ))}
                          </td>
                        </tr> : null}
                      </tbody>
                    </table>
                  </div>

                  <div className={styles.emailBody}>
                    {ReactHtmlParser(setEmailData?.html)}
                  </div>

                    <div className={styles.emailAttachments}>
                      {setEmailData?.attachments?.length ? "Here you can download the attached pdf files:" : ""}
                      {setEmailData?.attachments?.map((attachment, index) => (
                        <div key={index}>
                          {attachment?.contentType === "application/pdf" && attachment?.content?.type === 'Buffer' ? (
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                const blob = new Blob([Buffer.from(attachment.content?.data)], { type: attachment.contentType });
                                const url = window.URL.createObjectURL(blob);
                                const a = document.createElement('a');
                                a.href = url;
                                a.download = attachment.filename;
                                document.body.appendChild(a);
                                a.click();
                                document.body.removeChild(a);
                                window.URL.revokeObjectURL(url);
                              }}
                            >
                              Download : {attachment.filename}
                            </a>
                          ) : null}
                        </div>
                      ))}
                    </div>
                </div>
                : ""
          }
        </div>
      </div>
    </>
  )
}

export default ShowEmailContent

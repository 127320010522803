import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Layout } from "antd";
import { listingDataHandle } from "./state/actions";
import { Loading } from "../Common/components/Loading";
import JsxParser from "react-jsx-parser";
import { contractDataHandle } from "../CreateOffer/state/actions";
import { useParams, Redirect } from "react-router-dom";
import { roleAccessFunc } from "../Common/commondata/commonData";
const { Content } = Layout;

function PreviewContractView(props) {
  let { id } = useParams();
  const dispatch = useDispatch();
  const { contractData } = useSelector((state) => state.createOffer);
  const { adminRole, roleAccessed } = useSelector(
    (state) => state.account,
    shallowEqual
  );

  useEffect(() => {
    if(roleAccessFunc(roleAccessed, "PREVIEW_VIEW")){
      dispatch(contractDataHandle.getContractData(id));
    }
    return () => {
      dispatch(listingDataHandle.resetListingData());
    };
  }, [dispatch]);

  if (!roleAccessFunc(roleAccessed, "PREVIEW_VIEW")) {
    return <Redirect to="/error" />;
  }

  return (
    <Layout style={{ height: "100%", padding: "0px" }}>
      <Content
        style={{
          overflow: "auto",
          background: "rgb(202 202 202)",
          padding: "1rem",
        }}
      >
        {contractData ? (
          <div id="htmlElements">
            <JsxParser jsx={contractData.contractJSX} />
          </div>
        ) : (
          <div style={{ height: "100vh" }}>
            <Loading />;
          </div>
        )}
      </Content>
    </Layout>
  );
}

export { PreviewContractView };

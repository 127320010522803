export const helpConstants = {
    HELP_TOPIC_DATA_LOADING: "HELP_TOPIC_DATA_LOADING",
    HELP_TOPIC_DATA_SUCCESS: "HELP_TOPIC_DATA_SUCCESS",
    HELP_TOPIC_DATA_ERROR: "HELP_TOPIC_DATA_ERROR",

    HELP_TOPIC_GLOBAL_SEARCH_LOADING: "HELP_TOPIC_GLOBAL_SEARCH_LOADING",
    HELP_TOPIC_GLOBAL_SEARCH_SUCCESS: "HELP_TOPIC_GLOBAL_SEARCH_SUCCESS",
    HELP_TOPIC_GLOBAL_SEARCH_ERROR: "HELP_TOPIC_GLOBAL_SEARCH_ERROR",

    CREATE_HELP_TOPIC_DATA_LOADING: "CREATE_HELP_TOPIC_DATA_LOADING",
    CREATE_HELP_TOPIC_DATA_SUCCESS: "CREATE_HELP_TOPIC_DATA_SUCCESS",
    CREATE_HELP_TOPIC_DATA_ERROR: "CREATE_HELP_TOPIC_DATA_ERROR",

    EDIT_HELP_TOPIC_DATA_LOADING: "EDIT_HELP_TOPIC_DATA_LOADING",
    EDIT_HELP_TOPIC_DATA_SUCCESS: "EDIT_HELP_TOPIC_DATA_SUCCESS",
    EDIT_HELP_TOPIC_DATA_ERROR: "EDIT_HELP_TOPIC_DATA_ERROR",

    DELETE_HELP_TOPIC_DATA_LOADING: "DELETE_HELP_TOPIC_DATA_LOADING",
    DELETE_HELP_TOPIC_DATA_SUCCESS: "DELETE_HELP_TOPIC_DATA_SUCCESS",
    DELETE_HELP_TOPIC_DATA_ERROR: "DELETE_HELP_TOPIC_DATA_ERROR",

    UPLOAD_IMAGE_LOADING: "UPLOAD_IMAGE_LOADING",
    UPLOAD_IMAGE_SUCCESS: "UPLOAD_IMAGE_SUCCESS",
    UPLOAD_IMAGE_ERROR: "UPLOAD_IMAGE_ERROR",

    RESET_IMAGE_LOADING: "RESET_IMAGE_LOADING",
    RESET_IMAGE_SUCCESS: "RESET_IMAGE_SUCCESS",
    RESET_IMAGE_ERROR: "RESET_IMAGE_ERROR",

    GET_ROLES_LOADING: "GET_ROLES_LOADING",
    GET_ROLES_SUCCESS: "GET_ROLES_SUCCESS",
    GET_ROLES_ERROR: "GET_ROLES_ERROR",

    GET_SECTION_LOADING: "GET_SECTION_LOADING",
    GET_SECTION_SUCCESS: "GET_SECTION_SUCCESS",
    GET_SECTION_ERROR: "GET_SECTION_ERROR",
  };

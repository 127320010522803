import { contractConstants } from "../../CreateOffer/state/action-types";
import { contractStoreConstants } from "./action-types";

const initialState = {
  persistKey: "",
  submitsuccess: {},

  autoSaveContractsuccess: null,
  autoSaveContractError: null,
  autoSaveContractLoading: null,
  autoSaveContractStaus: false,
  transparentDivData: {
    loadingStatus: false,
    message: ""
  },


  validatesuccess: [],
  validateError: null,
  validateLoading: true,

  addClausesData: [],
  addClausesLoading: null,
  addClausesError: null,

  addDocumentsData: [],
  addDocumentsLoading: null,
  addDocumentsError: null,

  getTransactionLaneData: null,
  getTransactionLaneLoading: true,
  getTransactionLaneError: null,
  getBuyersData: null,
  getBuyersLoading: true,
  getBuyersError: null,

  getSellersData: null,
  getSellersLoading: true,
  getSellersError: null,

  getTransactionReportData: null,
  getTransactionReportLoading: true,
  getTransactionReportError: null,

  resetSignatureLoading: false,

  templateData:[],
  templateLoading: false,
  templateError: false,
};

const contractStore = (state = initialState, action) => {
  switch (action.type) {
    case contractConstants.SET_URL_ID: {
      return {
        ...state,
        persistKey: action.currentUrlId,
      };
    }
     
    case contractStoreConstants.RESET_FORM:
      return {
        ...state,
        submitsuccess: action.submitsuccess,
      };
   
    // Auto save  Contract
    case contractStoreConstants.AUTO_SAVE_CONTRACT_SUCCESS: {
      return {
        ...state,
        autoSaveContractsuccess: action.autoSaveContractsuccess,
        autoSaveContractLoading: action.autoSaveContractLoading,
        autoSaveContractStaus: action.autoSaveContractStaus,
      };
    }
    case contractStoreConstants.AUTO_SAVE_CONTRACT_FAILURE:
      return {
        ...state,
        autoSaveContractError: action.autoSaveContractError,
        autoSaveContractLoading: action.autoSaveContractLoading,
        autoSaveContractStaus: action.autoSaveContractStaus,
      };

    case contractStoreConstants.AUTO_SAVE_CONTRACT_STATUS:
      return {
        ...state,
        autoSaveContractStaus: action.autoSaveContractStaus,
      };
    case contractStoreConstants.SET_TRANSPARENT_DIV_LOADING:
      return {
        ...state,
        transparentDivData: action.transparentDivData,
      };
    // Validate Contract
    case contractStoreConstants.VALIDATE_FORM_SUCCESS:
      return {
        ...state,
        validatesuccess: action.validatesuccess,
        validateLoading: action.validateLoading,
      };
    case contractStoreConstants.VALIDATE_FORM_FAILURE:
      return {
        ...state,
        validateError: action.validateError,
        validateLoading: action.validateLoading,
      };

    case contractStoreConstants.GET_ADD_CLAUSES_SUCCESS:
      return {
        ...state,
        addClausesData: action.addClausesData,
        addClausesLoading: false,
        addClausesError: false,
      };
      case contractStoreConstants.GET_ADD_CLAUSES_LOADING:
        return {
          ...state,
          addClausesData: [],
          addClausesLoading: true,
          addClausesError: false,
        };
    case contractStoreConstants.GET_ADD_CLAUSES_ERROR:
      return {
        ...state,
        addClausesError: true,
        addClausesLoading: false,
      };
    case contractStoreConstants.GET_ADD_DOCUMENTS_SUCCESS:
      return {
        ...state,
        addDocumentsData: action.addDocumentsData,
        addDocumentsLoading: false,
        addDocumentsError: false,
      };
    case contractStoreConstants.GET_ADD_DOCUMENTS_ERROR:
      return {
        ...state,
        addDocumentsError: true,
        addDocumentsLoading: false,
      };
    case contractStoreConstants.GET_ADD_DOCUMENTS_LOADING:
      return {
        ...state,
        addDocumentsError: false,
        addDocumentsLoading: true,
      };
    case contractStoreConstants.RESET_VALIDATION_FORM: {
      return {
        ...state,
        validatesuccess: [],
        validateError: null,
        validateLoading: true,
      };
    }
    case contractStoreConstants.GET_TRANSACTION_LANE_SUCCESS:
      const loclData = JSON.parse(localStorage.getItem(state.persistKey));
      loclData["rtdDocs"] = action.getTransactionLaneData;
      localStorage.setItem(state.persistKey, JSON.stringify(loclData));
      return {
        ...state,
        getTransactionLaneData: action.getTransactionLaneData,
        getTransactionLaneLoading: action.getTransactionLaneLoading,
        getTransactionLaneError: null,
        data: action.getTransactionLaneData,
      };
    case contractStoreConstants.GET_TRANSACTION_LANE_FAILURE:
      return {
        ...state,
        getTransactionLaneData: [],
        getTransactionLaneLoading: action.getTransactionLaneLoading,
        getTransactionLaneError: action.getTransactionLaneError,
      };
    case contractStoreConstants.GET_BUYERS_SUCCESS:
      if (action.party === "BUYER") {
        return {
          ...state,
          getBuyersData: action.getBuyersData,
          getBuyersLoading: action.getBuyersLoading,
          getBuyersError: null,
        };
      } else {
        return {
          ...state,
          getSellersData: action.getBuyersData,
          getSellersLoading: action.getBuyersLoading,
          getSellersError: null,
        };
      }

    case contractStoreConstants.GET_BUYERS_ERROR:
      return {
        ...state,
        getBuyersData: [],
        getBuyersLoading: action.getBuyersLoading,
        getBuyersError: action.getBuyersError,
      };

    case contractStoreConstants.GET_TRANSACTION_REPORT_SUCCESS:
      return {
        ...state,
        getTransactionReportData: action.getTransactionReportData,
        getTransactionReportLoading: action.getTransactionReportLoading,
        getTransactionReportError: null,
      };

    case contractStoreConstants.GET_TRANSACTION_REPORT_ERROR:
      return {
        ...state,
        getTransactionReportData: [],
        getTransactionReportLoading: action.getTransactionReportLoading,
        getTransactionReportError: action.getTransactionReportError,
      };
    case contractStoreConstants.RESET_SIGNATURE_LOADING:
      return {
        ...state,
        resetSignatureLoading: action.resetSignatureLoading,
      };
      case contractStoreConstants.TEMPLATE_DATA_LOADING:
        return {
          ...state,
          templateLoading: true,
          templateError: false,
        };
      case contractStoreConstants.TEMPLATE_DATA_SUCCESS:
        return {
          ...state,
          templateData: action.templateData,
          templateLoading: false,
          templateError: false,
        };
      case contractStoreConstants.TEMPLATE_DATA_FAILURE:
        return {
          ...state,
          templateError: action.templateError,
          templateLoading: false,
        };
    default:
      return state;
  }
};
export { contractStore };

import { contactSheetActionTypes } from "./action-types";

const initialState = {
    contactSheetData: null,
    contactSheetDataError: null,
    contactSheetDataLoading: false,
}

const contactSheetReducer = (state = initialState, action) => {
    switch (action.type) {
        case contactSheetActionTypes.GET_CONTACT_SHEET_SUCCESS:
            return {
                ...state,
                contactSheetData: action.contactSheetData,
                contactSheetDataError: action.contactSheetDataError,
                contactSheetDataLoading: action.contactSheetDataLoading,
            }
        case contactSheetActionTypes.GET_CONTACT_SHEET_LOADING:
            return {
                ...state,
                contactSheetData: null,
                contactSheetDataError: action.contactSheetDataError,
                contactSheetDataLoading: action.contactSheetDataLoading,
            }
        case contactSheetActionTypes.GET_CONTACT_SHEET_ERROR:
            return {
                ...state,
                contactSheetData: null,
                contactSheetDataError: action.contactSheetDataError,
                contactSheetDataLoading: action.contactSheetDataLoading,
            }
        default:
            return state
    }

}

export { contactSheetReducer };
import { Button, Form, Input, Modal, Upload, message } from 'antd'
import React, { useRef, useState } from 'react'
import DatePicker from 'react-date-picker';
import { useDispatch } from 'react-redux';
import { newsApis } from '../state/actions';
import { PlusOutlined, MinusCircleOutlined,InboxOutlined } from '@ant-design/icons';
import Dragger from 'antd/lib/upload/Dragger';

const NewsCreateModal = ({isModalOpen,handleCancel,form}) => {
    const dispatch = useDispatch();
    const uploadFile = useRef("");
  const [heading,setHeading] = useState("");
  const [filetext,setFileText] = useState([]);

  // Handling Property Docs
let handleCustome = (data) => {
    const reader = new FileReader();
    reader.readAsDataURL(data.file);
    reader.onload = () => {
      const newFile = {
        name: data.file.name,
        size: bytesToSize(data.file.size),
        file: reader.result,
        origin: "notBase64",
      };
      let fileType;
      if (data.file.name.endsWith(".pdf")) {
        fileType = "PDF";
      } else {
        fileType = "IMAGE";
      }
      newFile.fileType = fileType; // Adding fileType property to newFile

      setFileText((prevFiles) => {
        if (prevFiles?.length) {
          return [...prevFiles, newFile];
        } else {
          return [newFile];
        }
      });
    };
    reader.onerror = (error) => {
      console.error(error);
    };
    uploadFile.current = [...uploadFile.current,data.file];
  };
  const CleanUpFunc =()=>{
        uploadFile.current="";
        setFileText([]);
        setHeading([]);
    }
    const onFinish = () => {
        dispatch(newsApis.postNewsData(uploadFile.current,filetext[0].fileType,heading));
        handleCancel();
        CleanUpFunc();
        form.resetFields();
      };

      function beforeUpload(file) {
        let supportedFormats = file?.type

        if (supportedFormats?.includes("image")) {
          const isJpgOrPng =
            file.type === "image/jpeg" || file.type === "image/png";
          if (!isJpgOrPng) {
            message.error("You can only upload JPG/PNG file!");
          }
          const isLt5M = file.size / 1024 / 1024 < 5;
          if (!isLt5M) {
            message.error("Image must smaller than 5MB!");
          }
          return isJpgOrPng && isLt5M;
        }

        if (supportedFormats?.includes("pdf")) {
          const isPdf = file.type === "application/pdf";
          if (!isPdf) {
            message.error("You can only upload PDF file!");
          }
          const isLt2M = file.size / 1024 / 1024 < 5;
          if (!isLt2M) {
            message.error("File must be smaller than 5MB!");
          }
          return isPdf && isLt2M;
        }
      }

       function bytesToSize(bytes) {
        let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
        if (bytes === 0) return "0 Byte";
        let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
      }
  return (
    <div>
    <Modal
     style={{marginTop:"-60px"}}
      title={<span style={{display:"flex",justifyContent:"center",color:"grey"}}>CREATE NEWS LETTER</span>}
      footer={false}
      visible={isModalOpen}
      onCancel={()=>handleCancel(CleanUpFunc)}
      >
        <Form
      form={form}
      name="newsletter-form"
      onFinish={onFinish}
      labelCol={{ span: 24 }} // Label at the top of the fields
      wrapperCol={{ span: 24 }}
    >
      <Form.Item
        label="Heading"
        name="heading"
        rules={[{ required: true, message: 'Please input the heading' }]}
      >
        <Input onChange={(e)=>setHeading(e.target.value)} placeholder='Please enter heading...'/>
      </Form.Item>
      <Form.Item
        label={<span style={{color:"red",fontSize:'15px'}}>*<span style={{color:"black",marginLeft:"5px"}}>File</span></span>}
        name="file"
        rules={[{ required: false, message: 'Please choose file!' }]}
      >
        <Dragger beforeUpload={beforeUpload} customRequest={handleCustome} showUploadList={false} accept="image/*,.pdf" >
        <p className="ant-upload-drag-icon">
            <InboxOutlined />
        </p>
    <p className="ant-upload-text">Click or drag file to this area to upload</p>
    <p className="ant-upload-hint">
      Support for a single or bulk upload. Strictly prohibit from uploading company data or other
      band files
    </p>
  </Dragger>
       <div style={{textAlign:"center",marginBottom:"10px"}}>
       {filetext && filetext[0]?.name && `Name : ${ filetext[0]?.name}`}
       </div>
      </Form.Item>
      <Form.Item>
        <Button disabled={filetext.length===0}style={{width:"100%"}} type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
      </Modal>
     {/* <Modal
     style={{marginTop:"-60px"}}
      title={<span style={{display:"flex",justifyContent:"center",color:"grey"}}>CREATE NEWS LETTER</span>}
      width={800}
      footer={false}
      visible={isModalOpen}
      onCancel={handleCancel}
      >
     <div style={{height:"600px",overflow:"auto"}}>
     <Form
      name="newsletter-form"
      onFinish={onFinish}
      labelCol={{ span: 24 }} // Label at the top of the fields
      wrapperCol={{ span: 24 }}
    >
    <Form.Item
        label="Heading"
        name="heading"
        rules={[{ required: true, message: 'Please input the heading' }]}
      >
        <Input />
      </Form.Item>


      <Form.List name="sections">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <div key={key}>
                <Form.Item
                  label="Section Title"
                  name={[name, 'title']}
                  fieldKey={[fieldKey, 'title']}
                  rules={[{ required: true, message: 'Please input section title' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Section Content"
                  name={[name, 'content']}
                  fieldKey={[fieldKey, 'content']}
                  rules={[{ required: true, message: 'Please input section content' }]}
                >
                  <Input.TextArea />
                </Form.Item>

                <Form.List
                  name={[name, 'videos']}
                >
                  {(fields, { add, remove }) => (
                    <div>
                      {fields.map(({ key, name, fieldKey, ...restField }) => (
                        <div key={key}>
                          <Form.Item
                            label="Video URL"
                            name={name}
                            fieldKey={fieldKey}
                            rules={[{ required: true, message: 'Please input video URL' }]}
                          >
                            <Input />
                          </Form.Item>
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => {
                              remove(name);
                            }}
                          />
                        </div>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => {
                            add();
                          }}
                          icon={<PlusOutlined />}
                        >
                          Add Video
                        </Button>
                      </Form.Item>
                    </div>
                  )}
                </Form.List>

                <MinusCircleOutlined
                  className="dynamic-delete-button"
                  onClick={() => {
                    remove(name);
                  }}
                />
              </div>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => {
                  add();
                }}
                icon={<PlusOutlined />}
              >
                Add Section
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      <Form.List
        name="imageURLs"
      >
        {(fields, { add, remove }) => (
          <div>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <div key={key}>
                <Form.Item
                  label="Image URL"
                  name={name}
                  fieldKey={fieldKey}
                  rules={[{ required: true, message: 'Please input image URL' }]}
                >
                  <Input />
                </Form.Item>
                <MinusCircleOutlined
                  className="dynamic-delete-button"
                  onClick={() => {
                    remove(name);
                  }}
                />
              </div>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => {
                  add();
                }}
                icon={<PlusOutlined />}
              >
                Add Image URL
              </Button>
            </Form.Item>
          </div>
        )}
      </Form.List>

      <Form.Item
        label="PDF URL Link"
        name="pdfURLLink"
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Videos"
        name="videos"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Google Play Store Link"
        name="googlePlayStoreLink"
      >
        <Input />
      </Form.Item>

       <div style={{display:"flex",justifyContent:"space-around"}}>
      <Form.Item
        label="Web Release Date"
        name="webReleaseDate"
      >
        <DatePicker />
      </Form.Item>

      <Form.Item
        label="Mobile Release Date"
        name="mobileReleaseDate"
      >
        <DatePicker />
      </Form.Item>

      <Form.Item
        label="Publish Date"
        name="publishDate"
        >
        <DatePicker />
      </Form.Item>
     </div>

      <Form.Item>
        <Button style={{width:"100%"}} type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
    </div>
      </Modal> */}
    </div>
  )
}

export  {NewsCreateModal}

import { calendarConstants } from "./action-types";

const initialState = {
  calendarData: null,
  calendarDataLoading: null,
  calendarDataError: null,

  calendarDataEncrypt: null,
  calendarDataEncryptLoading: null,
  calendarDataEncryptError: null,

  getRemindersData: [],
  getRemindersError: null,

  getSchedulesData: [],
  getSchedulesError: null,

  shareCalendarLoading: false,
};

const calendarData = (state = initialState, action) => {
  switch (action.type) {
    case calendarConstants.CALENDAR_DATA_SUCCESS:
      return {
        ...state,
        calendarData: action.calendarData,
        calendarDataLoading: action.calendarDataLoading,
        calendarDataError: null,
      };
    case calendarConstants.CALENDAR_DATA_FAILURE:
      return {
        ...state,
        calendarData: [],
        calendarDataLoading: action.calendarDataLoading,
        calendarDataError: action.calendarDataError,
      };
    // get calendar data encrypt
    case calendarConstants.CALENDAR_GETDATA_SUCCESS:
      return {
        ...state,
        calendarDataEncrypt: action.calendarDataEncrypt,
        calendarDataEncryptLoading: action.calendarDataEncryptLoading,
        calendarDataEncryptError: null,
      };
    case calendarConstants.CALENDAR_GETDATA_FAILURE:
      return {
        ...state,
        calendarDataEncrypt: [],
        calendarDataEncryptLoading: action.calendarDataEncryptLoading,
        calendarDataEncryptError: action.calendarDataEncryptError,
      };
    case calendarConstants.GET_REMINDERS_LIST_SUCCESS:
      return {
        ...state,
        getRemindersData: action.payload,
      };
    case calendarConstants.GET_SCHEDULE_LIST_SUCCESS:
      return {
        ...state,
        getSchedulesData: action.payload,
      };
    case calendarConstants.GET_CALENDAR_LOADING:
      return {
        ...state,
        calendarDataLoading: action.calendarDataLoading,
        calendarDataError: action.calendarDataError,
        calendarData: action.calendarData,
      };
    case calendarConstants.RESET_CALENDAR:
      return {
        ...initialState,
      };
    case calendarConstants.SHARE_CALENDAR_LOADING:
      return {
        ...state,
        shareCalendarLoading: true,
      };
    case calendarConstants.SHARE_CALENDAR_SUCCESS:
      return {
        ...state,
        shareCalendarLoading: false,
      };
    default:
      return state;
  }
};
export { calendarData };

import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Layout, Switch } from "antd";
import { listingDataHandle } from "./state/actions";
import { suggessionAction } from "../Suggestion/state/actions";
import { ContentList } from "./components/ContentList";
import { Loading } from "../Common/components/Loading";
import { ContentTable } from "./components/ContentTable";
import { Redirect } from "react-router-dom";
import { roleAccessFunc } from "../Common/commondata/commonData";
const { Content } = Layout;

function ListingPage(props) {
  // useEffect(() => {
  //   if (props.networkConnection === false) {
  //     window.location.reload();
  //   }
  // }, [props]);
  const dispatch = useDispatch();
  const [switchToggle, handleToggle] = useState(true);
  const { listingData, listLoading } = useSelector(
    (state) => state.listingPage
  );
  const { adminRole, roleAccessed } = useSelector(
    (state) => state.account,
    shallowEqual
  );
  useEffect(() => {
    dispatch(suggessionAction.getSuggesionList());
  }, [dispatch]);
  const { getSuggesionData } = useSelector((state) => state.suggestion);

  useEffect(() => {
    if(roleAccessFunc(roleAccessed, "LISTING")){
      dispatch(listingDataHandle.getListingData());
    }
    return () => {
      dispatch(listingDataHandle.resetListingData());
    };
  }, [dispatch]);
  if (!roleAccessFunc(roleAccessed, "LISTING")) {
    return <Redirect to="/error" />;
  }

  return (
    <Layout style={{ height: "100%", padding: "0px" }}>
      <>
        <div style={{ marginTop: "-20px",marginBottom:"5px" }}>
            <Switch
              onClick={() => handleToggle(!switchToggle)}
              checkedChildren="Grid View"
              unCheckedChildren="List View"
            />
          </div>
        <Content
          style={{
            overflow: "auto",
            background: "rgb(202 202 202)",
            padding: "1rem",
          }}
        >
          {/* <div style={{ margin: "1rem 0" }}>
            <Switch
              onClick={() => handleToggle(!switchToggle)}
              checkedChildren="Grid View"
              unCheckedChildren="List View"
            />
          </div> */}
          {switchToggle ? (
            listLoading ? (
              <Loading />
            ) : (
              <ContentTable
                getSuggesionData={getSuggesionData}
                renderData={listingData}
              />
            )
          ) : listLoading ? (
            <Loading />
          ) : (
            <ContentList renderData={listingData} />
          )}
        </Content>
      </>
    </Layout>
  );
}

export { ListingPage };

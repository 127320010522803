import { Badge, Card, Modal } from 'antd'
import moment from 'moment'
import React from 'react'
import styles from "../styles/EventDetailModal.module.css"

function EventDetailModal({ setModalStates, modalStates }) {
    const isContractSchedule = modalStates?.event?.type === "CONTRACT_SCHEDULE";
    return (
        <>
            <Modal
                // title={<div className={styles.modalDateTitle}>{moment(modalStates?.event?.eventDate).format('dddd, MMMM D YYYY')}</div>}
                title={
                // <div>
                //     {modalStates?.event?.eventName ? <div className={styles.eventName}>{modalStates?.event?.eventName}</div> :
                //     modalStates?.event?.title ? <div className={styles.eventName}>{modalStates?.event?.title}</div> : null}
                //     {modalStates?.event?.address?.fullAddress ? <div className={styles.fullAddress}>{modalStates?.event?.address?.fullAddress}</div> :
                //     modalStates?.event?.fullAddress ? <div className={styles.fullAddress}>{modalStates?.event?.fullAddress}</div> : null}
                // </div>

                    <Badge.Ribbon
                        text="Contract Schedule"
                        color="#178DFA"
                        // style={{ display: isContractSchedule ? 'block' : 'none' }}
                        style={{ display: 'none' }}
                        placement='start'
                    >
                        <Card 
                        bodyStyle={{paddingBottom: 3, marginTop: isContractSchedule && false ? 5 : undefined, paddingTop: isContractSchedule && false ? undefined : 3}}>
                            {modalStates?.event?.eventName ? (
                                <div className={styles.eventName}>{modalStates?.event?.eventName}</div>
                            ) : modalStates?.event?.title ? (
                                <div className={styles.eventName}>{modalStates?.event?.title}</div>
                            ) : null}
                            {modalStates?.event?.address?.fullAddress ? (
                                <div className={styles.fullAddress}>{modalStates?.event?.address?.fullAddress}</div>
                            ) : modalStates?.event?.fullAddress ? (
                                <div className={styles.fullAddress}>{modalStates?.event?.fullAddress}</div>
                            ) : null}
                        </Card>
                    </Badge.Ribbon>
                }
                visible={true}
                closable
                onCancel={() => setModalStates(prev => ({ ...prev, openDetailModal: false }))}
                footer={null}
                className='EventDetailModal'
                bodyStyle={{paddingTop: "5px"}}
            >
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                {(["REMINDER", "SCHEDULE", "CONTRACT_SCHEDULE"].includes(modalStates?.event?.type) && modalStates?.event?.start) ?
                    <div className={styles.modalContents}>
                        Start time: <span style={{ color: "black" }}>{moment(modalStates.event?.start).format('MM/DD/YYYY, h:mm A')}</span>
                    </div> : null}
                {(["SCHEDULE", "CONTRACT_SCHEDULE"].includes(modalStates?.event?.type) && modalStates?.event?.end) ?
                    <div className={styles.modalContents}>
                        End time: <span style={{ color: "black" }}>{moment(modalStates.event?.end).format('MM/DD/YYYY, h:mm A')}</span>
                    </div> : null}
                </div>
                <div className={styles.eventDates}>
                    {modalStates?.event?.dateOrDeadline ?
                        <div className={styles.modalContents}>
                            Due Date: <span>{moment(modalStates.event?.dateOrDeadline).format('MM/DD/YYYY')}</span>
                        </div> : null}
                    {modalStates?.event?.completedDateMili ?
                        <div className={styles.modalContents}>
                            Completed Date: <span style={{ color: "green" }}>{moment(modalStates.event?.completedDateMili).format('MM/DD/YYYY')}</span>
                        </div> : null}
                </div>
                
                {/* <div className={styles.mainDetailContainer}> */}
                    {/* {modalStates?.event?.address?.fullAddress ? <div className={styles.fullAddress}>{modalStates?.event?.address?.fullAddress}</div> : null} */}
                    {/* {modalStates?.event?.eventName ? <div className={styles.eventName}>{modalStates?.event?.eventName}</div> :
                     modalStates?.event?.title ? <div className={styles.eventName}>{modalStates?.event?.title}</div> : null} */}
                    {modalStates?.event?.description ? <div className={styles.description}>{modalStates?.event?.description}</div> : null}

                {/* </div> */}
            </Modal>
        </>
    )
}

export default EventDetailModal
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { transactionLaneDataHandle } from "../state/actions";
import { Tooltip, Button } from "antd";
import newStyles from "../style/RtdRow.module.css";
import { Modal, Spin } from "antd";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { FilePdfOutlined, CheckCircleFilled, MessageTwoTone, ExclamationCircleOutlined ,SettingOutlined, DeleteTwoTone} from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { offerTableHandle } from "../../OfferTable/state/actions";
import { conterOfferContractTypes, disabledTransactionText } from "../../Common/commondata/commonData";

const SignalIcon = ({ source, documentStatus }) => {
  const { buyerSignStatus, buyerAgentSignStatus, sellerSignStatus, sellerAgentSignStatus, signatureSequence } = documentStatus || {};
  const getStatusColor = (status) => {
    switch (status) {
      case "SUBMITTED":
        return "green";
      case "DRAFT":
        return "white";
      case "INPROGRESS":
        return "yellow";
      default:
        return "white";
    }
  };
  let topColor, bottomColor, fullColor, indicatorNotRequired;
  if (source === "BUYER") {
    if (signatureSequence?.buyer && signatureSequence?.buyerAgent) {
      topColor = getStatusColor(buyerSignStatus);
      bottomColor = getStatusColor(buyerAgentSignStatus);
    } else if (signatureSequence?.buyer) {
      fullColor = getStatusColor(buyerSignStatus);
    } else if (signatureSequence?.buyerAgent) {
      fullColor = getStatusColor(buyerAgentSignStatus);
    } else {
      // fullColor = getStatusColor();
      indicatorNotRequired = true;
    }
  } else if (source === "SELLER") {
    if (signatureSequence?.seller && signatureSequence?.sellerAgent) {
      topColor = getStatusColor(sellerSignStatus);
      bottomColor = getStatusColor(sellerAgentSignStatus);
    } else if (signatureSequence?.seller) {
      fullColor = getStatusColor(sellerSignStatus);
    } else if (signatureSequence?.sellerAgent) {
      fullColor = getStatusColor(sellerAgentSignStatus);
    } else {
      // fullColor = getStatusColor();
      indicatorNotRequired = true;
    }
  }
  // console.log(source, documentStatus.contractType, topColor, bottomColor, fullColor);
  if (indicatorNotRequired) {
    return null;
  }
  return (
    <div className={newStyles.signalIconOutline}>
      <div
        className={fullColor ? newStyles.fullIndicator : newStyles.signalIndicator}
        style={{
          "--topColor": topColor,
          "--bottomColor": bottomColor,
          "--fullColor": fullColor,
        }}
      />
    </div>
  );
};

const SellerComp = (props) => {
  let timestamp = (props.RTDInitiator === "SELLER") ? props.dateSubmitted : props.latestDocumentSignatureAt;
  let toolTipMessage = timestamp && (props.RTDInitiator === "SELLER") ? "Date the document was sent to the Buyer Agent." : "Date the last required signer from the buying side signed the document.";
  let date = timestamp && new Date(timestamp);
  const dateNew = timestamp && moment(date).format("MM/DD/YYYY");
  const time = timestamp && moment(date).format("h:mm a");
  const signalRequired = props?.signatureSequence?.seller || props.signatureSequence?.sellerAgent
  const {  checkForDisable } = useSelector((state) => state.offerTable);
  const dispatch = useDispatch();
  const { confirm } = Modal;
  const { URLofferTableJSON } = useSelector((state) => state.offerTable);
  const sellerOpenOfferTable = URLofferTableJSON?.urlData?.sellerOpenOfferTable;
  const confirmDeleteForm = (props) => {
    let modalMessage = props?.contractType === "CO" ? `Note: This action is irreversible. Deleting this document will permanently remove it from your account.` : "The document will be removed from Transaction Dashboard but can still be accessed from Property Docs."
    confirm({
      title: "Are you sure want to delete this Document?",
      content: modalMessage,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        if (props?.documentId) {
          const payloadData = {
            documentId: props?.documentId,
            ...(props?.selectedData?.offerId && { offerId: props?.selectedData?.offerId }),
            token: props?.userAuthKey,
            role: props?.RTDInitiator,
            isToggleFrom: "PRE_RTD",
          };
          dispatch(transactionLaneDataHandle.deletePropertyContractDocs({ payloadData }));
        }
      },
      onCancel() {
        // Do nothing, just close the Modal
      },
    });
  };

  return (
    <div className={newStyles.sellerCompContainer}>
      <div className={newStyles.sellerCenterline}>
        <>
          <div
            style={{
              width: "100%",
              position: "relative",
              borderStyle: props.RTDInitiator === "SELLER" ? "solid" : "none",
              height: 0,
              marginTop: signalRequired ? 3 : 5,
              borderColor: props?.RTDInitiator === "SELLER" ? (props?.invalidated ? "darkgray" : "#2976be") : "white",
            }}
          >
            {props?.RTDInitiator === "SELLER" &&
              props?.client === "SELLER" &&
             (props?.checked?.showBuyer ? (
                <span style={{ position: "absolute", top: "50%", transform: "translateY(-50%)" }}>
                  <Tooltip title="Document sent to Buyer Agent">
                    <CheckCircleFilled style={{ fontSize: props?.isSmallScreen?"14px":"18px", backgroundColor: "white", borderRadius: "100px", color: props?.invalidated ? "darkgray" : "green" }} />
                  </Tooltip>
                </span>
              ) : (
                <></>
              ))}

            <div className={newStyles.dateAndTimeSeller}>
              {timestamp && <div style={{display: "inline-block"}}>
                <Tooltip title={toolTipMessage} placement="top">
                <p
                className={newStyles.newdateSeller}
                    style={{
                   marginTop: props?.RTDInitiator === "BUYER" ? "-30px" : "0px",
                    }}
                  >
                    {dateNew}
                  </p>
                  <p
                   className={newStyles.newtimeSeller}
                  >
                    {time}
                  </p>
                </Tooltip>
              </div>}
            </div>
            {!sellerOpenOfferTable && conterOfferContractTypes.includes(props?.contractType)
              ? props?.RTDInitiator === "SELLER" &&
              props?.sellerSignStatus !== "SUBMITTED" &&
              props?.buyerSignStatus !== "SUBMITTED" && (
                <div
                  style={{ position: "absolute", bottom: 0, left: 0, cursor: "pointer" }}
                  {...(checkForDisable?.disabled
                    ? { onClick: disabledTransactionText }
                    : { onClick: () => confirmDeleteForm(props) })}
                >
                  <Tooltip title="Delete Document">
                    <DeleteTwoTone />
                  </Tooltip>
                </div>
              )
              :!sellerOpenOfferTable && props?.RTDInitiator === "SELLER" && (
                <div
                  style={{ position: "absolute", bottom: 0, left: 0, cursor: "pointer" }}
                  {...(checkForDisable?.disabled
                    ? { onClick: disabledTransactionText }
                    : { onClick: () => confirmDeleteForm(props) })}
                >
                  <Tooltip title="Delete Document">
                    <DeleteTwoTone />
                  </Tooltip>
                </div>
              )}
          </div>
           <SignalIcon source="SELLER"   documentStatus={props} />
        </>
      </div>
    </div>
  );
};

const BuyerComp = (props) => {
  let timestamp = (props.RTDInitiator === "BUYER") ? props.dateSubmitted : props.latestDocumentSignatureAt;
  let toolTipMessage = timestamp && (props.RTDInitiator === "BUYER") ? "Date the document was sent to the Seller Agent." : "Date the last required signer from the selling side signed the document.";
  let date = timestamp && new Date(timestamp);
  const dateNew = timestamp && moment(date).format("MM/DD/YYYY");
  const time = timestamp && moment(date).format("h:mm a");
  const signalRequired = props?.signatureSequence?.buyer || props.signatureSequence?.buyerAgent

return (
    <div className={newStyles.buyerCompContainer}>
      <div className={newStyles.buyerCenterLine}>
        <>
           <SignalIcon source="BUYER" documentStatus={props} />
          <div
            style={{
              width: "100%",
              position: "relative",
              borderStyle: props.RTDInitiator === "BUYER" ? "solid" : "none",
               height: 0,
               marginTop: signalRequired ? 3 : 5,
              borderColor: props.RTDInitiator === "BUYER" ? (props?.invalidated ? "darkgray" : "#ffce51") : "white",
            }}
          >
            {props.RTDInitiator === "BUYER" &&
              props.client === "BUYER" &&
              (props.checked.showSeller ? (
                <span style={{ position: "absolute", top: "50%", transform: "translateY(-50%)" }}>
                  <Tooltip title="Document sent to Seller Agent">
                    <CheckCircleFilled style={{ fontSize: props?.isSmallScreen?"14px":"18px", backgroundColor: "white", borderRadius: "100px", color: props.invalidated ? "darkgray" : "green" }} />
                  </Tooltip>
                </span>
              ) : (
                <></>
              ))}

            <div className={newStyles.dateAndTimeBuyer}>
              {timestamp && <div style={{display: "inline-block"}}>
                <Tooltip title={toolTipMessage} placement="top">
                  <p
                  className={newStyles.newdateBuyer}
                    style={{
                      marginTop: props?.RTDInitiator === "BUYER" ? "-5px" : "-30px",
                      }}
                  >
                    {dateNew}
                  </p>
                  <p
                    className={newStyles.newtimeBuyer}
                  >
                    {time}
                  </p>
                </Tooltip>
              </div>}
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

const DetailComp = ({ doc, seller, role, selectedData, ispdfClone, userAuthKey, handleCheckboxChangeBuyer, isSmallScreen, isMediumScreen, allBuyerAgentData }) => {

  let dispatch = useDispatch();
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const { URLofferTableJSON, currentSellerAgentData, offerTableAuthKey,offerTablePropertyId } = useSelector((state) => state.offerTable);
  let sellerOpenOfferTable = URLofferTableJSON?.urlData?.sellerOpenOfferTable;

  const urlShortningForDraft = () => {
    if (seller?.ispdfClone || seller?.isPdf) {
      let fileUrl = seller?.resureSignedPdfLink ? seller?.resureSignedPdfLink : seller?.pdfLink;
      window.open(fileUrl, "_blank").focus();
    } else {
      setLoader(true);
      let buildData = {
        builtForId: URLofferTableJSON?.builtForId ? URLofferTableJSON?.builtForId : currentSellerAgentData?._id,
        key: URLofferTableJSON?.builtForId?URLofferTableJSON?.builtForId:currentSellerAgentData?._id,
        builtForSection: "DOCUMENT",
        signable: sellerOpenOfferTable ? false : true,
        openFor: sellerOpenOfferTable ? "SELLER" : "SELLER_AGENT",
        contractType: seller?.contractType,
        propertyId: URLofferTableJSON?.propertyId,
        ...(selectedData?.offerId ? { offerId: selectedData?.offerId } : {}),
        documentId: seller?.documentId,
        contractId: seller?.contractId,
        token: userAuthKey,
      };
      dispatch(transactionLaneDataHandle.getDraftDocumentData(buildData, null, history));
    }
  };

  function isSingleWord(text) {
    return text?.trim()?.split(/\s+/)?.length === 1;
  }

const handleSignOffer = () => {
    dispatch(offerTableHandle.acceptOfferViaResureSignFun({ offerTableAuthKey, offerTablePropertyId, documentId: selectedData?.CBSRId, onlySellerAgentSignRequired: true }));
  };
  
 return (
    <div className={newStyles.documentDetailOuterCOntainer}>
      <div
        className={newStyles.documentBox}
        style={{
          borderColor: seller?.invalidated ? "darkgray" : seller?.RTDInitiator === "SELLER" ? "#3376a8" : "#ffce51",
        }}
      >
        <div
          style={{
            cursor: "pointer",
            textAlign: "center",
            width: "15%",
            pointerEvents: sellerOpenOfferTable ? "none" : "",
          }}
        >
        </div>

        <div className={newStyles.documentNameContainer}>
        <div>
   {selectedData?.counterProposalExist &&selectedData.ispdfClone && !selectedData?.sellerAgentSigned&& seller?.contractType === "CBSR"&& <Tooltip onClick={handleSignOffer} title={"Sign Disclosure via RE-Sure Sign"}><SettingOutlined style={{fontSize:"large"}}/></Tooltip>}
        </div>
          <div
            onClick={() => {
              urlShortningForDraft();
            }}
            className={`${newStyles.documentName} ${isSingleWord(doc) ? newStyles.singleWord : ""}`}
          >
            <span style={{ ...(seller?.invalidated ? { textDecoration: "line-through" } : {}) }}>{doc}</span>
             {ispdfClone ? seller?.contractType === "CBSR" ? <FilePdfOutlined /> : "" : (seller?.isPdf && <FilePdfOutlined />)}

          </div>
 
          {seller?.contractType !== "CBSR" &&
            (seller?.invalidated ? (
              <Tooltip title={<span style={{ fontSize: isSmallScreen ? 10 : isMediumScreen ? 12 : 14 }}>Invalidated</span>}>
                <ExclamationCircleOutlined style={{ color: "red" }} />
              </Tooltip>
            ) : (
              ""
            ))}
        </div>

        <div
          style={{
            color: "darkgray",
            textAlign: "center",
            cursor: "pointer",
            width: "15%",
          }}
        >
        </div>
      </div>
    </div>
  );
};

function RtdRow(props) {
  const { contractType  } = props;
  const { updateDocumentLoading } = useSelector((state) => state.rtdTransactionLane);
  const { URLofferTableJSON, offerTableAuthKey, offerTablePropertyId} = useSelector((state) => state.offerTable);

  let sellerOpenOfferTable = URLofferTableJSON?.urlData?.sellerOpenOfferTable;
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery({ maxWidth: 550 });
  const isMediumScreen = useMediaQuery({ minWidth: 551, maxWidth: 850 });
  const [showCoModal, setShowCoModal] = useState(false);

  const checked = {
    showBuyer: props.showBuyer,
    showSeller: props.showSeller,
  };
  
  const handleCheckboxChangeBuyer = (contractType, RTDInitiater, sellerAgentSigned) => {
    // console.log("selectedData", props?.selectedData?.sellerAgentSigned, props?.selectedData);
      if (props?.selectedData?.counterProposalExist && !sellerAgentSigned) {
        if (props.selectedData.ispdfClone && !props?.selectedData?.sellerAgentSigned) {
          setShowCoModal(true);
          return;
        }
      }

  };

  const handleCoConfirmCancel = () => {
    setShowCoModal(false);
  };
  const handleAlreadySignedSend = () => {
    setShowCoModal(false);
    handleCheckboxChangeBuyer(contractType, "SELLER", true);
  };

  const handleSignOffer = () => {
    setShowCoModal(false);
    dispatch(offerTableHandle.acceptOfferViaResureSignFun({ offerTableAuthKey, offerTablePropertyId, documentId: props?.selectedData?.CBSRId, onlySellerAgentSignRequired: true }));
  };
  return (
    <>
      {updateDocumentLoading && (
        <div style={{ position: "fixed", left: "38%", top: "50%", zIndex: "10" }}>
          <Spin size="large" />
        </div>
      )}
      <>
        <Modal width={800} title={<div style={{ color: "#1890ff", fontSize: "18px", fontWeight: "bold", marginBottom: "5px", textAlign: "center", textDecoration: "underline" }}>Seller Agent Signature Required</div>} size="100px" visible={showCoModal} onCancel={handleCoConfirmCancel} footer={null}>
          <div className={newStyles.signOfferConfirmationMsgDiv}>
            <div>
              <MessageTwoTone style={{ fontSize: "24px" }} />
            </div>
            <div style={{ fontSize: "16px", color: "#777" }}>
            Seller agent should sign the broker disclosure section of the Contract to Buy and Sell Real Estate before the Counterproposal is sent to the buyer's agent.
            </div>
          </div>
          <div className={newStyles.signOfferConfirmationBtns}>
            <Button onClick={handleSignOffer}>Sign Disclosure via RE-Sure Sign</Button>
            <Button type="primary" onClick={handleAlreadySignedSend}>
            I have signed the disclosure, Send
            </Button>
          </div>
        </Modal>


        {contractType !== "TR" ? (
          <div className={newStyles.eachDocumentRowContainer}>
       
            <SellerComp {...props} sellerOpenOfferTable={sellerOpenOfferTable} checked={checked} handleCheckboxChangeBuyer={handleCheckboxChangeBuyer} isSmallScreen={isSmallScreen} isMediumScreen={isMediumScreen}/>

            <DetailComp sellerOpenOfferTable={sellerOpenOfferTable} seller={props} doc={props.contractName} role={props.tags} selectedData={props.selectedData} ispdfClone={props.ispdfClone} userAuthKey={props.userAuthKey} handleCheckboxChangeBuyer={handleCheckboxChangeBuyer} isSmallScreen={isSmallScreen} isMediumScreen={isMediumScreen} allBuyerAgentData={props?.allBuyerAgentData} />

            <BuyerComp sellerOpenOfferTable={sellerOpenOfferTable} {...props} checked={checked} handleCheckboxChangeBuyer={handleCheckboxChangeBuyer} isSmallScreen={isSmallScreen} isMediumScreen={isMediumScreen} />
          </div>
        ) : null}
      </>
    </>
  );
}

export default RtdRow;

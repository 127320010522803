import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Spin,
  message,
} from "antd";
import axios from "axios";
import React, { useState } from "react";
import styles from "../styles/ClientList.module.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const apiURL = process.env.REACT_APP_BASE_URL;

export default function AddClient({
  visible,
  setVisible,
  setClientData,
  accessTokan,
  source,
  openFor,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);


  const onReset = () => {
    form.resetFields();
  };

  const handleCancel = () => {
    setVisible(false);
    onReset();
  };

  const onFinish = (values) => {
    onReset();
    setLoading(true);
    axios
      .post(
        `${apiURL}/customer/createCustomer`,
        {
          ...{ roleType: "CUSTOMER" },
          ...values,
          firstName: values.firstName.charAt(0).toUpperCase() + values.firstName.slice(1),
          middleName: values.middleName ? values.middleName.charAt(0).toUpperCase() + values.middleName.slice(1) : "",
          lastName: values.lastName ? values.lastName.charAt(0).toUpperCase() + values.lastName.slice(1) : "",
          email: values.email.toLowerCase(),
        },
        {
          headers: {
            accept: "application/json",
            authorization: `bearer ${accessTokan}`,
          },
        }
      )
      .then(() => {
        setLoading(false);
        message.success("Contact Created Successfully!");
        axios
          .get(`${apiURL}/realtorCustomerList`, {
            headers: {
              accept: "application/json",
              authorization: `bearer ${accessTokan}`,
            },
          })
          .then((res) => {
            setClientData(res.data.info);
          })
          .catch((error) => {
            // console.log(error);
          });
      })
      .catch((err) => {
        setLoading(false);
        message.error(err?.response?.data?.message || "We encountered an issue while creating the contact. Please try again later.");
      });
  };

  return (
    visible && (
      <Drawer
        title={
          <div className={styles.createtitle} style={{ color: "#8C8C8C" }}>
            Create a new {source === "ContactSheet" ? "Contact" : source === "TRANSACTION_CALENDAR" ? (openFor === "BUYER_AGENT" ? "Buyer" : "Seller") : "Contact"}
          </div>
        }
        placement="right"
        visible={visible}
        onClose={handleCancel}
      >
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "80px",
              height: "200px",
            }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <Form form={form} layout="vertical" onFinish={onFinish} className={styles.formcreate}>
            <Row>
              <Col span={11}>
                <Form.Item name="firstName" label="First Name" className="extraDrawer" rules={[{ required: true, message: "Please Enter First Name!" }]}>
                  <Input placeholder="Enter First Name" allowClear />
                </Form.Item>
              </Col>
              <Col span={11} style={{ marginLeft: "15px" }}>
                <Form.Item name="middleName" label="Middle Name" className="extraDrawer">
                  <Input placeholder="Enter Middle Name" allowClear />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={11}>
                <Form.Item name="lastName" label="Last Name" className="extraDrawer" rules={[{ required: true, message: "Please Enter Last Name" }]}>
                  <Input placeholder="Enter Last Name" allowClear />
                </Form.Item>
              </Col>
              <Col span={11} style={{ marginLeft: "15px" }}>
                <Form.Item
                  name="email"
                  label="Email"
                  className="extraDrawer"
                  hasFeedback
                  rules={[
                    {
                      type: "email",
                      message: "Not a valid Email!",
                    },
                    {
                      required: true,
                      message: "Please Enter Email!",
                    },
                    { whitespace: true },
                  ]}
                >
                  <Input placeholder="Enter Email" allowClear />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={11}>
                <Form.Item
                  name="phoneNumber"
                  label="Phone Number"
                  className="extraDrawer"
                  hasFeedback
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (value?.length) {
                          if (value?.length === 10) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error("Please Enter 10 digit Phone Number!"));
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <PhoneInput
                    inputClass="phoneInput"
                    inputStyle={{
                      width: "100%",
                    }}
                    country={"us"}
                    onlyCountries={["us"]}
                    maxLength="10"
                    international={false}
                    disableCountryCode={true}
                    placeholder="Enter Phone Number"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="notes"
                  label="Notes"
                  className="extraDrawer"
                >
                  <Input.TextArea placeholder="Add Notes" />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="center">
              <Row span={9}>
                <Form.Item>
                  <Button type="primary" style={{ marginRight: "20px" }} htmlType="submit">
                    Save
                  </Button>
                  <Button htmlType="button" onClick={onReset}>
                    Reset
                  </Button>
                </Form.Item>
              </Row>
            </Row>
          </Form>
        )}
      </Drawer>
    )
  );
}

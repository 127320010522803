import React from 'react'
import { formatPhoneNumber } from '../../Common/utils/universalFuntions'
import styles from "../styles/ContactSheet.module.css";
import "../styles/contact.css";
import { Typography } from 'antd';

const { Text } = Typography;

function TitleCompanyTable({individualContactData, data, handleFieldUpdate}) {
    return (
        <>
            {individualContactData?.titleCompanyLogo &&
                <tr>
                    <td colSpan={2} className={styles.image}><img src={individualContactData?.titleCompanyLogo} /></td>
                </tr>}
            <tr>
                <td className={styles.label} onClick={() => handleFieldUpdate({ key: data, ref: { label: "Company", updatingKey: "titleCompanyName" } })}>Company:</td>
                <td className={`${styles.value} ${styles.fullName}`}><span onClick={() => handleFieldUpdate({ key: data, ref: { label: "Company", updatingKey: "titleCompanyName" } })}>{individualContactData?.titleCompanyName || ""}</span></td>
            </tr>
            <tr>
                <td className={styles.label} onClick={() => handleFieldUpdate({ key: data, ref: { label: "Title Office", updatingKey: "titleOfficeName" } })}>Office:</td>
                <td className={`${styles.value} ${styles.fullName}`}><span onClick={() => handleFieldUpdate({ key: data, ref: { label: "Title Office", updatingKey: "titleOfficeName" } })}>{individualContactData?.titleOfficeName || ""}</span></td>
            </tr>
            <tr>
                <td className={styles.label} onClick={() => handleFieldUpdate({ key: data, ref: { label: "Office Address", updatingKey: "titleOfficeAddress" } })}>Address:</td>
                <td className={styles.value}>{individualContactData?.titleOfficeAddress ? <Text style={{ maxWidth: '90%' }} copyable={{ text: individualContactData?.titleOfficeAddress }}><span onClick={() => handleFieldUpdate({ key: data, ref: { label: "Office Address", updatingKey: "titleOfficeAddress" } })}>{individualContactData?.titleOfficeAddress}</span></Text> : ""}</td>
            </tr>
            <tr>
                <td className={styles.label} onClick={() => handleFieldUpdate({ key: data, ref: { label: "Office Email", updatingKey: "titleOfficeEmail" } })}>Email:</td>
                <td className={styles.value}>{individualContactData?.titleOfficeEmail ? <Text copyable={{ text: individualContactData?.titleOfficeEmail }}><span onClick={() => handleFieldUpdate({ key: data, ref: { label: "Office Email", updatingKey: "titleOfficeEmail" } })}>{individualContactData?.titleOfficeEmail}</span></Text> : ""}</td>
            </tr>
            <tr>
                <td className={styles.label} onClick={() => handleFieldUpdate({ key: data, ref: { label: "Office Ph.", updatingKey: "titleOfficePhoneNumber" } })}>Phone:</td>
                <td className={styles.value}>{individualContactData?.titleOfficePhoneNumber ? <Text copyable={{ text: individualContactData?.titleOfficePhoneNumber }}><span onClick={() => handleFieldUpdate({ key: data, ref: { label: "Office Ph.", updatingKey: "titleOfficePhoneNumber" } })}>{formatPhoneNumber(individualContactData?.titleOfficePhoneNumber)}</span></Text> : ""}</td>
            </tr>
            <tr>
                <td className={styles.label} onClick={() => handleFieldUpdate({ key: data, ref: { label: "Office Fax", updatingKey: "titleOfficeFax" } })}>Fax:</td>
                <td className={styles.value}>{individualContactData?.titleOfficeFax ? <Text copyable={{ text: individualContactData?.titleOfficeFax }}><span onClick={() => handleFieldUpdate({ key: data, ref: { label: "Office Fax", updatingKey: "titleOfficeFax" } })}>{formatPhoneNumber(individualContactData?.titleOfficeFax)}</span></Text> : ""}</td>
            </tr>
            <tr>
                <td className={styles.label} onClick={() => handleFieldUpdate({ key: data, ref: { label: "Sales Rep", updatingKey: "salesRepName" } })}>Sales Rep:</td>
                <td className={`${styles.value} ${styles.fullName}`}><span onClick={() => handleFieldUpdate({ key: data, ref: { label: "Sales Rep", updatingKey: "salesRepName" } })}>{individualContactData?.salesRepName || ""}</span></td>
            </tr>
            <tr>
                <td className={styles.label} onClick={() => handleFieldUpdate({ key: data, ref: { label: "Sales Rep Email", updatingKey: "salesRepEmail" } })}>Sales Rep Email:</td>
                <td className={styles.value}>{individualContactData?.salesRepEmail ? <Text copyable={{ text: individualContactData?.salesRepEmail }}><span onClick={() => handleFieldUpdate({ key: data, ref: { label: "Sales Rep Email", updatingKey: "salesRepEmail" } })}>{individualContactData?.salesRepEmail}</span></Text> : ""}</td>
            </tr>
            <tr>
                <td className={styles.label} onClick={() => handleFieldUpdate({ key: data, ref: { label: "Sales Rep Ph.", updatingKey: "salesRepPhoneNumber" } })}>Sales Rep Ph.:</td>
                <td className={styles.value}>{individualContactData?.salesRepPhoneNumber ? <Text copyable={{ text: individualContactData?.salesRepPhoneNumber }}><span onClick={() => handleFieldUpdate({ key: data, ref: { label: "Sales Rep Ph.", updatingKey: "salesRepPhoneNumber" } })}>{formatPhoneNumber(individualContactData?.salesRepPhoneNumber)}</span></Text> : ""}</td>
            </tr>
            <tr>
                <td className={styles.label} onClick={() => handleFieldUpdate({ key: data, ref: { label: "Title Closer", updatingKey: "titleCloserName" } })}>Closer:</td>
                <td className={`${styles.value} ${styles.fullName}`}>{individualContactData?.titleCloserName ? <span onClick={() => handleFieldUpdate({ key: data, ref: { label: "Title Closer", updatingKey: "titleCloserName" } })}>{individualContactData?.titleCloserName}</span> : ""}</td>
            </tr>
            <tr>
                <td className={styles.label} onClick={() => handleFieldUpdate({ key: data, ref: { label: "Closer Email", updatingKey: "titleCloserEmail" } })}>Closer Email:</td>
                <td className={styles.value}>{individualContactData?.titleCloserEmail ? <Text copyable={{ text: individualContactData?.titleCloserEmail }}><span onClick={() => handleFieldUpdate({ key: data, ref: { label: "Closer Email", updatingKey: "titleCloserEmail" } })}>{individualContactData?.titleCloserEmail}</span></Text> : ""}</td>
            </tr>
            <tr>
                <td className={styles.label} onClick={() => handleFieldUpdate({ key: data, ref: { label: "Closer Ph.", updatingKey: "titleCloserPhone" } })}>Closer Ph.:</td>
                <td className={styles.value}>{individualContactData?.titleCloserPhone ? <Text copyable={{ text: individualContactData?.titleCloserPhone }}><span onClick={() => handleFieldUpdate({ key: data, ref: { label: "Closer Ph.", updatingKey: "titleCloserPhone" } })}>{formatPhoneNumber(individualContactData?.titleCloserPhone)}</span></Text> : ""}</td>
            </tr>
        </>
    )
}

export default TitleCompanyTable
import React, { useEffect, useState } from "react";
import { Avatar, Form, Modal, Table, Tooltip } from "antd";
import { PlusCircleFilled,CheckCircleOutlined ,CloseCircleOutlined} from "@ant-design/icons";
import { CreateTitlecompany } from "./CreateTitlecompany";
import { firmDetailsHandle } from "../../FirmCreation/state/action";
import { useDispatch, useSelector } from "react-redux";
import { TitleCompanyHandle } from "../state/action";
import { Loading } from "../../Common";

const TitleCompanyCreation = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const titleCompanyCreationColoums = [
    {
      title: 'TITLE COMPANY CREATED',
      dataIndex: 'titleType',
      key: 'titleType',
      render: (text) => (
        <div>
          <div>{text}</div>
          </div>
      ),
    },
    {
      title: 'CREATED BY',
      dataIndex: 'nameemail',
      key: 'nameemail',
      width: '16%',
      render: (text) => (
        <div>

        <div >
          <div>{text?.fullName}</div>
          <div style={{ fontSize: "11px", color: "#787474" }}>
            {text?.email ? text.email : ""}
          </div>
        </div>
        </div>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      width: '12%',
      render: (isVerified) => <div>
          {
              isVerified ? <div style={{display: "flex", gap: "5px", alignItems: "center"}}>
                  <CheckCircleOutlined style={{color: "#4bd33a", fontSize: "18px"}}/>
                  Verified
                  </div> : <div style={{display: "flex", gap: "5px", alignItems: "center"}}>
                      <CloseCircleOutlined style={{color: "#e73939", fontSize: "18px"}}/>
                      Not Verified
                  </div>
          }
      </div>,
  },
    {
      title: 'TITLE COMPANY',
      dataIndex: 'company',
      key: 'company',
      width: '23%',
      render: (text) => (
        <div style={{display:"flex"}}>
        <div style={{paddingTop:"10px"}}>{text?.officeLogo?<Avatar shape="circle" size={32} src={text?.officeLogo}  />:""}</div>
        <div style={{marginLeft:"10px"}}>
          <div style={{ fontSize: "13px", color: "black" }}>{text?.name}</div>
          <div style={{ fontSize: "11px", color: "#787474" }}>
          {text?.address?`Address : ${text?.address}`:""}
          </div>
          </div>
        </div>
      ),
    },
    {
      title: 'TITLE OFFICE',
      dataIndex: 'office',
      key: 'office',
      width: '23%',
      render: (text) => (
        <div>
          <div style={{ fontSize: "13px", color: "black" }}>{text?.name}</div>
          <div style={{ fontSize: "11px", color: "#787474" }}>
          {text?.address?`Address : ${text?.address}`:""}
          </div>
        </div>
      ),
    },
  ];

  const handleCancelModal = () => {
    form.resetFields();
    setShowCreateModal(false);
    dispatch(firmDetailsHandle.resetImageUrl());
  };

  useEffect(()=>{
  dispatch(TitleCompanyHandle.getTitleCompanyTypes("createTitleCompany"))
  },[])
  const {gettitleCompanyData,gettitleCompanyLoading} = useSelector((state)=>state.titleCompanyDetails)
return (
    <div>
      <Modal
        visible={showCreateModal}
        style={{ top: 20,  minWidth: "620px", maxWidth: "1000px"  }}
        footer={false}
        onCancel={handleCancelModal}
        onOk={() => {}}
      >
        <CreateTitlecompany setShowCreateModal={setShowCreateModal} form={form} />
      </Modal>
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "5px 30px 10px 10px",
          }}
        >
          <div>
            <h1>Lists of Created Title Company</h1>
          </div>
          <Tooltip title={"Create Title Company"} placement="left" color={"#085191"}>
            <PlusCircleFilled
              style={{
                fontSize: "30px",
                fill: "#085191",
                color: "#085191",
              }}
              onClick={() => {
                setShowCreateModal(true);
              }}
            />
          </Tooltip>
        </div>
        {gettitleCompanyLoading?<Loading/>:<Table
          columns={titleCompanyCreationColoums}
          scroll={{ y: "74vh" }}
          dataSource={gettitleCompanyData}
          bordered
          pagination={false}
        />}
        <div>
        </div>
      </>
    </div>
  );
};

export { TitleCompanyCreation };

import { newsConstants } from "./action-types";

const initialState = {
  newsData: [],
  newsDataLoading: false,
  newsDataError: null,
};

const news = (state = initialState, action) => {
  switch (action.type) {
        case newsConstants.GET_NEWS_LOADING:
            return {
              ...state,
              newsData: [],
              newsDataLoading: true,
              newsDataError: null,
            };
        case newsConstants.GET_NEWS_SUCCESS:
            return {
              ...state,
              newsData: action?.newsData?.data,
              totalRecords: action?.newsData?.totalRecords,
              newsDataLoading: false,
              newsDataError: null,
            };
        case newsConstants.HELP_GLOBAL_SEARCH_ERROR:
            return {
              ...state,
              newsData: [],
              newsDataLoading: false,
              newsDataError: action.helpDataError,
            };

    default:
      return state;
  }
};
export { news };

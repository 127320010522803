import { Image, Modal } from 'antd';
import React, { useState } from 'react';

const HtmlContentWithClick = ({ htmlContent }) => {
  const [imgPreview, setImgPreview] = useState(null);
  const [visible, setVisible] = useState(false);

  const handleImageClick = (src) => {
    setImgPreview(src);
    setVisible(true);
  };

  const handleCancelImage = () => {
    setVisible(false);
  };

  // Function to attach click event handlers to images
  const attachClickHandlersToImages = () => {
    const images = document.querySelectorAll('img');

    images.forEach((image) => {
      image.addEventListener('click', () => {
        handleImageClick(image.src);
      });
    });
  };

  // Run the function to attach click handlers when the component mounts
  React.useEffect(() => {
    attachClickHandlersToImages();
  }, []);

  const styling = `<style>
                    img{
                        object-fit:contain !important;
                    }
                    </style>`
  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: htmlContent + styling }} />
      <Modal
        visible={visible}
        onCancel={handleCancelImage}
        footer={[]}
        width={900}
        style={{marginTop:"-90px",maxHeight:"700px"}}
      >
        <div
          style={{
            textAlign: "center",
          }}
        >
          <Image width={'100%'} style={{maxHeight:"700px"}} preview={false} src={imgPreview} />
        </div>
      </Modal>
    </div>
  );
};

export default HtmlContentWithClick;

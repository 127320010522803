
import { TitleCompanyConstants } from "./action-types";

const initialState = {
  titleCompanyData: [],
  titleCompanyLoading: false,
  titleCompanyError: false,

  
  gettitleCompanyData: [],
  gettitleCompanyLoading: false,
  gettitleCompanyError: false,
 
};
const company=(element)=>{
  return {
    ...((element?.titleType === "TITLE_COMPANY") ? {name: element.name} :  {name: null}),
    officeLogo:element?.officeLogo?element?.officeLogo:"",
     address: ((element?.titleType === "TITLE_COMPANY") ? (element?.address?.streetNumber + " " + element?.address?.streetName + ", " + 
     element?.address?.unit  + element?.address?.city + ", " + element?.address?.state + " " + 
     element?.address?.zipCode) : null),
}
}
const office=(element)=>{
  return {
    ...((element?.titleType === "TITLE_OFFICE") ? {name: element.name} :  {name: null}),
    
     address: ((element?.titleType === "TITLE_OFFICE") ? (element?.address?.streetNumber + " " + element?.address?.streetName + ", " + 
     element?.address?.unit  + element?.address?.city + ", " + element?.address?.state + " " + 
     element?.address?.zipCode) : null),
}
}
const titleCompanyDetails = (state = initialState, action) => {
  
  switch (action.type) {
    
    // Create a new titleCompany
    case TitleCompanyConstants.CREATE_TITLECOMPANY_LOADING:
      return {
        ...state,
        titleCompanyLoading: true,
        titleCompanyError: false,
      };
    case TitleCompanyConstants.CREATE_TITLECOMPANY__SUCCESS:
      
      return {
        ...state,
        titleCompanyData: action.TitleCompanydata,
        titleCompanyLoading: false,
        titleCompanyError: false,

      };
    case TitleCompanyConstants.CREATE_TITLECOMPANY__FAILURE:
      return {
        ...state,
        titleCompanyLoading: false,
        titleCompanyError: false,
      };
      case TitleCompanyConstants.GET_TITLECOMPANY_LOADING:
        return {
          ...state,
          gettitleCompanyLoading: true,
          gettitleCompanyError: false,
        };
      case TitleCompanyConstants.GET_TITLECOMPANY_SUCCESS:
      const datainfo=[]
       action.TitleCompanydata.forEach((ele)=>{
        const companydata=company(ele)
        const officedata=office(ele)
        datainfo.push({
          nameemail:{
            fullName:ele?.createdBy?.fullName,
            email:ele?.createdBy?.email,
             },
          titleType:ele?.titleType=="TITLE_COMPANY"?"COMPANY":"OFFICE",
          office:officedata,
          status: ele.isVerified,
          company:companydata,
          details:{
            name:ele?.name,
            streetNumber:ele?.address.streetNumber,
            streetName:ele?.address.streetName,
            unit:ele?.address?.unit,
            city:ele?.address?.city,
            state:ele?.address?.state
          },


        })
       })
        return {
          ...state,
          gettitleCompanyData: datainfo,
          getdata:action.TitleCompanydata,
          gettitleCompanyLoading: false,
          gettitleCompanyError: false,
        };
      case TitleCompanyConstants.GET_TITLECOMPANY_FAILURE:
        return {
          ...state,
          gettitleCompanyLoading: false,
          gettitleCompanyError: false,
        };
    default:
      return state;
  }
};
export { titleCompanyDetails };

import axios from "axios";
const apiURL = process.env.REACT_APP_BASE_URL;

//Create Api
const postHelpDataApi = (data) => {
    let accessTokan = localStorage.getItem("auth");
    data.accessibleTO = [...data.accessibleTO,'SUPER_ADMIN'];
    return axios.post(`${apiURL}/help`,data,{
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    })
  }

 //Edit Api
const editHelpDataApi = (data,id) => {
    let accessTokan = localStorage.getItem("auth");
    return axios.put(`${apiURL}/help/${id}`,data,{
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    })
  }

  //Get Api
const getHelpData = (query,page,limit=10,search=null) => {
    let accessTokan = localStorage.getItem("auth");
    if(query){
    return axios.get(`${apiURL}/help?section=${query}`,{
        params:{page,limit},
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    })
   }else if(search){
    return axios.get(`${apiURL}/help?search=${search}&platForm=ADMIN`,{
        params:{page,limit},
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    })
   }else{
    return axios.get(`${apiURL}/help`,{
        params:{page,limit},
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    })
   }
  }

  //Get Search Api
  // const getHelpSeachData = (query,page,limit) => {
  //   let accessTokan = localStorage.getItem("auth");
  //   return axios.get(`${apiURL}/help?search=${query}&platForm=ADMIN`,{
  //       params:{page,limit},
  //     headers: {
  //       accept: "application/json",
  //       authorization: `bearer ${accessTokan}`,
  //     },
  //   })
  // }

  //Delete  Api
  const deleteHelpDataApi = (id) => {
    let accessTokan = localStorage.getItem("auth");
    return axios.delete(`${apiURL}/help/${id}`,{
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    })
  }

  //Upload image to bucket
  const uploadImageRequest = (data, origin) => {
    let accessTokan = localStorage.getItem("auth");
    var params = new FormData();
    params.append("fileBase64", data);
    params.append("folderOf", "BUGS");

    return axios.post(`${apiURL}/common/uploadFile`, params, {
      headers: {
        "Content-Type": "multipart/form-data",
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
}

//get Roles api
const getRolesDataApi = (query) => {
    let accessTokan = localStorage.getItem("auth");
    return axios.get(`${apiURL}/getHelpSections?toggle=${query}`,{
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    })
  }

  const getSectionsDataApi = (query) => {
    let accessTokan = localStorage.getItem("auth");
    return axios.get(`${apiURL}/getHelpSections?toggle=${query}`,{
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    })
  }

export const helpDataApi = {
    postHelpDataApi,
    getHelpData,
    // getHelpSeachData,
    deleteHelpDataApi,
    editHelpDataApi,
    uploadImageRequest,
    getRolesDataApi,
    getSectionsDataApi,
};

import React, { useEffect, useState } from 'react'
import { Button, Collapse, Form, Popconfirm } from 'antd'
import { NewsCreateModal } from './components/NewsCreateModal';
import { useDispatch, useSelector } from 'react-redux';
import { newsApis } from './state/actions';
import { EmptyData } from '../Common/components/EmptyData';
import {DeleteOutlined,FormOutlined} from "@ant-design/icons";
import { NewsLetterDesign } from './components/NewsLetterDesign';
const NewsLetter = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch();
    const { Panel } = Collapse;
    const [form] = Form.useForm();
    const {newsData} = useSelector(state=>state.news);
    const showModal = () => {
      setIsModalOpen(true);
    };

    const handleCancel = (CleanUpFunc) => {
      setIsModalOpen(false);
      if(CleanUpFunc){
        CleanUpFunc();
        form.resetFields();
      }
    };

    //Delete questions
   const handeDelete =(id)=>{
    dispatch(newsApis.deleteNewsData(id));
    }

    useEffect(() => {
      dispatch(newsApis.getNewsData());
    }, [dispatch])

  return (
    <div style={{background:"white",height:"100%"}}>
     <NewsCreateModal handleCancel={handleCancel} isModalOpen={isModalOpen} form={form}/>
     <div style={{display:"flex",justifyContent:"center",paddingTop:"10px"}}>
        <Button  type='primary' onClick={showModal} >Create News Letter</Button>
     </div>

     <div style={{width:"95%",margin:"auto",marginTop:"10px",}}>
     <Collapse accordion bordered={true} >
            {newsData && newsData.length > 0 ? newsData?.map((el, ind) => (
                <Panel style={{textAlign:"left",fontWeight:"bold",}}
                header={<div style={{display:"flex",flexDirection:"row"}}>
                        <div style={{width:"90%",marginLeft:"20px"}}>{el?.heading}</div>
                         <div style={{display:"flex"}}>
                        {/* <div>
                        <FormOutlined onClick={(e)=>{e.stopPropagation(); handleEdit(el)}} style={{color:"green",marginLeft:"15px",fontSize:"18px"}}/>
                        </div> */}
                        <div
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                            >
                              <Popconfirm
                                 title="Are you sure you want to delete this News Letter?"
                                 onConfirm={() => {
                                     handeDelete(el._id);
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                    >
                              <DeleteOutlined  style={{ fontSize: "18px",color:"red",marginLeft:"10px" }} />
                             </Popconfirm>
                        </div>
                        </div>
                        </div>}
                        key={ind.toString()}>
                            <div style={{height:"400px",overflow:"auto"}}>
                              <NewsLetterDesign el={el}/>
                            </div>
              </Panel>
            )) : <div style={{paddingTop:"30%",height:"600px"}}><EmptyData/></div>}
          </Collapse>
     </div>
    </div>
  )
}

export  {NewsLetter}

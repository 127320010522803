import axios from "axios";

const apiURL = process.env.REACT_APP_BASE_URL;

const getFJGGAccessRequest = (query) => {
    let accessTokan = localStorage.getItem("auth");
    let url = `${apiURL}/FJGGAccess?source=ADMIN`;

    if (query) {
        url += `&search=${query}`;
    }

    return axios.get(url, {
        headers: {
            accept: "application/json",
            authorization: `bearer ${accessTokan}`,
        },
    });
};


  const grantFJGGAccessRequest = (data) => {
    let accessTokan = localStorage.getItem("auth");
    return axios.post(`${apiURL}/confirm`,data,{
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  };

export const fjggApis = {
  getFJGGAccessRequest,
  grantFJGGAccessRequest,
};

import React, { useState } from "react";
import JsxParser from "react-jsx-parser";
import { Modal, Row, Input, Button } from "antd";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import HTMLtoJSX from "htmltojsx";
import { postPdfHandle } from "../state/actions";

import "../../CreateOffer/styles/RenderJsx.css";
import { Redirect, useParams } from "react-router-dom";
import { data } from "./datajsx";
import { upload } from "./pdfDatajsx";
import { roleAccessFunc } from "../../Common/commondata/commonData";
// import { htmlrender } from "../../NDA/components/DataForNDA";

function PreviewContract(props) {
  const { id } = useParams();
  const dispatch = useDispatch();



  const [visible, setVisible] = useState(false);
  const [password, setPassword] = useState("");
  let { sendPreviewData } = useSelector((state) => state.pdfUpload);
  const { adminRole, roleAccessed } = useSelector((state) => state.account, shallowEqual);

  if (!roleAccessFunc(roleAccessed, "PREVIEW")) {
    return <Redirect to="/error" />;
  }

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleSubmit = () => {
    setVisible(true);
  };

  let handlesave = async () => {
    // setLoading(true);

    let getHTML = document.getElementsByClassName("jsx-parser")[0];
    let converter = new HTMLtoJSX({
      createClass: false,
    });

    let output = converter.convert(getHTML.innerHTML);
    // let output = upload;

    let offerTableValue = {
      "Due Diligence Docs": "Due Diligence Docs",
      "Highest Escalated Price": "Highest Escalated Price",
      "Extra Inclusions": "Extra Inclusions",
      Exclusions: "Exclusions",
      "Loan Termination Deadline": "Loan Termination Deadline",
      "Inspection Termination Deadline": "Inspection Termination Deadline",
      "Inspection Objection Deadline": "Inspection Objection Deadline",
      "Closing Date": "Closing Date",
      "Possession Date": "Possession Date",
      "Props Ins Termination Deadline": "Props Ins Termination Deadline",
      "Earnest Money": "Earnest Money",
      Price: "Price",
      Concessions: "Concessions",
      "Loan Type": "Loan Type",
      "Closing Fee": "Closing Fee",
      "Status Letter": "Status Letter",
      "Record Change Fee": "Record Change Fee",
      "Water Transfer Fee": "Water Transfer Fee",
      "Additional Prov": "Additional Prov",
      "Down Payment": "Down Payment",
      "Point In Approval": "Point In Approval",
      "Possession Penalty": "Possession Penalty",
      "Rent Back Cost": "Rent Back Cost",
      "Rent Back Deposit": "Rent Back Deposit",
      "Time To Decide": "Time To Decide",
      "Items Suggested To Counter": "Items Suggested To Counter",
      "Offer Package Supplements": "Offer Package Supplements",
      Notes: "Notes",
    };
    // boldElements = data;

    // uploadData === originalJSX
    // imp parameters
    // id,
    //   htmlString,
    //   offerTableData,
    //   boldElements or data 'taken from file'
    dispatch(
      postPdfHandle.sendPreview(
        id,
        password,
        // DataForNDA,
        output,
        offerTableValue,
        data, //boldElements
        // uploadData //original data
        upload
      )
    );
    setVisible(false);
  };

  if (sendPreviewData) {
    return <Redirect to="/listing" />;
  }

  return (
    <>
      <div>
        <div style={{ overflow: "scroll" }}>
          <>
            <Row
              style={{
                position: "fixed",
                zIndex: "1",
                top: "80%",
                right: "6%",
              }}
            >
              <Modal title="Enter Your Password" visible={visible} onOk={handleOk} onCancel={handleCancel} footer={false}>
                <Input placeholder="Please Enter Your Password" onChange={(e) => setPassword(e.target.value)} />
                <Button onClick={handlesave} style={{ marginLeft: "200px", marginTop: "20px", backgroundColor: "cornflowerblue", color: "white" }}>
                  Save
                </Button>
              </Modal>
              <Button type="primary" onClick={handleSubmit} danger>
                Submit
              </Button>
            </Row>
            <Row justify="center" style={{ height: "100vh" }}>
              <div id="htmlElements" style={{}}>
                {/* <JsxParser jsx={DataForNDA} /> */}

                <JsxParser jsx={upload} />

                {/* //original data */}
                {/* <JsxParser jsx={uploadData} /> */}
                {/* <JsxParser jsx={data} /> */}
              </div>
            </Row>
            {/* for testing purpose */}
            {/* <Row justify="center" style={{ height: "100vh" }}>
        {boldElements &&
          boldElements.map((ele, index) => {
            return <JsxParser key={index} jsx={ele} />;
          })}
      </Row> */}
          </>
        </div>
      </div>
    </>
  );
}
export { PreviewContract };

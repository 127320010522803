import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Switch,
  Tooltip,
} from "antd";
import React from "react";
import { useState } from "react";
import {
  PlusCircleFilled,
  UserOutlined,
  LockOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import PhoneInput from "react-phone-input-2";
import moment from "moment";
import "../Authenticate/styles/signup.css";
import styles from "../Authenticate/styles/Admin.module.css";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { accountAction } from "./state/actions";

import ManageUser from "./components/ManageUser";
import { Redirect } from "react-router-dom";
import { capitalizeStr } from "../Common/utils/universalFuntions";

const { Option } = Select;

function validateMessages(label) {
  return {
    required: `${label} is required!`,
    types: {
      email: `${label} is not a valid email!`,
      number: `${label} is not a valid number!`,
    },
  };
}

function disabledDate(current) {
  return current && current > moment().subtract(16, "years");
}
// not in use
export default function Accounts() {
  const [email, setEmail] = useState("");
  const [createUser, setCreateUser] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { adminRole, roleAccessed, getUserDetailsData } = useSelector(
    (state) => state.account,
    shallowEqual
  );
   let onFinish = (event) => {
    const signUpData = {
      firstName: capitalizeStr(event.firstName),
      lastName: capitalizeStr(event.lastName),
      email: event.email.toLowerCase(),
      phoneNumber: event.phoneNumber,
      password: event.password,
      birthDate: event.birthDate,
      ...(event.roleType === "ADMIN_OFFICE"
        ? { adminRole: event.roleType }
        : { role: event.roleType }),
      accountLinkedTo: getUserDetailsData._id,
    };
    handleCancelModal();
    dispatch(accountAction.realterSignup(signUpData));
  };

  const handleCancelModal = () => {
    form.resetFields();
    setCreateUser(false);
  };


  if (roleAccessed && adminRole && !roleAccessed[adminRole]["ACCOUNTS"]) {
    return <Redirect to="/error" />;
  }

  return (
    <>
       {roleAccessed && adminRole && roleAccessed[adminRole]["ACCOUNTS"] ? (
        <>
          <Modal
            visible={createUser}
            // style={{ top: 20,}}
            footer={false}
            onCancel={handleCancelModal}
            onOk={() => {}}
          >
            <div>
              <div
                style={{
                  fontSize: "20px",
                  textAlign: "center",
                  marginBottom: "15px",
                }}
              >
                Create User
              </div>
              <Form
                layout="vertical"
                name="1normal_login_page1"
                onFinish={onFinish}
                validateMessages={validateMessages}
                scrollToFirstError
                style={{ width: "100%" }}
              >
                <Row
                  justify="space-between"
                  gutter={0}
                  style={{ width: "100%" }}
                >
                  <Col span={11}>
                    <Form.Item
                      name="firstName"
                      label="First Name"
                      wrapperCol={{ span: 24 }}
                      rules={[
                        { required: true, message: "Please Enter First Name!" },
                      ]}
                    >
                      <Input placeholder="Enter First Name" allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item
                      name="lastName"
                      label="Last Name"
                      wrapperCol={{ span: 24 }}
                      rules={[
                        { required: true, message: "Please Enter Last Name!" },
                      ]}
                    >
                      <Input placeholder="Enter Last Name" allowClear />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "Not a valid Email!",
                    },
                    {
                      required: true,
                      message: "Please Enter Email!",
                    },
                    { whitespace: true },
                  ]}
                >
                  <Input
                    prefix={
                      <UserOutlined
                        className="site-form-item-icon"
                        style={{ marginRight: "15px" }}
                      />
                    }
                    placeholder="Enter Email"
                    allowClear
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Item>

                <Row gutter={0} justify="space-between">
                  <Col span={11}>
                    <Form.Item
                      name="password"
                      label="Password"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              getFieldValue("password") !== email &&
                              value?.length > 5 &&
                              value?.includes(" ") === false
                            ) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                new Error(
                                  "Required minimum 6 characters password!"
                                )
                              );
                            }
                          },
                         }),
                      ]}
                    >
                      <Input.Password
                        prefix={
                          <LockOutlined
                            className="site-form-item-icon"
                            style={{ marginRight: "15px" }}
                          />
                        }
                        maxLength={20}
                        type="password"
                        placeholder="Enter Password"
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item
                      name="confirmpassword"
                      label="Confirm Password"
                      rules={[
                        {
                          required: true,
                          message: "",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }

                            return Promise.reject(
                              new Error(
                                "The password that you entered do not match!"
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        prefix={
                          <LockOutlined
                            className="site-form-item-icon"
                            style={{ marginRight: "15px" }}
                          />
                        }
                        type="password"
                        maxLength={20}
                        placeholder="Enter Password"
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={0} justify="space-between">
                  <Col span={11}>
                    <Form.Item
                      name="phoneNumber"
                      label="Phone Number"
                      hasFeedback
                      rules={[
                        { required: true, message: "" },
                        { whitespace: true },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (value?.length === 10) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error("Please Enter 10 digit Phone Number!")
                            );
                          },
                        }),
                      ]}
                    >
                      <PhoneInput
                        // isValid={true}
                        inputStyle={{
                          width: "98px",
                        }}
                        inputClass="phoneInput"
                        country={"us"}
                        onlyCountries={["us"]}
                        maxLength="10"
                        international={false}
                        disableCountryCode={true}
                        placeholder="Enter Phone Number"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item
                      name="birthDate"
                      label="Birth Date"
                     
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        placeholder=" Select Birthday"
                        format="MM-DD-YYYY"
                        disabledDate={disabledDate}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  name="roleType"
                  label="Select Role"
                  rules={[
                    {
                      required: true,
                      message: "Please Select A Role!",
                    },
                  ]}
                >
                  <Select placeholder="Please Select a Role" allowClear>
                    {/* <Option value="ADMIN_OFFICE">Admin Office</Option> */}
                    <Option value="USER">User</Option>
                    <Option value="AGENT">Agent</Option>
                    <Option value="REALTOR">Realtor</Option>
                    <Option value="TRANSACTION_COORDINATOR">
                      Transaction Co-ordinator
                    </Option>
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    type="primary"
                    size="large"
                    className={styles.btnPrimary}
                    // loading={userSignupLoading}
                  >
                    Add User
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Modal>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "15px",
            }}
          > */}
            {/* <div style={{display:"1px solid green"}}></div> */}
            <div style={{ fontSize: "20px", fontWeight: 600,textAlign:"center"}}>
              Create And Manage Accounts
            </div>
          <ManageUser />
        </>
      ) : null}
    </>
  );
}

import { cmsConstants } from "./action-types";

const initialState = {
  personData: null,
  personLoading: null,
  personError: null,
  totalItems: 1,
  changeStatusData: null,
  changeStatusLoading: null,
  changeStatusError: null,

  verifyEmailLoading: true,
  verifyEmailError: null,
  verifyEmailData: null,

  associationDataLoading : false,
  associationDataError : false,
  associationData : null,

  addAssociationLoading: false,

  viewSignupLinkLoading : false,
  viewSignupLinkDataError : false,
  viewSignupLinkData : null,
  toggleLoading: false

};

const cms = (state = initialState, action) => {
  switch (action.type) {
    case cmsConstants.GET_PERSONS_LOADING:
      return {
        ...state,
        personLoading: true,
        personError: null,
      };
    case cmsConstants.GET_PERSONS_SUCCESS:
      return {
        ...state,
        personData: action.personData,
        totalItems: action.totalItems,
        personLoading: false,
        personError: null,
      };
    case cmsConstants.GET_PERSONS_FAILURE:
      return {
        ...state,
        personData: [],
        personLoading: action.personLoading,
        personError: action.personError,
      };

    case cmsConstants.USER_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        changeStatusData: action.changeStatusData,
        changeStatusLoading: action.changeStatusLoading,
        changeStatusError: null,
      };
    case cmsConstants.USER_CHANGE_STATUS_FAILURE:
      return {
        ...state,
        changeStatusData: [],
        changeStatusLoading: action.changeStatusLoading,
        changeStatusError: action.changeStatusError,
      };

    case cmsConstants.USER_EMAIL_VERIFY_FAILURE:
      return {
        ...state,
        verifyEmailError: action.verifyEmailError,
        verifyEmailLoading: action.verifyEmailLoading,
      };
    case cmsConstants.USER_EMAIL_VERIFY_SUCCESS:
      return {
        ...state,
        verifyEmailData: action.verifyEmailData,
        verifyEmailLoading: action.verifyEmailLoading,
      };
       // filter admin role
       case cmsConstants.GET_FILTER_ADMINROLE_LOADING:
        return {
          ...state,
          personLoading: true,
          personError: null,
        };
      case cmsConstants.GET_FILTER_ADMINROLE_SUCCESS:
        return {
          ...state,
          personData: action.personData,
          totalItems: action.totalItems,
          personLoading: false,
          personError: null,
        };
      case cmsConstants.GET_FILTER_ADMINROLE_ERROR:
        return {
          ...state,
          personData: [],
          personLoading: action.personLoading,
          personError: action.personError,
        };
        // filter role
      case cmsConstants.GET_FILTER_ROLE_LOADING:
        return {
          ...state,
          personLoading: true,
          personError: null,
        };
      case cmsConstants.GET_FILTER_ROLE_SUCCESS:
        return {
          ...state,
          personData: action.personData,
          totalItems: action.totalItems,
          personLoading: false,
          personError: null,
        };
      case cmsConstants.GET_FILTER_ROLE_ERROR:
        return {
          ...state,
          personData: [],
          personLoading: action.personLoading,
          personError: action.personError,
        };
      //filter active/deactived
       case cmsConstants.GET_FILTER_ISACTIVE_LOADING:
        return {
          ...state,
          personLoading: true,
          personError: null,
        };
      case cmsConstants.GET_FILTER_ISACTIVE_SUCCESS:
        return {
          ...state,
          personData: action.personData,
          totalItems: action.totalItems,
          personLoading: false,
          personError: null,
        };
      case cmsConstants.GET_FILTER_ISACTIVE_ERROR:
        return {
          ...state,
          personData: [],
          personLoading: action.personLoading,
          personError: action.personError,
        };
        //sort created at
        case cmsConstants.GET_SORT_CREATEDAT_LOADING:
            return {
              ...state,
              personLoading: true,
              personError: null,
            };
          case cmsConstants.GET_SORT_CREATEDAT_SUCCESS:
            return {
              ...state,
              personData: action.personData,
              totalItems: action.totalItems,
              personLoading: false,
              personError: null,
            };
          case cmsConstants.GET_SORT_CREATEDAT_ERROR:
            return {
              ...state,
              personData: [],
              personLoading: action.personLoading,
              personError: action.personError,
            };
            case cmsConstants.ASSOCIATION_LOADING:
              return {
                ...state,
                associationDataLoading : true,
                associationDataError : false,
              };
            case cmsConstants.ASSOCIATION_ERROR:
              return {
                ...state,
                associationDataLoading : false,
                associationDataError : true,
              };
            case cmsConstants.ASSOCIATION_SUCCESS:
              return {
                ...state,
                associationData : [...action.associationData],
                associationDataLoading : false,
                associationDataError : false,
              };  
              case cmsConstants.TOGGLE_LOADING:
                return {
                  ...state,
                  toggleLoading: action.toggleLoading
                };
              case cmsConstants.ADD_ASSOCIATION_LOADING :
                return {
                  ...state,
                  addAssociationLoading: true,
                };
              case cmsConstants.ADD_ASSOCIATION_SUCCESS:
                return {
                  ...state,
                  addAssociationLoading: false,
        
                };
              case cmsConstants.ADD_ASSOCIATION_ERROR:
                return {
                  ...state,
                  addAssociationLoading: false,
                };  

                case cmsConstants.VIEW_ASSOCIATION_LOADING:
                  return {
                    ...state,
                    viewSignupLinkLoading : true,
                    viewSignupLinkDataError : false,
                  };
                case cmsConstants.VIEW_ASSOCIATION_ERROR:
                  return {
                    ...state,
                    viewSignupLinkLoading : false,
                    viewSignupLinkDataError : true,
                  };
                case cmsConstants.VIEW_ASSOCIATION_SUCCESS:
                  return {
                    ...state,
                    viewSignupLinkData : action.viewSignupLinkData,
                    viewSignupLinkLoading : false,
                    viewSignupLinkDataError : false,
                  };    

    default:
      return state;
  }
};
export { cms };

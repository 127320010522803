let calDa = [
  {
    eventName: "New Year's Day",
    eventDate: "2022-01-01",
    eventDay: "01",
    eventMonth: "01",
    id: 2182247,
    resourceId: 63534,
    title: "New Year's Day",
    start: "2022-01-01T00:00:00.000Z",
    end: "2022-01-01T00:00:00.000Z",
    eventYear: 2022,
    eventTimestamp: 1640995200000,
    eventType: "PUBLIC HOLIDAY",
  },
  {
    eventName: "Martin Luther King Jr. Day",
    eventDate: "2022-01-17",
    eventDay: "17",
    eventMonth: "01",
    id: 31809210,
    resourceId: 3918493,
    title: "Martin Luther King Jr. Day",
    start: "2022-01-17T00:00:00.000Z",
    end: "2022-01-17T00:00:00.000Z",
    eventYear: 2022,
    eventTimestamp: 1642377600000,
    eventType: "PUBLIC HOLIDAY",
  },
  {
    eventName: "Presidents Day and Washington's Birthday",
    eventDate: "2022-02-21",
    eventDay: "21",
    eventMonth: "02",
    id: 23806780,
    resourceId: 29405340,
    title: "Presidents Day and Washington's Birthday",
    start: "2022-02-21T00:00:00.000Z",
    end: "2022-02-21T00:00:00.000Z",
    eventYear: 2022,
    eventTimestamp: 1645401600000,
    eventType: "PUBLIC HOLIDAY",
  },
  {
    eventName: "Cesar Chavez Day",
    eventDate: "2022-03-31",
    eventDay: "31",
    eventMonth: "03",
    id: 32589467,
    resourceId: 17262550,
    title: "Cesar Chavez Day",
    start: "2022-03-31T00:00:00.000Z",
    end: "2022-03-31T00:00:00.000Z",
    eventYear: 2022,
    eventTimestamp: 1648684800000,
    eventType: "PUBLIC HOLIDAY",
  },
  {
    eventName: "World MS Day",
    eventDate: "2022-05-30",
    eventDay: "30",
    eventMonth: "05",
    id: 12563784,
    resourceId: 34625784,
    title: "World MS Day",
    start: "2022-05-30T00:00:00.000Z",
    end: "2022-05-30T00:00:00.000Z",
    eventYear: 2022,
    eventTimestamp: 1653868800000,
    eventType: "PUBLIC HOLIDAY",
  },
  {
    eventName: "National Cheese Day",
    eventDate: "2022-06-04",
    eventDay: "04",
    eventMonth: "06",
    id: 28975411,
    resourceId: 18678981,
    title: "National Cheese Day",
    start: "2022-06-04T00:00:00.000Z",
    end: "2022-06-04T00:00:00.000Z",
    eventYear: 2022,
    eventTimestamp: 1654300800000,
    eventType: "PUBLIC HOLIDAY",
  },
  {
    eventName: "Labor Day",
    eventDate: "2022-09-05",
    eventDay: "05",
    eventMonth: "09",
    id: 27611729,
    resourceId: 25030358,
    title: "Labor Day",
    start: "2022-09-05T00:00:00.000Z",
    end: "2022-09-05T00:00:00.000Z",
    eventYear: 2022,
    eventTimestamp: 1662336000000,
    eventType: "PUBLIC HOLIDAY",
  },
  {
    eventName: "Mean Girls Day",
    eventDate: "2022-10-03",
    eventDay: "03",
    eventMonth: "10",
    id: 16727448,
    resourceId: 16820448,
    title: "Mean Girls Day",
    start: "2022-10-03T00:00:00.000Z",
    end: "2022-10-03T00:00:00.000Z",
    eventYear: 2022,
    eventTimestamp: 1664755200000,
    eventType: "PUBLIC HOLIDAY",
  },
  {
    eventName: "Veterans Day",
    eventDate: "2022-11-11",
    eventDay: "11",
    eventMonth: "11",
    id: 21961900,
    resourceId: 23260473,
    title: "Veterans Day",
    start: "2022-11-11T00:00:00.000Z",
    end: "2022-11-11T00:00:00.000Z",
    eventYear: 2022,
    eventTimestamp: 1668124800000,
    eventType: "PUBLIC HOLIDAY",
  },
  {
    eventName: "National Day Of Mourning",
    eventDate: "2022-11-24",
    eventDay: "24",
    eventMonth: "11",
    id: 7412729,
    resourceId: 1836050,
    title: "National Day Of Mourning",
    start: "2022-11-24T00:00:00.000Z",
    end: "2022-11-24T00:00:00.000Z",
    eventYear: 2022,
    eventTimestamp: 1669248000000,
    eventType: "PUBLIC HOLIDAY",
  },
  {
    eventName: "Boxing Day",
    eventDate: "2022-12-26",
    eventDay: "26",
    eventMonth: "12",
    id: 11239853,
    resourceId: 28500242,
    title: "Boxing Day",
    start: "2022-12-26T00:00:00.000Z",
    end: "2022-12-26T00:00:00.000Z",
    eventYear: 2022,
    eventTimestamp: 1672012800000,
    eventType: "PUBLIC HOLIDAY",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a201d",
    eventName: "Date or DeadlineAlternative Earnest Money Deadline",
    eventDate: "2022-10-31",
    eventTimestamp: "1665640862840",
    id: 25322835,
    resourceId: 4228528,
    title: "Date or DeadlineAlternative Earnest Money Deadline",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a2023",
    eventName: "Date or DeadlineRecord Title Deadline and Tax Certificate",
    eventDate: "2022-11-04",
    eventTimestamp: "1665640862842",
    id: 32113858,
    resourceId: 25148495,
    title: "Date or DeadlineRecord Title Deadline and Tax Certificate",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a2029",
    eventName: "Date or DeadlineRecord Title Objection Deadline",
    eventDate: "2022-11-07",
    eventTimestamp: "1665640862843",
    id: 4176448,
    resourceId: 6581958,
    title: "Date or DeadlineRecord Title Objection Deadline",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a202f",
    eventName: "Date or DeadlineOffRecord Title Deadline",
    eventDate: "2022-11-04",
    eventTimestamp: "1665640862847",
    id: 26954660,
    resourceId: 6667758,
    title: "Date or DeadlineOffRecord Title Deadline",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a2035",
    eventName: "Date or DeadlineOffRecord Title Objection Deadline",
    eventDate: "2022-11-07",
    eventTimestamp: "1665640862847",
    id: 21063009,
    resourceId: 27803763,
    title: "Date or DeadlineOffRecord Title Objection Deadline",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a203b",
    eventName: "Date or DeadlineTitle Resolution Deadline",
    eventDate: "2022-11-14",
    eventTimestamp: "1665640862850",
    id: 235470,
    resourceId: 25991009,
    title: "Date or DeadlineTitle Resolution Deadline",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a2041",
    eventName: "Third Party Right to PurchaseApprove Deadline",
    eventDate: "2022-11-14",
    eventTimestamp: "1665640862851",
    id: 19115463,
    resourceId: 30816985,
    title: "Third Party Right to PurchaseApprove Deadline",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a2047",
    eventName: "Date or DeadlineAssociation Documents Deadline",
    eventDate: "2022-11-07",
    eventTimestamp: "1665640862852",
    id: 30600695,
    resourceId: 33073996,
    title: "Date or DeadlineAssociation Documents Deadline",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a204d",
    eventName: "Date or DeadlineAssociation Documents Termination Deadline",
    eventDate: "2022-11-09",
    eventTimestamp: "1665640862853",
    id: 25836045,
    resourceId: 18498783,
    title: "Date or DeadlineAssociation Documents Termination Deadline",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a2053",
    eventName: "Date or DeadlineSellers Property Disclosure Deadline",
    eventDate: "2022-10-31",
    eventTimestamp: "1665640862854",
    id: 19048044,
    resourceId: 29996051,
    title: "Date or DeadlineSellers Property Disclosure Deadline",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a2059",
    eventName: "Date or DeadlineLeadBased Paint Disclosure Deadline",
    eventDate: "2022-10-31",
    eventTimestamp: "1665640862855",
    id: 31294190,
    resourceId: 11088577,
    title: "Date or DeadlineLeadBased Paint Disclosure Deadline",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a205f",
    eventName: "Date or DeadlineNew Loan Application Deadline",
    eventDate: "2022-10-31",
    eventTimestamp: "1665640862855",
    id: 31034232,
    resourceId: 2473907,
    title: "Date or DeadlineNew Loan Application Deadline",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a2065",
    eventName: "New Loan Term Deadline",
    eventDate: "2022-11-03",
    eventTimestamp: "1665640862856",
    id: 13767787,
    resourceId: 9031336,
    title: "New Loan Term Deadline",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a206b",
    eventName: "New Loan Availability Deadline",
    eventDate: "2022-11-25",
    eventTimestamp: "1665640862858",
    id: 17702461,
    resourceId: 18426737,
    title: "New Loan Availability Deadline",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a2071",
    eventName: "Date or DeadlineBuyers Credit Information Deadline",
    eventDate: "2022-11-03",
    eventTimestamp: "1665640862858",
    id: 22946639,
    resourceId: 33120367,
    title: "Date or DeadlineBuyers Credit Information Deadline",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a2077",
    eventName:
      "Date or DeadlineDisapproval of Buyers Credit Information Deadline",
    eventDate: "2022-11-09",
    eventTimestamp: "1665640862859",
    id: 27951748,
    resourceId: 27753351,
    title: "Date or DeadlineDisapproval of Buyers Credit Information Deadline",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a207d",
    eventName: "Existing Loan Deadline",
    eventDate: "2022-11-07",
    eventTimestamp: "1665640862859",
    id: 30718681,
    resourceId: 19705482,
    title: "Existing Loan Deadline",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a2083",
    eventName: "Existing Loan Termination Deadline",
    eventDate: "2022-11-09",
    eventTimestamp: "1665640862860",
    id: 26510335,
    resourceId: 4242429,
    title: "Existing Loan Termination Deadline",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a2089",
    eventName: "Date or DeadlineLoan Transfer Approval Deadline",
    eventDate: "2022-11-21",
    eventTimestamp: "1665640862862",
    id: 8241568,
    resourceId: 20664816,
    title: "Date or DeadlineLoan Transfer Approval Deadline",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a208f",
    eventName: "Seller or Private Financing Deadline",
    eventDate: "2022-12-02",
    eventTimestamp: "1665640862863",
    id: 26902533,
    resourceId: 32696855,
    title: "Seller or Private Financing Deadline",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a2095",
    eventName: "Appraisal Deadline",
    eventDate: "2022-11-14",
    eventTimestamp: "1665640862864",
    id: 24708813,
    resourceId: 4443061,
    title: "Appraisal Deadline",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a209b",
    eventName: "Appraisal Objection Deadline",
    eventDate: "2022-11-01",
    eventTimestamp: "1665640862866",
    id: 7575647,
    resourceId: 6285871,
    title: "Appraisal Objection Deadline",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a20a1",
    eventName: "Appraisal Resolution Deadline",
    eventDate: "2022-10-31",
    eventTimestamp: "1665640862867",
    id: 3308991,
    resourceId: 1602007,
    title: "Appraisal Resolution Deadline",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a20a7",
    eventName: "New ILC or New Survey Deadline",
    eventDate: "2022-11-14",
    eventTimestamp: "1665640862870",
    id: 23454157,
    resourceId: 5559354,
    title: "New ILC or New Survey Deadline",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a20ad",
    eventName: "New ILC or New Survey Objection Deadline",
    eventDate: "2022-11-17",
    eventTimestamp: "1665640862870",
    id: 10501458,
    resourceId: 24857544,
    title: "New ILC or New Survey Objection Deadline",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a20b3",
    eventName: "New ILC or New Survey Resolution Deadline",
    eventDate: "2022-11-21",
    eventTimestamp: "1665640862871",
    id: 7271122,
    resourceId: 11428999,
    title: "New ILC or New Survey Resolution Deadline",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a20b9",
    eventName: "Water Rights Examination Deadline",
    eventDate: "2022-11-09",
    eventTimestamp: "1665640862872",
    id: 15818787,
    resourceId: 16765610,
    title: "Water Rights Examination Deadline",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a20bf",
    eventName: "Mineral Rights Examination Deadline",
    eventDate: "2022-11-09",
    eventTimestamp: "1665640862874",
    id: 14663830,
    resourceId: 21180715,
    title: "Mineral Rights Examination Deadline",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a20c5",
    eventName: "Inspection Termination Deadline",
    eventDate: "2022-11-07",
    eventTimestamp: "1665640862874",
    id: 13098379,
    resourceId: 28594639,
    title: "Inspection Termination Deadline",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a20cc",
    eventName: "Inspection Objection Deadline",
    eventDate: "2022-11-07",
    eventTimestamp: "1665640862875",
    id: 19445213,
    resourceId: 7342572,
    title: "Inspection Objection Deadline",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a20d2",
    eventName: "Inspection Resolution Deadline",
    eventDate: "2022-10-31",
    eventTimestamp: "1665640862876",
    id: 6902071,
    resourceId: 12813433,
    title: "Inspection Resolution Deadline",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a20d8",
    eventName: "Props Ins Termination Deadline",
    eventDate: "2022-11-14",
    eventTimestamp: "1665640862877",
    id: 21417947,
    resourceId: 16266537,
    title: "Props Ins Termination Deadline",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a20de",
    eventName: "Due Diligence Documents Delivery Deadline",
    eventDate: "2022-11-03",
    eventTimestamp: "1665640862877",
    id: 30768601,
    resourceId: 10513278,
    title: "Due Diligence Documents Delivery Deadline",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a20e4",
    eventName: "Due Diligence Documents Objection Deadline",
    eventDate: "2022-11-14",
    eventTimestamp: "1665640862880",
    id: 21124429,
    resourceId: 23386306,
    title: "Due Diligence Documents Objection Deadline",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a20ea",
    eventName: "Due Diligence Documents Resolution Deadline",
    eventDate: "2022-11-17",
    eventTimestamp: "1665640862880",
    id: 15706878,
    resourceId: 11582523,
    title: "Due Diligence Documents Resolution Deadline",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a20f0",
    eventName: "Conditional Sale Deadline",
    eventDate: "2022-12-02",
    eventTimestamp: "1665640862883",
    id: 28149021,
    resourceId: 485994,
    title: "Conditional Sale Deadline",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a20f6",
    eventName: "LeadBased Paint Termination Deadline",
    eventDate: "2022-11-14",
    eventTimestamp: "1665640862884",
    id: 22132643,
    resourceId: 25313289,
    title: "LeadBased Paint Termination Deadline",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a20fc",
    eventName: "Closing Date",
    eventDate: "2022-12-02",
    eventTimestamp: "1665640862884",
    id: 28850284,
    resourceId: 34821010,
    title: "Closing Date",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
  {
    _id: "63a2b4f16c02c3aa3e7a2102",
    eventName: "Possession Date",
    eventDate: "2022-12-02",
    eventTimestamp: "1665640862884",
    id: 26009485,
    resourceId: 23442050,
    title: "Possession Date",
    start: null,
    end: null,
    eventMonth: "10",
    eventDay: "13",
    eventYear: 2022,
    eventType: "EVENT",
  },
];

module.exports = { calDa };

import React from "react";
import { Form, Input, Button, Row,  Col, Select, Spin } from "antd";
import { UserOutlined } from "@ant-design/icons";
import styles from "../styles/Admin.module.css";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { accountAction } from "../../Accounts/state/actions";
import { cmsDataHandle } from "../../CMS/state/actions";
import { adminAction } from "../state/actions";
import BrokerageDetails from "./BrokerageDetails";
import { capitalizeStr } from "../../Common/utils/universalFuntions";

const { Option } = Select;
/* eslint-enable no-template-curly-in-string */
function validateMessages(label) {
  return {
    required: `${label} is required!`,
    types: {
      email: `${label} is not a valid email!`,
      number: `${label} is not a valid number!`,
    },
  };
}
/* eslint-enable no-template-curly-in-string */

//Function to check if user is at least 16 years old
function disabledDate(current) {
  return current && current > moment().subtract(16, "years");
}

function Signup({ form, handleCancelModal, brokerageInput, setBrokerageInput, currentSelectedFranchise, setCurrentSelectedFranchise }) {
  const [firstPageData, setFirstPageData] = useState();
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const { Signup_Loading, } = useSelector((state) => state.authenticate);
  const { associationData, associationDataLoading, } = useSelector((state) => state.cms);
  const { setBrokerageModal } = useSelector((state) => state.authenticate);
  const [officeInput, setOfficeInput] = useState("");

  let onFinish = (event) => {
    event.officeId = currentSelectedFranchise._id;
    setCurrentSelectedFranchise("");
    setBrokerageInput("");
    setOfficeInput("");

    form?.resetFields();
    const signUpData = {
      firstName: capitalizeStr(event.firstName),
      lastName: capitalizeStr(event.lastName),
      email: event.email.toLowerCase(),
      ...(event.association && {association: event.association}),
      ...(event.officeId && {officeId: event.officeId}),
      ...(event.phoneNumber && {phoneNumber: event.phoneNumber}),
    };
    dispatch(accountAction.realterSignup(signUpData, "SUPER_ADMIN_USER"));
    handleCancelModal();
  };

  const handleAssociation = () => {
    dispatch(cmsDataHandle.getAssociationData())
  }

  return (
    <>
  
      <div
        style={{
          display: Signup_Loading ? "flex" : "none",
          position: "absolute",
          top: "50%",
          left: "49%",
          zIndex: 1005,
        }}
      >
        <Spin />
      </div>
        <div className={styles.adminCreateAccount}>
          <Form layout="vertical" name="1normal_login_page1" initialValues={firstPageData} form={form} onFinish={onFinish} validateMessages={validateMessages} scrollToFirstError style={{ width: "100%" }}>
            <Row justify="space-between" gutter={0} style={{ width: "100%" }}>
              <Col span={11}>
                <Form.Item name="firstName" label="First Name" wrapperCol={{ span: 24 }} rules={[{ required: true, message: "Please Enter First Name!" }]}>
                  <Input placeholder="Enter First Name" allowClear />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item name="lastName" label="Last Name" wrapperCol={{ span: 24 }} rules={[{ required: true, message: "Please Enter Last Name!" }]}>
                  <Input placeholder="Enter Last Name" allowClear />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  type: "email",
                  message: "Not a valid Email!",
                },
                {
                  required: true,
                  message: "Please Enter Email!",
                },
                { whitespace: true },
              ]}
            >
              <Input prefix={<UserOutlined className="site-form-item-icon" style={{ marginRight: "15px" }} />} placeholder="Enter Email" allowClear value={email} onChange={(e) => setEmail(e.target.value)} />
            </Form.Item>
            <Row gutter={0} justify="space-between">
              <Col span={11}>
                <Form.Item
                  name="association"
                  label="Association"
                  // rules={[
                  //   { required: true, message: "Please select Association" },
                  // ]}
                >
                  {associationData && associationData?.length > 0 ? (
                    <Select
                      style={{ width: "100%", height: "30px" }}
                      placeholder="Select Association"
                      onFocus={handleAssociation}
                      showSearch
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {associationData?.map((element, index) => (
                        <Option key={index} value={element.key}>
                          {element.label}
                        </Option>
                      ))
                      }
                    </Select>
                  ) : (
                    <Select
                      style={{ width: "100%", height: "30px" }}
                      placeholder="Select Association"
                      onFocus={handleAssociation}
                    >
                      {
                      associationDataLoading ? (
                        <div style={{ minHeight: "20vh", display: "flex", alignItems: 'center', textAlign: 'center' }}>
                          <Spin size="medium" />
                        </div>
                      ) : (
                        associationData?.map((element, index) => (
                          <Option key={index} value={element.key}>
                            {element.label}
                          </Option>
                        ))
                      )}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item name="brokerage" label="Brokerage">
                  <Input placeholder="Select Company Name"
                  value={brokerageInput}
                    onClick={()=>{
                      dispatch(adminAction.openBrokerageSelectModal(true));
                    }}
                  />
                  {setBrokerageModal && 
                  <BrokerageDetails 
                  brokerageInput={brokerageInput} 
                  setBrokerageInput={setBrokerageInput} 
                  officeInput={officeInput} setOfficeInput={setOfficeInput} 
                  currentSelectedFranchise={currentSelectedFranchise}
                  setCurrentSelectedFranchise={setCurrentSelectedFranchise}
                  />}
                </Form.Item>
              </Col>
            </Row>

         
            <Row gutter={0} justify="space-between">
              <Col span={11}>
                <Form.Item
                  name="phoneNumber"
                  label="Phone Number"
                  hasFeedback
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value?.length) {
                          if (value?.length === 10) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error("Please Enter 10 digit Phone Number!"));
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <PhoneInput
                    // isValid={true}
                    inputStyle={{
                      width: "100%",
                    }}
                    inputClass="phoneInput"
                    country={"us"}
                    onlyCountries={["us"]}
                    maxLength="10"
                    international={false}
                    disableCountryCode={true}
                    placeholder="Enter Phone Number"
                  />
                </Form.Item>
              </Col>
              {/* <Col span={11}>
                <Form.Item name="birthDate" label="Birth Date"  >
                  <DatePicker style={{ width: "100%" }} placeholder=" Select Birthday" format="MM-DD-YYYY" disabledDate={disabledDate} />
                </Form.Item>
              </Col> */}
            </Row>

            <Row gutter={0} justify="space-between">
              {/* <Col span={11}>
                <Form.Item
                  name="role"
                  label="Select Role"
                  rules={[
                    {
                      required: true,
                      message: "Please Select A Role!",
                    },
                  ]}
                >
                  <Select placeholder="Please Select a Role" mode="multiple" allowClear>
                    <Option value="TRIAL_USER">TRIAL USER</Option>
                    <Option value="USER">USER</Option>
                    <Option value="CUSTOMER">CUSTOMER</Option>
                    <Option value="AGENT">AGENT</Option>
                    <Option value="REALTOR">REALTOR</Option>
                    <Option value="TRANSACTION_COORDINATOR">TRANSACTION COORDINATOR</Option>
                    <Option value="RESURE_SUPPORT">RESURE SUPPORT</Option>
                  </Select>
                </Form.Item>
              </Col> */}
              {/* <Col span={11}>
                <Form.Item name="adminRole" label="Select Admin Role">
                  <Select placeholder="Please Select a admin Role" mode="multiple" allowClear>
                    <Option value="ADMIN_FRANCHISE" disabled>ADMIN FRANCHISE</Option>
                    <Option value="ADMIN_BROKERAGE" disabled>ADMIN BROKERAGE</Option>
                    <Option value="ADMIN_OFFICE" disabled>ADMIN OFFICE</Option>
                    <Option value="ADMIN_TITLE_COMPANY" disabled>ADMIN TITLE COMPANY</Option>
                    <Option value="ADMIN_TITLE_OFFICE" disabled>ADMIN TITLE OFFICE</Option>
                  </Select>
                </Form.Item>
              </Col> */}
            </Row>

            {/* <Row gutter={0} justify="space-between">
              <Col span={11}>
                <Form.Item name="city" label="City">
                  <Input placeholder="Enter City" allowClear />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item
                  name="state"
                  label="State"
                  rules={[
                    {
                      required: true,
                      message: "Please Select your State!",
                    },
                  ]}
                >
                  <Select placeholder="Select State" allowClear>
                    <Option value="texsa">Texas</Option>
                    <Option value="california">California</Option>
                    <Option value="georgia">Georgia</Option>
                    <Option value="colorado">Colorado</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row> */}
          {/* <Form.Item
            style={{ marginBottom: "35px" }}
            name="termsAndConditions"
            label={
              <div
                style={{
                  position: "relative",
                  display: "flex",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    top: 0,
                    color: "red",
                    fontSize: 18,
                  }}
                >
                  *
                </span>

                <span style={{ marginLeft: "10px" }}>Terms & Conditions</span>
              </div>
            }
            rules={[
              {
                validator: validateTerms,
              },
            ]}
          // rules={[
          //   {
          //     required: true,
          //     message: (
          //       // style={{ position: "absolute", top: "1rem" }}
          //       <span>
          //         Please Select Terms & Conditions and Privacy Policy
          //       </span>
          //     ),
          //   },
          // ]}
          >
            <Checkbox onChange={onChange} defaultChecked={true}>
              I agree to the{" "}
              <a target="_blank" href={`${marketingURL}/termofservice`} rel="noreferrer">
                Terms & Conditions
              </a>{" "}
              and{" "}
              <a target="_blank" href={`${marketingURL}/privacypolicy`} rel="noreferrer">
                Privacy Policy
              </a>
            </Checkbox>
          </Form.Item> */}

            <Form.Item style={{marginTop: 40}}>
              <Button
                htmlType="submit"
                type="primary"
                size="large"
                className={styles.btnPrimary}
                // loading={userSignupLoading}
              >
                Create Account
              </Button>
            </Form.Item>
            {/* <Form.Item>
              <Row justify="center" style={{ marginTop: "px" }}>
                <Text type="secondary">
                  Already have an account ?<Link to="/admin"> Log in now</Link>
                </Text>
              </Row>
            </Form.Item> */}
          </Form>
        </div>
      
    </>
  );
}
export { Signup };

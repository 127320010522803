import { fjggConstants } from "./action-types";


const initialState = {
  FJGGAcessData : [],
  FJGGAcessDataLoading : false,
  FJGGAcessDataError : false,
};

const fjggReducer = (state = initialState, action) => {
  switch (action.type) {
        case fjggConstants.GET_ACCESS_DATA_LOADING:
            return {
              ...state,
              FJGGAcessDataLoading:true,
            };
          case fjggConstants.GET_ACCESS_DATA_SUCCESS:
            return {
              ...state,
              FJGGAcessData:action.FJGGAccessData,
              FJGGAcessDataLoading:false,
            };
          case fjggConstants.GET_ACCESS_DATA_FAILURE:
            return {
              ...state,
              FJGGAcessDataError:action.error,
            };

    default:
      return state;
  }
};

export { fjggReducer };

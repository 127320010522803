/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Form, Row, Spin, Typography } from "antd";
import React from "react";
import styles from "./styles/ClientAuth.module.css";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clientAuthHandle } from "./state/actions";
import { decryptQuery } from "../Common/utils/universalFuntions";


const { Title, Text } = Typography;

export default function ClientAuth() {
  const dispatch = useDispatch();
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const destination = query.get("dest");
  const offerId = query.get("offerId");
  const omdUrlId = query.get("omdUrlId");
  const [error, setErr] = useState("");
  const { setLoading, setError } = useSelector((state) => state.clientAuth);
  
  useEffect(() => {
    localStorage.removeItem("auth");
    const urlId = decryptQuery(query.get("urlId"));
    if (urlId) {
    } else {
      setErr("url ID is required");
    }
    const OTPId = decryptQuery(query.get("OTPId"));
    if (OTPId) {
    } else {
      setErr("OTP ID is required");
    }

    const OTP = decryptQuery(query.get("emailKey"));
    if (OTP) {
      if (OTP.length < 5) {
        setErr("Please enter the code!");
        return;
      }
      dispatch(clientAuthHandle.verifyOTP({ OTP, OTPId, urlId, destination, offerId, omdUrlId }));
    }
  }, []);

  useEffect(() => {
    setErr(setError);
  }, [setError]);


  return (
    <div className={styles.alignAdmin}>
      <Card className={styles.custForm}>
        <div className={styles.Loginlogo}>
          <img
            style={{ width: "52px", height: "auto" }}
            src={"https://resureprojectbucket.s3.us-east-2.amazonaws.com/dev/649be3538168791298e1a3f0/RESURE_RESERVED/18resure-logo-short-blue-text.png"}
            alt="Logo"
          />
        </div>
        <div>
          {/* <h1 className={styles.custFormH1}>Verification</h1> */}
          <h2 className={styles.custFormH2}>Verification</h2>
        </div>
        <div className={styles.center}>
          <Title type="secondary" level={5}>
            Authorizing access to your account
          </Title>
        </div>
        {setLoading ? (
          <div className={styles.center}>
            <Spin size="large" />
          </div>
        ) : (
          <Form>
            {/* <Form.Item>
              <div className={styles.center}>
                <OtpInput
                  numInputs={5}
                  value={otp}
                  // isInputNum={true}
                  onChange={(res) => {
                    setOtp(res);
                    if (error) {
                      setErr("");
                    }
                    if (setError) {
                      dispatch(clientAuthHandle.verifyOTPError(""));
                    }
                  }}
                  separator={<span style={{padding:"0px"}}>-</span>}
                  inputStyle={{
                    width: isMobile ? "55px" : "70px",
                    border: "1px solid #afaeae",
                    height: isMobile ? "40px" :"50px",
                   margin:"auto",
                  }}
                  // containerStyle={{
                  //   width: isMobile ? "70px" : "70px",
                  //   // display:"flex",
                  //   // justifyContent:"space-around",
                  //   // // width:"100%",
                  //   // margin:"auto",
                  // }}
                />
              </div>
            </Form.Item> */}
            <Row justify="center">
              {error && (
                <Text type="danger" style={{ marginTop: "10px" }}>
                  {error}
                </Text>
              )}
            </Row>
            {/* <Form.Item>
              <div className={styles.center} style={{ marginTop: "30px" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className={styles.btnPrimary}
                  disabled={!urlId || !OTPId}
                >
                  Submit
                </Button>
              </div>
            </Form.Item> */}
          </Form>
        )}
      </Card>
    </div>
  );
}

import { Avatar, Collapse, List } from 'antd';
import React, { useEffect, useState } from 'react'
import { CaretRightOutlined ,PhoneOutlined,MailOutlined,UserOutlined} from "@ant-design/icons"
import { useDispatch, useSelector } from 'react-redux';
import { closingActions, propertyDataHandle } from '../state/actions';

const TitleRepsCloser = ({isMobile}) => {
  const { Panel } = Collapse
  const dispatch = useDispatch()
  const [activePanel, setActivePanel] = useState(null);
 
  const {openTitleCompanyModal, selectOffice, companyModalStep, titleOffice, getTitleOfficeData,titleCloser, titleRep} = useSelector((state) => state.transactionReport);
  const [titleCloserData, setTitleCloserData] = useState(null);
  const [titleRepData, setTitleRepData] = useState(null);
  const { titleReps, titleClosers } = useSelector(
    (state) => state.transactionReport
  )
 

 

  const handleSelectedValues = (el) => {
    dispatch(closingActions.titleCompanyModalState({
      titleRep: el?.name,
      titleRepId: el?._id,
      titleRepEmail: el?.email
    }));
  }
  const handleSelectedValues2 = (el) => {
    dispatch(closingActions.titleCompanyModalState({
      titleCloser: el?.name,
      titleCloserId:el?._id,
      titleCloserEmail: el?.email
    }));
  }
  useEffect(() => {
    const result = getTitleOfficeData?.find(item => item._id ===titleOffice);
    setTitleRepData(result?.salesReps);
    setTitleCloserData(result?.titleClosers)
  }, []);
  return (
    <>
   <div style={{display:"flex"}}>
      {titleRep&&<p style={{fontSize: "16px",
                  fontWeight: "bold",
                  marginLeft: ".3rem",
                 }}><text style={{
                  color: "#096dd9",
                  marginRight:"5px"}}>Title Rep :</text >{titleRep}</p>}
      {titleCloser&&<p style={{fontSize: "16px",
                  fontWeight: "bold",
                  marginLeft: "20px",
                 }}><text style={{
                  color: "#096dd9", marginRight:"5px"}}>Closer :</text>{titleCloser}</p>}
    </div>

      <Collapse
        style={{
          fontSize: "22px",
          maxHeight:"390px",
          overflowY:"auto"
        }}
        expandIcon={({ isActive, panelKey }) => (
        <CaretRightOutlined
          rotate={isActive ? 90 : 0}
          style={{ fontSize: "1.3rem", cursor: 'pointer' }}
          onClick={(e) => {
            e.stopPropagation();
            setActivePanel(activePanel === panelKey ? null : panelKey);
          }}
        />
      )}


        bordered={false}
        activeKey={activePanel}
        

      >
        <Panel
          key="1"
          header={
            <div style={{ width: "100%", display: "flex", justifyContent: "space-between", border: "none" }}
             onClick={(e) => {
                e.stopPropagation();
                setActivePanel(activePanel === '1' ? null : '1');
              }}>
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginLeft: ".3rem",
                  color: "#096dd9",
                }}
              >
                Title Rep
              </span>
            </div>
          }
        >
          <List
            style={{
              borderRadius: "8px",
              maxHeight: isMobile?"120px":"235px",
              overflowY: "auto"
            }}
            bordered={true}
            itemLayout="horizontal"
            dataSource={titleRepData}
            renderItem={(item) => {
              return (
                <List.Item
                  style={{
                    background: item.name === titleRep ? "rgb(132, 187, 238)" : "",
                    borderRadius: "8px",
                    cursor:"pointer"
                  }}
                  key={item._id}
                  onClick={(e)=>{
                    e.stopPropagation();
                    handleSelectedValues(item)
                    setActivePanel(activePanel === '1' ? null : '1');
                    }}
                >
                  <List.Item.Meta
                    // avatar={
                    //     <Avatar size={54}
                    //   style={{
                    //     border: "3px solid #085191",
                    //     background: "#085191",
                    //     marginTop:"10px"
                    //   }}>
                    //     {item?.name ? item?.name.split(' ').map(word => word.charAt(0).toUpperCase()).join('') : ""}
                    //   </Avatar>
                    // }
                    title={<><UserOutlined style={{color:"black",marginRight:"10px"}}/><text style={{ color: "#096dd9",}}>{item?.name}</text></>}
                    description={
                      <div style={{display:"flex",flexDirection:"column",marginTop:"-5px"}}>
                      <div style={{ color: "black", fontSize: "16px" ,display:"flex",alignItems:"center"}}>
                       <MailOutlined style={{marginRight:"10px"}}/> <text style={{marginBottom:"5px"}}>{item?.email}</text>
                      </div>
                      <div style={{ color: "black", fontSize: "16px",display:"flex",alignItems:"center" }}>
                      <PhoneOutlined style={{marginRight:"10px"}} /> {item?.phoneNumber?item?.phoneNumber:"N/A"}
                      </div>
                      </div>
                    }
                  />
                </List.Item>
              );
            }}
          />
        </Panel>

        <Panel
          key="2"
          header={
            <div style={{ display: "flex", justifyContent: "space-between" }} 
             onClick={(e) => {
              e.stopPropagation();
              setActivePanel(activePanel === '2' ? null : '2');
            }}>
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#096dd9",
                }}
              >
                Closer
              </span>
            </div>
          }
        >
          <List
            style={{
              borderRadius: "8px",
              maxHeight: isMobile?"120px":"235px",
              overflowY: "auto"
            }}
            bordered={true}
            itemLayout="horizontal"
            dataSource={titleCloserData}
            renderItem={(item) => {
              return (
                <List.Item
                  key={item._id}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelectedValues2(item)
                    setActivePanel(activePanel === '2' ? null : '2');
                  }}
                  style={{ background: item.name === titleCloser ? "rgb(132, 187, 238)" : "",cursor:"pointer"}}
                >
                  <List.Item.Meta
                    // avatar={
                    //     <Avatar size={55}
                    //   style={{
                    //     border: "3px solid #085191",
                    //     background: "#085191",
                    //     marginTop:"10px"
                    //   }}>
                    //     {item?.name ? item?.name.split(' ').map(word => word.charAt(0).toUpperCase()).join('') : ""}
                    //   </Avatar>
                    // }
                    title={<><UserOutlined style={{color:"black",marginRight:"10px"}}/><text style={{color: "#096dd9",}}>{item?.name}</text></>}
                    description={
                      <div style={{display:"flex",flexDirection:"column",marginTop:"-5px"}}>
                      <div style={{ color: "black", fontSize: "16px" ,display:"flex",alignItems:"center"}}>
                       <MailOutlined style={{marginRight:"10px"}}/> <text style={{marginBottom:"5px"}}>{item?.email}</text>
                      </div>
                      <div style={{ color: "black", fontSize: "16px",display:"flex",alignItems:"center" }}>
                      <PhoneOutlined style={{marginRight:"10px"}} /> {item?.phoneNumber?item?.phoneNumber:"N/A"}
                      </div>
                      </div>
                    }
                  />
                </List.Item>
              );
            }}
          />
        </Panel>
      </Collapse>
    </>
  )
}

export {TitleRepsCloser};
import axios from "axios";
import dotEnv from "dotenv";
// import fetch from 'node-fetch';
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;

// with access token
const getContactSheetRequest = ({ offerId, accessToken, openFor }) => {
  let URLlink = `${apiURL}/contactSheet/${offerId}?openFor=${openFor}`;
  const config = {
    method: "GET",
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessToken}`,
    },
  };
  return axios(URLlink, config);
};

const addOrUpdateContactRequest = ({ offerId, queryData, payload, accessToken }) => {
  let URLlink = `${apiURL}/contactSheet/update/${offerId}`;
  const config = {
    method: "PUT",
    data: {
      ...payload
    },
    params: queryData,
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessToken}`,
    },
  };
  return axios(URLlink, config);
}

const sendContactSheetLinkRequest = ({ paramData, payload, accessToken }) => {
  let URLlink = `${apiURL}/sendContactSheet/${paramData?.contactSheetId}`;
  const config = {
    method: "POST",
    data: {
      ...payload
    },

    headers: {
      accept: "application/json",
      authorization: `bearer ${accessToken}`,
    },
  };
  return axios(URLlink, config);
};

export const contactSheetDataApi = {
  getContactSheetRequest,
  addOrUpdateContactRequest,
  sendContactSheetLinkRequest,
};

import { Button, Image, Modal, Spin, Upload, message } from 'antd'
import React, { useState } from 'react'
import { firmDetailsHandle } from '../state/action';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import { BiImageAdd } from 'react-icons/bi';

const Createmodal = ({ setShowModal, formtag, dataid, officeData, setData, data }) => {

  const { postImageUrl, postImageLoading } =
    useSelector((state) => state.firmDetails);

  const [previewUrl, setPreviewUrl] = useState(null);

  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [zip, setzip] = useState("")
  const [phone, setPhone] = useState("")
  const [photoCustomError, setPhotoCustomError] = useState(false)
  const [editdata, setEditdata] = useState(false)
  const [showCustomError, setShowCustomError] = useState({
    status: false,
    message: "",
  });
  const handlechange = (e) => {
    const { name, value } = e.target;
    if (name === "zipCode") {
      if (value?.length < 4 || value.length > 7) {
        setzip("Please enter valid zip code")
      }
      else {
        setzip("")
      }

    }
    if (name === "phonenumber") {
      if (value?.length > 14) {
        setPhone("Please enter valid Phone Number")
      }
      else {
        setPhone("")
      }
    }


    setData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

  }

  const onFinish = (e) => {
    e.preventDefault();

    if (data?.unitLabel?.trim() === "Unit" && data?.unitNumber) {
      data.unit = `Unit ${data?.unitNumber}`;
    } else if (data?.unitLabel?.length > 0 && data?.unitLabel?.trim() !== "Unit" && data?.unitNumber) {
      data.unit = `${data?.unitLabel} ${data?.unitNumber}`;
    } else if(!data?.unitLabel?.length && data?.unitNumber?.length){
      data.unit = `${data?.unitNumber}`
    }

    delete data?.unitLabel;
    delete data?.unitNumber;

    if (formtag === "franchise") {

      // if (!postImageUrl&&!data.officeLogo) {
      //   setShowCustomError({ status: true, message: "Please Enter the Logo" });
      //   return;
      // }
      if (postImageUrl) {
        data.logo = postImageUrl;
      }

      for (let key in data) {
        if (!data[key]) {
          delete data[key];
        }
      }

      dispatch(firmDetailsHandle.editfranchisedetails(data, dataid));
    }
    else if (formtag === "brokerage") {
      if (postImageUrl) {
        data.logo = postImageUrl;
      }
      for (let key in data) {
        if (!data[key]) {
          delete data[key];
        }
      }
      dispatch(firmDetailsHandle.editfranchisedetails(data, dataid));
    }
    else if (formtag === "office") {

      for (let key in data) {
        if (!data[key]) {
          delete data[key];
        }
      }

      dispatch(firmDetailsHandle.editfranchisedetails(data, dataid));
    }

    setShowModal(false);

    setData({
      name: "",
      email: "",
      phoneNumber: "",
      unitLabel: "Unit",
      unitNumber: "",
      association: "",
      state: "",
      city: "",
      streetName: "",
      zipCode: "",
      streetNumber: "",
      officeLogo: "",


    })
  }
  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  }

  let handleCustome = (event) => {
    setVisible(true);
    setFileData(event.file);
    setVisible(true);

    // Preview the image before uploading to our server
    const file = event.file;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");
      setPreviewUrl(base64String);
    };
  };

  const HandleCancel = () => {
    setVisible(false);
  };


  const handleImageUpload = () => {
    dispatch(firmDetailsHandle.postImage(fileData));
    setVisible(false);
    setPhotoCustomError(true)
  };



  const checkPhotoInInput = (_, value) => {
    if (!postImageUrl) {
      return Promise.reject(new Error('Please select a photo.'));
    }
    return Promise.resolve();
  };
  return (
    <div>
      <Modal
        title="Confirmation"
        visible={editdata}
        // onOk={handleOk}
        onCancel={HandleCancel}
        okText="OK"
        cancelText="Cancel"
      >
        <p>Are you sure you want to Edit?</p>
      </Modal>
      <Modal visible={visible} onCancel={HandleCancel} footer={[]}>
        {/* Show only when New pic got uploaded */}
        <div
          style={{
            display: previewUrl ? "block" : "none",
            width: "50%",
            margin: "auto",
            textAlign: "center",
          }}
        >
          {/* <div> */}{" "}
          {previewUrl && (
            <Image
              src={`data:image/png;base64,${previewUrl}`}
              width="250px"
              height="200px"
            />
          )}
          {/* </div> */}
          <div
            style={{ marginTop: "30px", textAlign: "center" }}
            wrapperCol={{ offset: 10, span: 14 }}
          >
            <Button
              type="primary"
              onClick={handleImageUpload}
              style={{ marginRight: "15px", marginLeft: "20px" }}
            >
              Save
            </Button>
            <Button type="primary" onClick={HandleCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>

      <form onSubmit={onFinish}>
        {postImageLoading ? (
          <div
            style={{
              display: "flex",
              height: "70vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <div style={{ margin: "12px" }}>
            <div >
              {formtag === "franchise" ? (
                <>

                  <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <label style={{ fontSize: "15px", marginTop: "8px" }}>Franchise Name</label>
                    <input style={{ width: "70%", height: "30px" }} value={data.name} name="name" onChange={handlechange} placeholder="Please Enter Franchise Name!"
                    />
                  </div>
                  <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    {/* <div style={{width:"40%"}}> */}
                    <label style={{ fontSize: "15px", marginTop: "18px", width: "40%" }}>Franchise logo</label>
                    {/* </div> */}
                    <div style={{ width: "90%", display: "flex", position: "relative", }}>
                      <input style={{ width: "100%", height: "30px", marginTop: "20px", }} name="logo" />

                      <div>
                        {(postImageUrl || data.officeLogo) && (
                          <div
                            style={{
                              marginTop: "20px",
                              border: "1px solid black",
                              position: "absolute",
                              top: "50",
                              left: 10,
                              zIndex: "30",
                            }}
                          >
                            <Image width={45} height={"21px"} src={postImageUrl ? postImageUrl : data.officeLogo} />
                          </div>
                        )}

                        <div
                          style={{
                            position: "absolute",
                            top: "0",
                            right: 4,
                            zIndex: "30",
                            height: "20px",
                            cursor: "pointer",
                          }}
                        >
                          <Upload
                            showUploadList={false}
                            customRequest={handleCustome}
                            beforeUpload={beforeUpload}

                            size={10}

                          >

                            <BiImageAdd
                              style={{

                                marginTop: "18px",
                                fontSize: "36px",
                                fill: "#085191",
                                color: "#085191",
                              }}
                            />

                          </Upload>
                        </div>
                        {showCustomError.status ? (
                          <div style={{ color: "red" }}>
                            {showCustomError.message}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>

                </>
              ) : null}
              {formtag === "office" ? (
                <>
                  <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <label style={{ fontSize: "15px" }}>Select Brokerage</label>

                    <select defaultValue={"Select Franchise"} name="parent" style={{ width: "70%" }} disabled={true}>
                      {officeData?.map((el) => {
                        if (el.name == "OFFICE" && el.office.name === data.name)
                          return (
                            <option key={el._id} value={el._id}>
                              {el.brokerage.name}
                            </option>
                          );
                      })}
                    </select>
                  </div>
                  <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <label style={{ fontSize: "15px", marginTop: "18px" }}>Office Name</label>

                    <input style={{ width: "70%", marginTop: "20px", height: "30px" }} placeholder="Enter Office Name"
                      onChange={handlechange} name="name" value={data.name} allowClear />
                  </div>
                </>
              ) : null}
              {formtag === "brokerage" ? (
                <>
                  <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <label style={{ fontSize: "15px" }}>Select Franchise</label>

                    <select defaultValue={"Select Franchise"} name="parent" style={{ width: "70%" }} disabled={true}>
                      {officeData?.map((el) => {
                        if (el.name == "BROKERAGE" && el.brokerage.name === data.name)
                          return (
                            <option key={el._id} value={el._id}>
                              {el.franchise.name}
                            </option>
                          );
                      })}
                    </select>
                  </div>
                  <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <label style={{ fontSize: "15px", marginTop: "18px" }}><span style={{ color: "red" }}>*</span>Brokerage Name</label>

                    <input required style={{ width: "70%", marginTop: "20px", height: "30px" }} placeholder="Enter Brokerage Name" onChange={handlechange}
                      name="name" value={data.name} allowClear />
                  </div>
                  <div
                    style={{ width: "100%", display: "flex", justifyContent: "space-between" }}
                  >
                    <label style={{ fontSize: "15px", marginTop: "18px" }}><span style={{ color: "red" }}>*</span>License Number</label>

                    <input
                      style={{ width: "70%", marginTop: "20px", height: "30px" }}
                      placeholder="Enter License Number"
                      onChange={handlechange}
                      name="licenseNumber"
                      value={data.licenseNumber}
                      allowClear
                      required
                    />
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <label style={{ fontSize: "15px", marginTop: "18px" }}>Brokerage logo</label>
                    <div style={{ display: "flex", position: "relative", width: "70%" }}>
                      <input style={{ width: "100%", height: "30px", marginTop: "20px", }} name="officeLogo" onChange={handlechange} />
                      <div>
                        {(postImageUrl || data.officeLogo) && (
                          <div
                            style={{
                              marginTop: "20px",
                              border: "1px solid black",
                              position: "absolute",
                              top: "50",
                              left: 10,
                              zIndex: "30",
                            }}
                          >
                            <Image width={45} height={"21px"} src={postImageUrl ? postImageUrl : data.officeLogo} />
                          </div>
                        )}

                        <div
                          style={{
                            position: "absolute",
                            top: "0",
                            right: 4,
                            zIndex: "30",
                            height: "20px",
                            cursor: "pointer",
                          }}
                        >
                          <Upload
                            showUploadList={false}
                            customRequest={handleCustome}
                            beforeUpload={beforeUpload}
                            size={10}
                          >
                            <BiImageAdd
                              style={{
                                marginTop: "18px",
                                fontSize: "36px",
                                fill: "#085191",
                                color: "#085191",
                              }}
                            />
                          </Upload>
                        </div>
                        {showCustomError.status ? (
                          <div style={{ color: "red" }}>
                            {showCustomError.message}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            <div>
              <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                <label style={{ fontSize: "15px", marginTop: "18px" }}>Association</label>
                <input style={{ width: "70%", marginTop: "20px", height: "30px" }} onChange={handlechange} name="association"
                  placeholder="Enter Association Name" value={data.association} />
              </div>
            </div>
            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
              <label style={{ fontSize: "15px", marginTop: "18px" }}>Street Number</label>
              <input style={{ width: "70%", marginTop: "20px", height: "30px" }}
                onChange={handlechange} name="streetNumber" placeholder="Enter Street Number" value={data.streetNumber}></input>
            </div>

            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
              <label style={{ fontSize: "15px", marginTop: "18px" }}>Street Name</label>
              <input style={{ width: "70%", marginTop: "20px", height: "30px" }} onChange={handlechange}
                name="streetName" placeholder="Enter Street Name" value={data.streetName}></input>
            </div>

            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
              <label style={{ fontSize: "15px", marginTop: "18px" }}>Unit Label</label>
              <input style={{ width: "70%", marginTop: "20px", height: "30px" }}
                onChange={handlechange} name="unitLabel" placeholder="Enter Name" value={data.unitLabel}></input>
            </div>

            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
              <label style={{ fontSize: "15px", marginTop: "18px" }}>Unit Number</label>
              <input style={{ width: "70%", marginTop: "20px", height: "30px" }}
                onChange={handlechange} name="unitNumber" placeholder="Enter Number" value={data.unitNumber}></input>
            </div>


            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
              <label style={{ fontSize: "15px", marginTop: "18px" }}>City</label>
              <input style={{ width: "70%", marginTop: "20px" }} onChange={handlechange} name="city"
                placeholder="Enter City Name" value={data.city}></input>
            </div>

            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
              <label style={{ fontSize: "15px", marginTop: "18px" }}>State</label>
              <input style={{ width: "70%", marginTop: "20px", height: "30px" }} onChange={handlechange} name="state" placeholder="Enter City Name"
                value={data.state}></input>
            </div>

            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
              <label style={{ fontSize: "15px", marginTop: "18px" }}>Zip Code</label>
              <input style={{ width: "70%", marginTop: "20px", height: "30px" }} maxLength={6}
                onChange={handlechange} name="zipCode" placeholder="Enter Zipcode" value={data.zipCode}></input>

            </div>
            {zip ? <div><text style={{ color: "red", marginLeft: "145px" }}>{zip}</text></div> : ""}
            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
              <label style={{ fontSize: "15px", marginTop: "18px" }}>Email</label>
              <input style={{ width: "70%", marginTop: "20px", height: "30px" }} onChange={handlechange} name="email"
                placeholder="Enter Email " value={data.email}></input>
            </div>

            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
              <label style={{ fontSize: "15px", marginTop: "18px" }}>Phone Number</label>
              <div style={{ marginTop: "20px", width: "70%" }}>
                <PhoneInput
                  onBlur={(event) => handlechange({ target: { name: 'phoneNumber', value: event.target.value } })}
                  name="phoneNumber"
                  inputStyle={{ width: '100%' }}
                  inputClass="phoneInput"
                  country={"us"}
                  onlyCountries={["us"]}
                  maxLength="10"
                  international={false}
                  disableCountryCode={true}
                  placeholder="Enter Phone Number"
                  className="phone-input"
                  value={data.phonenumber}
                  // style="color: red !important;"
                  style={{ width: "356px !important" }}
                />
                {phone ? <div><text style={{ color: "red" }}>{phone}</text></div> : ""}
              </div>  {/* <input style={{width:"70%",marginTop:"20px"}} placeholder="Enter Phone Number" value={selected.phoneNumber}></input> */}
            </div>
            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
              <button type="submit" style={{
                marginLeft: "250px", marginTop: "20px",
                backgroundColor: "cornflowerblue", color: "white", border: "none", width: "70px", height: "40px", borderRadius: "3px", cursor: "pointer"
              }}

              >Save</button>
            </div>

          </div>)}
      </form>
    </div>
  )
}

export default Createmodal

import axios from "axios";
import dotEnv from "dotenv";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;

const postNdaRequest = (data, pdfUrl, thumbnailUrl, id) => {
  let accessTokan = localStorage.getItem("NDA_accessToken");
  return axios.put(
    `${apiURL}/NDA/${id}`,
    {
      disclosureParty: {
        name: data.disclosingParty,
        nameOfCompany: data.nameofCompanydisclosingParty,
        nameOfPerson: data.nameofPersondisclosingParty,
        signature: data.nda1Signature,
        title: data.disclosingPartyTitle,
        date: data.disclosingPartyDate,
      },
      receivingParty: {
        name: data.receivingParty,
        nameOfCompany: data.nameofCompanyReceivingParty,
        ...(data.nameofPersonReceivingParty
          ? { nameOfPerson: data.nameofPersonReceivingParty }
          : {}),
        signature: data.nda2Signature,
        ...(data.receivingPartyTitle
          ? { title: data.receivingPartyTitle }
          : {}),
        date: data.receivingPartyDate,
        receivingPartyEmail: data.receivingPartyEmail,
      },
      sendMail: data.sendMail,
      pdfUrl: pdfUrl,
      thumbnailUrl: thumbnailUrl,
    },
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

// submit Email
const submitEmailRequest = (id, firstName, lastName) => {
  let accessTokan = localStorage.getItem("NDA_accessToken");
  return axios.post(
    `${apiURL}/NDA`,
    {
      email: id,
      firstName: firstName,
      lastName: lastName || "",
    },
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

const getNDARequest = (query) => {
 let accessTokan = localStorage.getItem("auth");
  return axios.get(`${apiURL}/NDA`, {
    params:{
      ...query
    },
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const editNDARequest = (data, id) => {
  let accessTokan = localStorage.getItem("auth");
  return axios.put(
    `${apiURL}/NDA/${id}`,
    {
      disclosureParty: {
        name: data.disclosingParty,
        nameOfCompany: data.nameofCompanydisclosingParty,
        nameOfPerson: data.nameofPersondisclosingParty,
        signature: data.nda1Signature,
        title: data.disclosingPartyTitle,
        date: data.disclosingPartyDate,
      },
      receivingParty: {
        name: data.receivingParty,
        nameOfCompany: data.nameofCompanyReceivingParty,
        ...(data.nameofPersonReceivingParty
          ? { nameOfPerson: data.nameofPersonReceivingParty }
          : {}),
        signature: data.nda2Signature,
        ...(data.receivingPartyTitle
          ? { title: data.receivingPartyTitle }
          : {}),
        date: data.receivingPartyDate,
        receivingPartyEmail: data.receivingPartyEmail,
      },
    },
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

const deleteNDARequest = (data, flag) => {
  let accessTokan = localStorage.getItem("auth");
  let payload = {};
  if (flag) payload = { email: data };
  else payload = { NDAId: data };
  return axios.delete(`${apiURL}/NDA`, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
    data: payload,
  });
};

const getNDA_JSXRequest = () => {
  let accessTokan = localStorage.getItem("auth");
  return axios.get(`${apiURL}/NDAForm`, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const imageUploadRequest = (event) => {
  let accessTokan = localStorage.getItem("NDA_accessToken");
  var formData = new FormData();
  formData.append("fileBase64", event.file);
  formData.append("folderOf", "SIGNATURE_IMAGE");
  const config = {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-Type": "multipart/form-data",
      authorization: `bearer ${accessTokan}`,
    },
    data: formData,
  };
  return axios(`${apiURL}/common/uploadFile`, config);
};

// pdf file upload
const uploadpdfFileRequest = (event) => {
  let accessTokan = localStorage.getItem("NDA_accessToken");
  var formData = new URLSearchParams();
  formData.append("fileBase64", event);
  formData.append("folderOf", "NDA_PDF");
  formData.append("fileType", "PDF");

  const config = {
    method: "POST",
    headers: {
      // accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      authorization: `bearer ${accessTokan}`,
    },
    data: formData,
  };
  return axios(`${apiURL}/file/NDA/uploadDocsBase64`, config);
};

export const ndaApi = {
  postNdaRequest,
  getNDARequest,
  editNDARequest,
  deleteNDARequest,
  getNDA_JSXRequest,
  imageUploadRequest,
  submitEmailRequest,
  uploadpdfFileRequest,
};

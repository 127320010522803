import { appConstants } from "./action-types";

const initialState = {
  appTitle: "test",

  helpData: [],
  helpDataLoading: false,
  helpDataError: null,
};

const app = (state = initialState, action) => {
  switch (action.type) {
    case appConstants.UPDATE_APP_TITLE:
      return {
        ...state,
        appTitle: action.appTitle,
      };
      case appConstants.HELP_DATA_LOADING:
        return {
          ...state,
          helpData: [],
          helpDataLoading: true,
          helpDataError: null,
        };
      case appConstants.HELP_DATA_SUCCESS:
        return {
          ...state,
          helpData: action?.helpData?.data,
          totalRecords: action?.helpData?.totalRecords,
          helpDataLoading: false,
          helpDataError: null,
        };
      case appConstants.HELP_DATA_ERROR:
        return {
          ...state,
          helpData: [],
          helpDataLoading: false,
          helpDataError: action.helpDataError,
        };
        case appConstants.HELP_GLOBAL_SEARCH_LOADING:
            return {
              ...state,
              helpData: [],
              helpDataLoading: true,
              helpDataError: null,
            };
          case appConstants.HELP_GLOBAL_SEARCH_SUCCESS:
            return {
              ...state,
              helpData: action?.helpData?.data,
              totalRecords: action?.helpData?.totalRecords,
              helpDataLoading: false,
              helpDataError: null,
            };
          case appConstants.HELP_GLOBAL_SEARCH_ERROR:
            return {
              ...state,
              helpData: [],
              helpDataLoading: false,
              helpDataError: action.helpDataError,
            };
    default:
      return state;
  }
};
export { app };

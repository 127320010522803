import { Image, Modal, Popconfirm, Button, Typography } from 'antd'
import React, { useRef, useState } from 'react'
import {CloseCircleOutlined,InboxOutlined} from "@ant-design/icons"
import Dragger from 'antd/lib/upload/Dragger'
import { helpApis } from '../state/actions'
import { useDispatch} from 'react-redux'

const UploadImageModal = ({isImageModalOpen,CancelImageUploadModal}) => {
    const [imageData, setimageData] = useState([]);
    const imageFormdataRef = useRef([]);
    const {Text} = Typography;
    const dispatch = useDispatch();

     // Handling pasting an image
 function bytesToSize(bytes) {
    let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }
  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      throw new Error("You can only upload JPG/PNG file!");
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      throw new Error("Image must be smaller than 5MB!");
    }
    return isJpgOrPng && isLt5M;
  }

    let handleCustome = (data) => {
        const reader = new FileReader();
        reader.readAsDataURL(data.file);
        reader.onload = () => {
          const newFile = {
            name: data.file.name,
            size: bytesToSize(data.file.size),
            file: reader.result,
            origin: "notBase64",
          };
          setimageData((prevFiles) =>{
            if (prevFiles?.length) {
                return [...prevFiles, newFile];
              } else {
                return [newFile];
              }
        });
        };
        reader.onerror = (error) => {
          return error;
        };
        imageFormdataRef.current = [...imageFormdataRef.current,data.file]
      };

      let handleFilesSelected = (event) => {
        const selectedFiles = Array.from(event.target.files);
        selectedFiles.forEach((file) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const newFile = {
              name: file.name,
              size: bytesToSize(file.size),
              file: reader.result,
              origin: "notBase64",
            };
            setimageData((prevFiles) => [...prevFiles, newFile]);
          };
          reader.onerror = (error) => {
            return error;
          };
          imageFormdataRef.current = [...imageFormdataRef.current,file]
        });
      };

      let handleAddFile = () => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";
        input.multiple = true;
        input.addEventListener("change", handleFilesSelected);
        input.click();
      };

      function deleteImg(index){
        let filterData = imageData.filter((el,i)=>(i!==index));
        setimageData(filterData);
        const imageFormdataCopy = [...imageFormdataRef.current]; // Create a shallow copy of the array
        imageFormdataCopy.splice(index, 1); // Remove one element at the specified index
        imageFormdataRef.current = imageFormdataCopy;
        }

        const handleUploadImages =()=>{
            let imageBuffers = imageFormdataRef.current;
            dispatch(helpApis.uploadImage(imageBuffers,'base64'));
            CancelImageUploadModal()
            imageFormdataRef.current =[]
            setimageData([])
        }

  return (
    <div>
      <Modal
      title={<div style={{display:"flex",justifyContent:"center",color:"grey"}}>
            UPLOAD IMAGE
        </div>} footer={false} visible={isImageModalOpen}  onCancel={()=>CancelImageUploadModal(setimageData,imageFormdataRef)}>
        <div>
        {imageData?.length > 0 ? (
        <div>
       <div
        style={{display:"flex",width:"100%",overflow:"auto",maxHeight:"200px",
        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px'}}
       >
     { imageData?.map((file, index) => (
      <div key={index} style={{margin:"10px"}} >
     <Image
      width={100}
      src={file.file}
      preview={{
        visible:false,
        mask: (
          <div
            style={{
              cursor: "pointer",
              position: "absolute",
              zIndex: 21,
              top: "0px",
              right: "0px",
            }}
          >
            <div
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
                <Popconfirm
                   title="Are you sure you want to delete this image?"
                   onConfirm={() => {
                     deleteImg(index);
                  }}
                 okText="Yes"
                 cancelText="No"
               >
                <CloseCircleOutlined style={{ fontSize: "30px" }} />
               </Popconfirm>
              </div>
           </div>
          ),
         }}/>
      <br />
      <Text type="secondary">{file.name}</Text>
     <br />
    <Text type="secondary">Size: {file.size}</Text>
    </div>
    ))}
   </div>
   <div style={{display:"flex",justifyContent:"space-around",margin:"5px auto", width:"70%"}}>
   <Button type="primary" disabled={imageData.length > 5 } onClick={handleAddFile}>Add More Images</Button>
   <Button onClick={handleUploadImages} >Upload Images</Button>
   </div>
  </div>
    ) : (
    <Dragger accept="image/*"
      showUploadList={false}
      customRequest={handleCustome}
    >
  <div >
  <p className="ant-upload-drag-icon">
   <InboxOutlined />
   </p>
    <p className="ant-upload-text">
    Click or drag file to this area to upload
   </p>
   <p className="ant-upload-hint">
    Support for multiple image uploads. Strictly prohibit from
    uploading company data or other banned files
   </p>
   </div>
   </Dragger>
   )}
   </div>

        </Modal>
    </div>
  )
}

export default UploadImageModal

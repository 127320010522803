import { buildQueryString } from "../../Common/utils/universalFuntions";
import { clienAuthApi } from "../utils/api";
import { clientAuthConstants } from "./action-types";
const apiURL = process.env.REACT_APP_ADMIN_BASE_URL;

const verifyOTPError = (error) => {
  return {
    type: clientAuthConstants.VERIFY_OTP_ERROR,
    setError: error,
    setLoading: false,
  };
};

const verifyOTPLoading = () => {
  return {
    type: clientAuthConstants.VERIFY_OTP_LOADING,
    setLoading: true,
  };
};
const verifyOTPSuccess = () => {
  return {
    type: clientAuthConstants.VERIFY_OTP_SUCCESS,
  };
};

const verifyOTP = ({ OTP, OTPId, urlId, destination, offerId, omdUrlId }) => {
  return (dispatch) => {
    dispatch(verifyOTPLoading());
    clienAuthApi
      .verifyUserOtp({ OTP, OTPId, urlId, destination, offerId })
      .then((response) => {
        if (destination === "offerTable" && !omdUrlId) {
          window.location.href = `${apiURL}/offer/${urlId}`;
        } else {
          const email = response?.data?.info?.email || "";
          const query = {
            ...(destination ? { dest: destination, email } : {}),
            ...(omdUrlId ? { omdUrlId } : {}),
          };
          const queryString = buildQueryString(query);
          window.location.href = `${apiURL}/contractDocument/${urlId}?${queryString}`;
        }
        dispatch(verifyOTPSuccess());
      })
      .catch((error) => {
        dispatch(verifyOTPError(error?.response?.data?.message || "Error occured while verifying the OTP!"));
      });
  };
};

export const clientAuthHandle = {
  verifyOTP,
  verifyOTPError,
};

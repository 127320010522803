import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Row, Col, Layout, Select } from "antd";
import { Loading } from "../Common/components/Loading";
import { listingEditAction } from "./state/actions";
import { EditableTable } from "./components/EditableTable";
import { AddCategory } from "./components/AddCategory";
import styles from "./styles/ListingEdit.module.css";
import { Redirect } from "react-router-dom";
import { roleAccessFunc } from "../Common/commondata/commonData";
const { Content } = Layout;
const { Option } = Select;

let catTypes = ["PRIMARY_CATEGORY", "SUB_CATEGORY", "DOC_TYPE"];

function ListingEdit(props) {
  // useEffect(() => {
  //   if (props.networkConnection === false) {
  //     window.location.reload();
  //   }
  // }, [props]);
  const dispatch = useDispatch();
  const { getCategoryData, getCategoryLoading } = useSelector(
    (state) => state.listingEdit
  );
  const [categoryType, setCategoryType] = useState("");
  const { adminRole, roleAccessed } = useSelector(
    (state) => state.account,
    shallowEqual
  );
  useEffect(() => {
    if (roleAccessFunc(roleAccessed, "LIST_EDIT")) {
      dispatch(listingEditAction.getCategoryList());
    }
  }, [dispatch]);

  let handleChange = (e) => {
    setCategoryType(e);
  };

  if (!roleAccessFunc(roleAccessed, "LIST_EDIT")) {
    return <Redirect to="/error" />;
  }

  return (
    <Layout style={{ height: "100%", padding: "0px" }}>
      <Content
        style={{
          overflow: "auto",
          background: "rgb(202 202 202)",
          padding: "1rem",
        }}
      >
        <div className={styles.site_layout_background}>
          {getCategoryLoading ? (
            <Loading />
          ) : (
            <Row gutter={[16, 16]}>
              <Col>
                Select Category Type :
                <Select style={{ width: 150 }} onChange={handleChange}>
                  {catTypes &&
                    catTypes.map((ele, index) => {
                      return <Option value={ele}>{ele}</Option>;
                    })}
                </Select>
              </Col>
              {categoryType && (
                <Col span={24}>
                  <AddCategory categoryType={categoryType} />
                </Col>
              )}
              <Col span={24}>
                <EditableTable list={getCategoryData} />
              </Col>
            </Row>
          )}
        </div>
      </Content>
    </Layout>
  );
}

export { ListingEdit };

import React, { useEffect } from "react";
import { useState } from "react";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { contractDataHandle } from "../state/actions";

export default function OpenCompressed() {
  const [source, setSource] = useState("webApp");
  const dispatch = useDispatch();
  const {setDrawer} = useSelector((state)=>state.createOffer);
  useEffect(() => {
    const updateSourceBasedOnWidth = () => {
      if (window.innerWidth < 1000 && window.innerWidth > 500) {
        dispatch(contractDataHandle.setDrawerPosition(false));
        setSource("mobileApp");
        let pan1 = document.getElementById("pan1");
        if (pan1) {
          pan1.style.transition = "0.5s";
          pan1.style.visibility = "visible";
          pan1.style.flexBasis = "0%";
        }
      } else if (window.innerWidth >= 1000) {
        dispatch(contractDataHandle.setDrawerPosition(true));
        setSource("webApp");
        let pan1 = document.getElementById("pan1");
        if (pan1) {
          pan1.style.visibility = "visible";
          pan1.style.transition = "0.5s";
          pan1.style.flexBasis = "25%";
        }
      } else if (window.innerWidth <= 500) {
        dispatch(contractDataHandle.setDrawerPosition(false));
        setSource("mobile");
        let pan1 = document.getElementById("pan1");
        pan1.style.visibility = "hidden";
        pan1.style.flexBasis = "0%";
        pan1.style.transition = "0s";
        
      }
    };
    updateSourceBasedOnWidth();
    window.addEventListener("resize", updateSourceBasedOnWidth);
    return () => {
      window.removeEventListener("resize", updateSourceBasedOnWidth);
    };
  }, []);

  const handleClose = () => {
    dispatch(contractDataHandle.setDrawerPosition(false));
    let pan1 = document.getElementById("pan1");
    if (source === "mobileApp") {
      let CCVClosedPositionBtn = document.getElementById("CCVClosedPositionBtn");
      if (pan1 && CCVClosedPositionBtn && pan1.style.flexBasis > "0%") {
        pan1.style.transition = "0.5s";
        pan1.style.flexBasis = "0%";
        CCVClosedPositionBtn.style.left = "-1rem";
      } 
      // else {
      //   let CCVClosedPositionBtn = document.getElementById("CCVClosedPositionBtn");
      //   if (pan1 && CCVClosedPositionBtn) {
      //     CCVClosedPositionBtn.style.left = "-1rem";
      //     pan1.style.transition = "0.5s";
      //     pan1.style.flexBasis = "95%";
      //   }
      // }
    } else if ( pan1 && source === "webApp") {
      pan1.style.transition = "0.5s";
      pan1.style.flexBasis = "8%";
    } else if (pan1 && source === 'mobile') {
      pan1.style.animation = "sidebar-unload 400ms ease-out";
      pan1.style.visibility = 'hidden';
      pan1.style.transition = "400ms";
    }
    setTimeout(() => {
      pan1.style.transition = "0s";
    }, 100);
  };

  const handleOpen = () => {
    dispatch(contractDataHandle.setDrawerPosition(true));
    let pan1 = document.getElementById("pan1");
    if (source === "mobileApp") {
      // let CCVOpenPositionBtn = document.getElementById("CCVOpenPositionBtn");
      // if (pan1 && CCVOpenPositionBtn && pan1.style.flexBasis > "0%") {
      //   pan1.style.transition = "0.5s";
      //   pan1.style.flexBasis = "0%";
      //   CCVOpenPositionBtn.style.left = "-1rem";
      // } else {
        let CCVOpenPositionBtn = document.getElementById("CCVOpenPositionBtn");
        if (pan1 && CCVOpenPositionBtn) {
          CCVOpenPositionBtn.style.left = "-1rem";
          pan1.style.transition = "0.5s";
          pan1.style.flexBasis = "95%";
        }
      // }
    } else if (pan1 && source === "webApp") {
      pan1.style.transition = "0.5s";
      pan1.style.flexBasis = "25%";
    } else if (pan1 && source === 'mobile') {
      pan1.style.visibility = 'visible'
      pan1.style.animation = "sidebar-load 400ms ease-in";
      // pan1.style.transition = "400ms";
      setTimeout(() => {
        pan1.style.transition = "0s";
      }, 100);
    }
  };
  return !setDrawer && source === "webApp" ? (
    <Tooltip title="Open Compressed Section">
      <RightOutlined onClick={handleOpen} style={{ fontSize: "20px", position: "relative", left: "-4px", backgroundColor: "#124E8F", padding: "8px", borderRadius: "100px", color: "white", cursor: "pointer", zIndex: 10 }} />
    </Tooltip>
  ) : !setDrawer && source === "mobileApp" ? (
    <Button id="CCVOpenPositionBtn" onClick={handleOpen} className="CCVOpenCloseBtn">
    NAV
    </Button>
  ) : setDrawer && source === "webApp" ? (
    <Tooltip title="Close Compressed Section">
      <LeftOutlined onClick={handleClose} style={{ fontSize: "20px", position: "relative", left: "-14px", backgroundColor: "#124E8F", padding: "8px", borderRadius: "100px", color: "white", cursor: "pointer", zIndex: 10 }} />
    </Tooltip>
  ) : setDrawer && source === "mobileApp" ? (
    <Button id="CCVClosedPositionBtn" onClick={handleClose} className="CCVOpenCloseBtn">
    NAV
    </Button>
  ) : setDrawer && source === 'mobile' ? (
    <Button id="CCVClosedPositionBtn" onClick={handleClose} className="CCVOpenCloseBtn">
    NAV
    </Button>
  ) : !setDrawer && source === 'mobile' ? (
    <Button id="CCVOpenPositionBtn" onClick={handleOpen} className="CCVOpenCloseBtn">
    NAV
    </Button>
  ) : null;
}

import React, { useEffect, useRef, useState } from 'react'
import { Button, Input, List, Pagination, Select, Space, Table, Tooltip, Typography } from 'antd';
import "../styles/logs.css";
import { useDispatch, useSelector } from 'react-redux';
import { logsDataHandle } from '../state/actions';
import { Loading } from '../../Common';
import moment from 'moment';
import { SearchOutlined, FilterFilled, } from "@ant-design/icons"
import Highlighter from 'react-highlight-words';
import _ from "lodash";

function AgentCreatedLogs() {
  const dispatch = useDispatch();
  const searchRef = useRef(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState({ logType: 'AGENT_CREATED_LOGS' });
  const { getLogsData, getLogsLoading, getLogsDataError, totalRecords } = useSelector((state) => state.getLogsData);

  useEffect(() => {
    let queryData = {
      ...query,
      page: currentPage,
    }
    dispatch(logsDataHandle.getAgentCreatedLogsData(queryData));
    setQuery(queryData);
  }, [currentPage]);

  const debouncedHandleSearch = _.debounce((queryData) => {
    dispatch(logsDataHandle.getAgentCreatedLogsData(queryData));
  }, 1000);


  const handleSearch = (value) => {
    let queryData = {
      logType: 'AGENT_CREATED_LOGS'
    };
    if(value){
        queryData.search = value;
      }else{
        queryData.page = currentPage;
      }
    debouncedHandleSearch(queryData);
  };



  return (
    <div id='logs'>
          <div style={{ background: "white" }}>
             <div style={{display:"flex",justifyContent:"center",fontSize:"large"}}>
                <h1>{"USER CREATED LOGS"}</h1>
            </div>
              {/* serach input box  */}
              <div style={{ width: "50%", margin: "auto", padding: "5px" }}>
                <Input
                  allowClear
                  suffix={<SearchOutlined />}
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>
              {/* main list box  */}
              {
                getLogsLoading ?
                <Loading />
                :
              <div style={{ height: "100vh" }}>
                <List
                  loading={getLogsLoading}
                  bordered
                  dataSource={getLogsData}
                  renderItem={(item, index) => (
                    <List.Item key={index}>
                      {/* Rendering content here */}
                      {/* <List.Item.Meta
                        title={
                          <span style={{ fontSize: "15px", fontWeight:"bold" }}>
                             <span style={item?.createdThrough ==="SIGN-UP" ? {color:"green"}:{color:"orange"}}>
                             {item?.createdThrough}
                             </span>
                          </span>
                        }
                        description={ */}
                        <div style={{width:"100%",display:"flex",fontSize:"15px",fontWeight:"bold"}}>
                          <div style={{ width: "85%" }}>
                            <Typography.Text style={{ fontFamily: "poppins" }}>
                              {/* <span>
                                {" "}
                                Using the {item?.platformUsed === "WEB" ? "Webapp" : "Mobileapp"} platform,{" "}
                              </span> */}
                              <span>
                               User {item?.createdAgentData?.fullName}
                                <span> ({item?.createdAgentData?.email}) </span>
                                created by
                              </span>

                              <span>
                                <span> {(item?.createdThrough !=="SIGN-UP") ? "Sign up" : "Admin panel"}.</span>
                                {/* <span> ({item?.createdByData?.email !== "support@resure.realestate" ? "" : item?.createdByData?.email }) </span> */}
                               
                              </span>
                            </Typography.Text>
                          </div>


                      <div style={{ width: "15%", marginLeft:"20px" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <div
                            style={{
                              width: "10px",
                              height: "10px",
                              borderRadius: "100px",
                              backgroundColor: "green",
                            }}
                          ></div>
                          {"Created"}
                        </div>
                        <div>
                          {item?.createdAt
                            ? moment(item?.createdAt).format("MMMM D, YYYY")
                            : ""}
                        </div>
                      </div>
                      </div>
                    </List.Item>
                  )}
                />
                <Pagination
                  style={{ textAlign: "center", padding: "10px" }}
                  current={currentPage}
                  total={totalRecords}
                  pageSize={5}
                  showSizeChanger={false}
                  onChange={(page) => {
                    if (!searchRef.current) {
                      searchRef.current = true;
                      return;
                    }
                    dispatch(
                      logsDataHandle.getAgentCreatedLogsData({
                        ...query,
                        logType: "AGENT_CREATED_LOGS",
                        page: page,
                      }),
                    );
                    setQuery({
                      ...query,
                      logType:"AGENT_CREATED_LOGS",
                      page: page,
                    });
                    setCurrentPage(page);
                  }}
                />
              </div>
                }
            </div>
    </div>
  )
}

export default AgentCreatedLogs

import { verificationConstants } from "./action-types";
import { verificationApi } from "../utils/api";


import { accountAction } from "../../Accounts/state/actions";

import { message } from "antd";

const verificationError = (error) => {
  return {
    type: verificationConstants.VERIFICATION_FAILURE,
    verificationError: error && JSON.stringify(error.response.data.message),
    statusCode: error && JSON.stringify(error.response.data.statusCode),
    loading: false,
  };
};

const verificationSuccess = (res) => {
  return {
    type: verificationConstants.VERIFICATION_SUCCESS,
    verificationData: res.data.message,
    statusCode: res.data.statusCode,
    loading: false,
  };
};

const verifyEmailFun = (userId, email, app) => {
  return (dispatch, getState) => {
    verificationApi
      .verificationRequest(userId, email, app)
      .then((response) => {
        dispatch(verificationSuccess(response));
      })
      .catch((error) => {
        dispatch(verificationError(error));
      });
  };
};

const approveEmailforClauseError = (error) => {
    return {
      type: verificationConstants.VERIFICATION_FAILURE,
      verificationError: error && JSON.stringify(error.response.data.message),
      statusCode: error && JSON.stringify(error.response.data.statusCode),
      loading: false,
    };
  };

  const approveEmailforClauseSuccess = (res) => {
    return {
      type: verificationConstants.VERIFICATION_SUCCESS,
      verificationData: res.data.message,
      statusCode: res.data.statusCode,
      loading: false,
    };
  };

  const approveEmailforClause = (id,source,fullName,userEmail) => {
    return (dispatch, getState) => {
      verificationApi
        .approveEmailforClauseRequest(id, source,fullName,userEmail)
        .then((response) => {
          dispatch(approveEmailforClauseSuccess(response));
        })
        .catch((error) => {
          dispatch(approveEmailforClauseError(error));
        });
    };
  };

const resetverification = () => ({
  type: verificationConstants.RESET_VERIFICATION,
});


const emailVerificationWithTerms = (token, {payload}) => {
  return (dispatch) => {
    verificationApi
      .emailVerificationWithTermsRequest(token,{payload})
      .then((response) => {
        dispatch(accountAction.getUserDetails({ token: token }));
      })
      .catch((error) => {
       message.error(error?.response?.data?.message || "We encontered an issue in verifing the email. Please try after some time!");
      });
  };
};

export const verificationHandle = {
  emailVerificationWithTerms,
  verifyEmailFun,
  resetverification,
  approveEmailforClause,
};

import React from "react";
import { Typography } from "antd";
import styles from "../style/SendEmailModal.module.css"
const { Text } = Typography;

const RecipientList = ({ data, type }) => (
  <div style={{fontSize: "18px", lineHeight: "1.6" ,marginTop:data?.length>0?"-5px":"0px"}}>
    <div>
    
      {data?.map((el, index) => (
        <div
          key={index}
          style={{
          //  padding: "5px 0",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span className={styles.bulletPoint}>•</span>
          <div
            style={{
              display: "flex",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <span className={styles.recipient}>{el?.fullName}</span>
            <span className={styles.dash}>-</span>
            <text
            className={styles.email}
              // copyable={{ text: el?.email }}
            >
              {el?.email}
            </text>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default RecipientList;

import { logsConstants } from "./action-types"


const initialState = {
    getLogsData: [],
    getLogsLoading: true,
    getLogsDataError: false,
    totalRecords: 0,
}

const getLogsData = (state = initialState, action) => {
    switch (action.type) {
        case logsConstants.GET_LOGS_LOADING:
            return{
                ...state,
                getLogsData:[],
                getLogsLoading:true,
            }
        case logsConstants.GET_AGENT_CREATED_LOGS_SUCCESS:
            return {
                ...state,
                getLogsData: action.getAgentCreatedLogs,
                getLogsLoading: false,
                totalRecords: action.totalRecords,
            }
        case logsConstants.GET_LOGS_FAILURE:
            return {
                ...state,
                getLogsData: [],
                getLogsLoading: action.getLogsLoading,
                getLogsDataError: action.getLogsDataError,
            }
        default:
            return state
    }
}

export { getLogsData }
